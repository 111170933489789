.badge-counter{
    width: 24px;
    height: 24px;
    background-color:  #363636;
    color: white;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .count{
        position: absolute;
    }
}