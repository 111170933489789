.team {
  position: relative;
  width: 100%;

  //add extra padding when reaches to end of page
  &:has(.invitee:last-child .categorised-search.categorised-dropdown-open) {
    padding-bottom: 30vh;
  }

  .team-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

    .bimmatch-button {
      min-width: 140px;
    }
  }

  .email-invite {
    display: flex;
    align-items: center;
    width: 50%;

    .input-text {
      margin-right: 10px;

      .label-container .label-text {
        font-family: $opensans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $black;
      }
    }

    .bimmatch-button {
      margin-bottom: -8px;
    }
  }

  .invitees-container {
    margin-top: 22px;
  }

  .invitee {
    background: $lightgrayshade;
    border-radius: 4px;
    margin-bottom: 15px;

    .invitee-details {
      display: flex;
      padding: 10px;
      min-height: 80px;
      align-items: center;

      .bimmatch-avatar {
        margin-right: 12px;

        .initials h3 {
          line-height: 35px;
        }
      }

      .invitee-sub-details {
        margin-right: 20px;
        width: 140px;

        .invitee-name,
        .invitee-email,
        .invitee-profession {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .roles-projects-reinvite {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 245px);

        .bimmatch-select {
          width: 49%;
        }

        .reinvite {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;

          .pending-text {
            font-family: $opensans;
            font-style: italic;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $black;
            margin-right: 20px;
          }
        }

        .projects {
          .section-container {
            max-height: 30vh;
            overflow: auto;
          }
        }
      }

      .bimmatch-popover .popover-content.popover-content-bottom {
        right: 4px;
        left: unset;
        padding: 5px 5px;

        .remove-invitee {
          padding: 5px;
          cursor: pointer;
        }

        .remove-invitee:hover {
          background-color: $lightgrayshade;
          border-radius: 4px;
        }
      }

      .invitee-options-icon {
        margin-left: 15px;
      }

      .chip-info-tooltip {
        border-radius: 4px;
        padding: 5px;
      }
    }
  }

  .role-info {
    font-family: $opensans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $black;

    .role-info-content {
      width: 250px;

      .roles-info-title,
      .roles-info-permission {
        font-style: normal;
        font-size: 14px;
        font-family: $opensans;
      }

      .roles-info-detail {
        margin-top: 8px;
      }

      .roles-info-title {
        font-weight: 600;
        line-height: 18px;
      }

      .roles-info-permission {
        font-weight: 400;
        line-height: 20px;
        margin-top: 2px;
      }
    }

    .info-icon {
      margin-left: 10px;
      cursor: pointer;
    }

    .bimmatch-popover .popover-content {
      text-align: left;
      margin-left: -136px;
    }
  }
}