.carousel-wrapper{
    .slideshow-container {
        max-width: 1000px;
        position: relative;
        margin: auto;
    }
    .mySlide{
      img{
        width: 100%;
        height: 500px;
        object-fit: contain;
      }
      display: none;
      &.active{
        display: block !important;
      }
    }
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    // width: auto;
    // padding: 16px;
    margin-top: -22px;
    color: #444444;
    // font-weight: bold;
    // font-size: 18px;
    // transition: 0.6s ease;
    // border-radius: 0 3px 3px 0;
    // user-select: none;
  }
  
  .next {
    right: 0;
    // border-radius: 3px 0 0 3px;
  }
  
  // .prev:hover, .next:hover {
  //   background-color: #fff;
  // }
  
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }

  .dot-wrapper{
    text-align: center;
  }
  
  .dot {
    cursor: pointer;
    height: 4px;
    width: 40px;
    margin: 0 8px;
    background-color: #AAAAAA;
    // border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    
    &.active, &:hover {
      background-color: #151515;
    }
  }
  
  
  .carousel-fade {
    animation-name: carousel-fade;
    animation-duration: 1.5s;
  }
  
  @keyframes carousel-fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  
  @media only screen and (max-width: 300px) {
    .prev, .next,.text {font-size: 11px}
  }
}