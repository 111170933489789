.bimmatch-avatar{
    width: 35px;
    height: 35px;
    img{
        border-radius: 50%;
        width: 100%;
    }
    .initials{
        background-color: #D8D8D8;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 100%;
        h3{
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            color: #000000;
            text-align: center;
        }
    }
}