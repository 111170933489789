.auth-layout-wrapper{
    min-height: 100vh;
    display: flex;

    .branding{
        width: 33%;
        background-color: $lightgrayshade;
        display: flex;
        align-items: center;
        justify-content: center;

        .branding-wrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .bm-logo-with-shadow{
            width: 184px;
            height: 206px;
        }

        .bm-slogan{
            display: flex;
            text-align: center;
            max-width: 331px;

            position: relative;
            top: -80px;
        }
        
        .slogan-content{
            color: $gray;
            font-family: Barlow;
            font-size: 28px;
            font-weight: 275;
        }
    }
    
    .content-wrapper{
        width: 100%;
        display: flex;
        flex-direction: column;        

        .login-header{
            display: none;
            width: 100%;
            align-items: center;
            gap: 5px;
            margin-bottom: 25px;
            padding: 12px;
    
            .bm-logo{
                width: 32px;
                height: 32px;
            }
    
            .login-header{
                font-family: Barlow;
                font-weight: 275;
                color: $black;
            }
        }

        .footer-content {
            text-align: center;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 50px;
            
            a {
                text-decoration-line: underline;
                color: $main-black;
                font-stretch: 100;
                font-weight: 600;
                font-size: 14px;
            }
        }
    }

    @media (max-width: $breakpoint-lg) {
        .branding, .section-title{
            display: none;
        }

        .content-wrapper .login-header{
            display: flex;
        }
    }
}