.product-list-page {
  .location-and-search {
    position: fixed;
    width: 100%;
    z-index: 12;

    background: rgba(239, 239, 239, 1);
    padding: 10px 0;
  }

  .container {
    padding-top: 70px;

    .category-list-container {
      position: fixed;
    }

    .product-list-container {
      margin-left: 25%;
    }

    &.bimmatch-product-filter-container {
      margin-top: unset;
      padding-top: unset;
    }
  }
}