.bimmatch-list-item{
    display: flex;
    padding: 10px;
    height: 40px;
    border-radius: 8px;
    align-items: center;

    .list-item-icon{
        height: 18px;
        width: 18px;
    }

    .item-title{
        color: $alt-black;
        font-weight: 600;
    }

    .left-section, .right-section{
        display: flex;
        align-items: center;
    }

    .left-section {
        flex: 1;
        min-width: 0;
        overflow: hidden;
        display: flex;
        align-items: center;

        .item-title-container {
            flex: 1;
            min-width: 0;
            overflow: hidden;

            .item-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .list-item-tags {
            flex: 0 0 30%;
            min-width: 30%;
            overflow-x: auto;
            margin: 0 10px;
            display: flex;
            align-items: center;

            .chip {
              border-radius: 24px;
              height: 18px;
              padding: 2px 4px;
              background: $lightgrayshade;


              &:not(:last-child) {
                margin-right: 5px;
              }

              .chip-name {
                font-size: 12px;
              }
            }
          }
    }

    .right-section {
        flex: 0 0 auto;

        .item-updated-at{
            font-size: 12px;
            max-width: 115px;
        }
    }

    .options{
        .bimmatch-popover {
            .popover-content-bottom {
              left: unset;
              right: 15px;
            }
      
            .popover-arrow {
              left: unset;
              right: 13px;
            }
        }
    }

    &.item-type-primary{
        background: $white;
    }
    &.item-type-secondary{
        background: $lightgraytransparent;
    }
}