.banner {
  min-height: 728px;
  padding: 0 15px;

  @media (max-width: $breakpoint-sm) {
    min-height: 100px;
    padding: 0 15px 60px;
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
    color: $white;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 48px;

    @include media-breakpoint-down(lg) {
      padding-top: 120px;
    }

    @media (max-width: $breakpoint-sm) {
      font-size: 36px;
      line-height: 44px;
      padding: 100px 0 30px;
    }
  }

  .file-upload {
  }
}

.feature {
  position: relative;
  padding: 80px 0 0;

  @media (max-width: $breakpoint-sm) {
    padding: 30px 0 0;
  }

  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 45px;
    padding-left: 45px;

    @media (max-width: $breakpoint-sm) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .row.feature-row {
    margin-right: -45px;
    margin-left: -45px;

    @media (max-width: $breakpoint-sm) {
      margin-right: -15px;
      margin-left: -15px;
    }

    .content-wrap {
      width: 100%;
      height: 100%;
      float: right;
      margin: 0 0 60px;
      position: relative;

      @media (max-width: $breakpoint-sm) {
        margin: 0 0 30px;
      }

      .image-wrap {
        position: absolute;
        width: 400px;
        height: 100%;
        top: 0;
        right: 0;

        @media (max-width: $breakpoint-sm) {
          @include calc(width, "100% - 60px");
        }
      }

      .overlay-text {
        position: relative;
        width: 422px;
        height: 100%;
        padding: 40px 40px 30px;
        margin: -20px 0 0 80px;
        -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
        @include background-opacity($offwhite, 0.7);

        @media (max-width: $breakpoint-sm) {
          margin: -10px 0 0;
        }

        h2 {
          margin: 0 0 20px;

          @media (max-width: $breakpoint-sm) {
            font-size: 18px;
          }
        }

        h4 {
          line-height: 22px;

          @media (max-width: $breakpoint-sm) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.awesome-products {
  position: relative;
  padding: 60px 0;
  background-color: $white;

  @media (max-width: $breakpoint-sm) {
    padding: 30px 0;
  }

  .container {
    max-width: 990px;
  }

  h1 {
    font-weight: 600;
    margin: 0 0 50px;

    @media (max-width: $breakpoint-sm) {
      font-size: 24px;
      margin: 0 0 30px;
    }
  }

  .card-columns {
    &.home-card {
      column-gap: 30px;
      margin: 0 0 30px;

      a {
        .card {
          @include media-breakpoint-down(md) {
            @include calc(width, "50% - 30px");
          }

          @media (max-width: 480px) {
            @include calc(width, "100% - 30px");
          }
        }
      }
    }
  }
}

.testimonial {
  position: relative;
  padding: 90px 0 110px;

  @media (max-width: $breakpoint-sm) {
    padding: 30px 0 20px;
  }

  .container {
    max-width: 990px;
  }

  .testimonial-slide {
    margin: 0;

    .slick-list.draggable {
      overflow-x: hidden;
      overflow-y: -webkit-paged-y;
    }

    .slick-list {
      margin: 0;
    }

    .item {
      background-color: $offwhite;
      margin-left: 120px;
      margin-top: 30px;
      padding: 15px 30px 15px 80px;
      position: relative;
      min-height: 175px;
      width: 80% !important;

      @media (max-width: $breakpoint-sm) {
        margin-top: 70px;
        margin-left: 0;
        padding: 70px 20px 20px;
        min-height: 50px;
      }

      .image-wrap {
        width: 176px;
        height: 176px;
        border-radius: 50%;
        position: absolute;
        top: -30px;
        left: -120px;
        overflow: hidden;
        box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);

        @media (max-width: $breakpoint-sm) {
          width: 80px;
          height: 80px;
          left: 20px;
          top: -40px;
        }
      }

      .content-wrap {
        .testimonial-list {
          margin: 0 0 30px;
          float: left;
          width: 100%;

          @include media-breakpoint-down(sm) {
            margin: 0 0 20px;
          }

          li {
            position: relative;
            float: left;
            font-size: 18px;
            font-weight: 600;
            margin-right: 40px;

            @media (max-width: $breakpoint-sm) {
              font-size: 18px;
            }

            &:first-child {
              font-size: 24px;
              font-weight: 400;

              @media (max-width: $breakpoint-sm) {
                font-size: 18px;
              }
            }

            &:first-child:after {
              content: "";
              position: absolute;
              width: 1px;
              height: 24px;
              background-color: $white;
              top: 0;
              right: -20px;
            }
          }
        }

        p {
          font-size: 20px;
          line-height: 32px;
          font-style: italic;
          overflow-wrap: break-word;

          @media (max-width: $breakpoint-sm) {
            font-size: 18px;
            color: $black;
          }
        }
      }
    }

    .slick-dots {
      bottom: -35px;
      z-index: 10;

      li {
        button {
          width: 15px;
          height: 15px;
          border: 1px solid $lightgray;
          background-color: transparent;

          @media (max-width: $breakpoint-sm) {
            width: 12px;
            height: 12px;
          }
        }

        &.slick-active {
          button {
            background-color: $lightgray;
          }
        }
      }
    }
  }

  .sign-in-wrap {
    width: 100%;
    height: 100%;
    background-color: $red;
    padding: 14px 9px 20px;
    margin: 30px 0 0;
    position: relative;
    z-index: 9;

    @media (max-width: $breakpoint-sm) {
      margin: 70px 0 0;
      padding: 20px 10px;
    }

    h4 {
      font-size: 18px;
      font-weight: 400;
      color: $white;
      margin: 0 0 29px;

      @media (max-width: $breakpoint-sm) {
        margin: 0 0 20px;
      }
    }

    .white-button {
      @media (max-width: $breakpoint-sm) {
        width: 100px;
      }
    }
  }
}

.our-client {
  position: relative;
  padding: 60px 0 65px;
  background-color: $offwhite;

  @media (max-width: $breakpoint-sm) {
    padding: 40px 0 35px;
  }

  .container {
    max-width: 1160px;
  }

  h1 {
    font-weight: 600;
    margin: 0 0 70px;

    @media (max-width: $breakpoint-sm) {
      font-size: 24px;
      margin: 0 0 30px;
    }
  }

  img {
    margin: 0 0 60px;

    @media (max-width: $breakpoint-sm) {
      margin: 0 0 40px;
    }
  }

  .text-right,
  .text-left {
    @media (max-width: $breakpoint-md) {
      text-align: center !important;
    }
  }

  .contact-wrap {
    padding: 50px 0 0;
    border-top: 1px solid $white;

    @media (max-width: $breakpoint-sm) {
      padding: 30px 0 0;
    }

    h4 {
      font-weight: 600;
    }

    p {
      font-size: 18px;
      margin: 0 0 25px;
    }
  }
}
