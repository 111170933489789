.login-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 900px;
    margin: auto;
    align-items: center;
    padding: 12px;

    .login-container {
        display: flex;
        gap: 50px;
        width: 100%;

        .left,
        .right {
            width: 100%;
        }

        .section-title {
            color: $black;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 20px;
        }

        .divider-section {
            color: $gray;
            font-weight: 700;
        }

        .left {
            padding: 5px;

            .login-form {
                margin: auto;
            }
        }

        .center {
            padding: 5px;

            @media (min-width: $breakpoint-lg) {
                .divider {
                    width: 1px;
                    left: 30px;
                    margin: 10px auto;
                    height: 85%;
                    background: $main-black;
                    content: "";
                }
            }

            @media (max-width: $breakpoint-lg) {
                display: flex;

                .divider {
                    width: 100%;
                    display: inline-block;
                    margin-left: 10px;
                    height: 1px;
                    background: $main-black;
                }
            }
        }

        .right {
            padding: 5px;


            .bimmatch-button {
                padding: 10px 16px;
                margin: 0;
                max-width: unset;
                width: 100%;
            }

            .bimmatch-button-primary {
                border: 1px solid $black;
                color: $white;

                margin: 0 auto;
                margin-top: 15px;
                text-align: start;

                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
            }

            .google-signin {
                background-color: $lightblue;
                border-color: $lightblue;
            }

            .autodesk-signin {
                background-color: $black;
            }
        }
    }

    @media (max-width: $breakpoint-lg) {
        justify-content: unset;
        align-items: unset;

        .section-title {
            display: none;
        }

        .login-container {
            width: 100%;
            display: block;

            .login-form,
            .form-group {
                width: 100%;
                max-width: unset !important;
            }
        }
    }

    .reset-password {
        margin-top: 10px;
        display: flex;

        .bimmatch-anchor-tag {
            margin-right: 10px;
        }


        .circle-loader {
            margin-right: 10px;
        }

        .input-text:first-child {
            margin-bottom: 20px;
        }
    }
}