.grouped-priority-description {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: auto;
    margin-top: 16px;

    .grouped-priority-description-header {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .specs-childs{
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
}