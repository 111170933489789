.rfq-data-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 15px 15px 15px;
  justify-content: space-between;
  font-weight: lighter;

  .project-title {
    font-weight: bold;
    font-size: 16px;
  }

  .project-rfq-info {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;

    .project-rfq-info-icon {
      margin-right: 8px;
    }

    .project-rfq-info-category {
      font-weight: bold;
      margin-right: 8px;
      align-content: center;
      font-size: 16px;
    }

    .project-rfq-info-subcategory {
      font-weight: bold;
      margin-right: 8px;
    }

    .project-rfq-info-separate {
      margin-right: 8px;
    }

    .project-rfq-info-quantity {
      margin-right: 8px;
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      .seprator-line {
        color: $darkgray;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .project-rfq-sustainability-icons {
      margin-right: 8px;
    }
  }

  .project-rfq-description {
    font-weight: lighter;
    margin-top: 20px;
  }

  .project-rfq-location-time {
    display: flex;
    font-weight: lighter;
    margin-top: 20px;

    .project-rfq-location {
      width: 80%;
    }

    .project-rfq-time {
      margin-left: 10px;
      max-width: 300px;
    }
  }

  .project-rfq-distributors {
    // font-weight: bold;
    font-weight: lighter;
    margin-top: 20px;

    .data-container {
      bottom: 40px;
    }

    .head-text {
      width: max-content;
    }
  }

  .chips-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .sustainability-section-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;
    margin-bottom: 20px;

    .section-title {
      font-size: 14px;
      line-height: 18px;
      color: $alt-black;
      margin: 0;
    }
  }

  .categorised-search .data-container .data-main {
    max-height: 300px
  }
}