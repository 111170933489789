.product-list {
  width: 100%;
  position: relative;
  padding-top: 2px;

  h1 {
    margin: 0 0 40px;
  }

  h4 {
    font-weight: 600;
  }

  .dropdown {
    float: right;
    margin: 12px 0 0;

    .dropbtn {
      img {
        width: 18px;
        display: inline-block;
      }
    }
  }

  .fix-menu {
    position: relative;

    // &.side-menu-fix {
    // 	position: fixed;
    // 	top: 105px;
    // }
  }

  .links {
    font-weight: 600;
    color: $blue;
    cursor: pointer;
  }

  .products-family-popover,
  .brands-popover {
    &:hover {
      color: $darkblue;
      cursor: pointer;
    }
  }
}

.product-filter-tag {
  background-color: #eeeeee;
  padding: 4px 12px;
  border-radius: 3px;
  margin-left: 5px;
  img {
    margin-left: 5px;
    width: 10px;
    height: 10px;
  }
  img:hover {
    cursor: pointer;
  }
}

.no-products {
  display: flex;
  margin-top: 10px;

  .message {
    color: #151515;
    font-size: 32px;
    font-weight: 275;
    font-family: $barlow;
    line-height: 38px;
    margin-bottom: 10px;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 28px;
  }

  .clear-all-container {
    display: flex;
  }

  .clear-all-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;
    margin-right: 7px;
    cursor: pointer;
  }

  img {
    height: 100px;
  }
}
