.no-data-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &.size-small {
        .no-data-img {
            height: 50px;
            width: 63px;
        }
    }

    &.size-medium {
        .no-data-img {
            width: 167px;
            height: 131px;
        }
    }

    .no-data-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: center;
        color: $lightgray;
        margin-top: 5px;
    }

    .content {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
}