.bimmatch-circular-ring-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
}

.bimmatch-circular-chart {
    display: block;
    position: absolute;
    // top: 0;
}

.bimmatch-circle-bg {
    fill: none;
    stroke: #eee;
    stroke-width: 2px;
}

.bimmatch-circle {
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    // animation: progress 1s ease-out forwards;
}

// @keyframes progress {
//     0% {
//         stroke-dasharray: 0 100;
//     }
// }