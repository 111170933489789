.bimmatch-key-value-pair {
    font-size: 14px;
    line-height: 20px;

    .key-field {
        color: $mid-gray;
    }

    .highlighted-key-name {
        color: $black;
    }

    .value-field {
        margin-left: 8px;
        word-break: break-word;
    }
    
    .value-field-url {
        color: $black;
        text-decoration: underline !important;
    }
    
    .tags-wrapper{
        display: flex;
        margin-left: 8px;
        gap: 5px;
    }

    .hierarchy-key-value-field{
        border-radius: 8px;
        background: $lightgraytransparent;
        padding: 8px;

        .hierarchy-value-field{
            margin-left: 20px;
        }
    }

    &.custom-property-value-wrapper, &.tags-property-value-wrapper {
        display: flex;
    }
}