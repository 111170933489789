.create-distributor {
    min-width: 400px;

    .create-distributor-main {
        padding: 5px 10px;
        background: #efefef;
        border-radius: 4px;

        .input-text,
        .address-content,
        .input-number-phone {
            width: 48%;
        }

        .remove-distributor {
            height: 25px;

            .remove-icon {
                height: 20px;
                float: right;
                cursor: pointer;
            }

        }

        .input-number-phone {
            .input-number-container {
                width: 100%;
            }
        }

        .address-content {
            padding: 0 0 23px 0;
        }

        .name-email,
        .phone-address {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .address-input {
            height: 40px;
            text-indent: 10px;
            width: 100%;
        }
    }

    .create-distributor-map {
        margin-top: 5px;
    }
}