.inputMultipleFiles {
    margin-bottom: 15px;

    .input-file {
        padding: 0;
    }

    .repeatComponent-horizontal .repeatComponent-children-container {
        padding-top: 5px;
    }
}


.inputMultipleFiles-shadow {
    background: rgba(204, 204, 204, 0.5);
    border-radius: 4px;
    padding: 5px 5px;
}