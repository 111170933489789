.bimmatch-button {
  border-radius: 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 30px;
  height: 41px;
  min-width: 114px;
  cursor: pointer;
  position: relative;

  .bimmatch-button-name {
    margin: 0;
  }
}

.bimmatch-button-primary {
  border: 1px solid $red;
  color: $red;
}

.bimmatch-button-secondary {
  color: $white;
  background: $red;
}

.bimmatch-button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.bimmatch-button-loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: $white;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.bimmatch-button-primary.bimmatch-button-loading::after {
  border-top-color: $red;
}


//Theme Black

.bimmatch-button-primary.bimmatch-button-black {
  border: 1px solid $black;
  color: $black;
}

.bimmatch-button-secondary.bimmatch-button-black {
  color: $white;
  background: $black;
}