.base-layout-wrapper:has(.private-catalog){
  .page-content{
    background: $lightgrayshade;
  }

  .bold-content{
    font-weight: 600;
  }

  .file-upload-success-alert{
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.main-page.private-catalog {
  position: relative;

  .alerts {
    margin: 0 40px;

    .organization-account-alert {
      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }

  //header
  .search-header {
    background-color: $lightgrayshade;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 13;

    .search-header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      padding: unset;

      .org-item-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .org-lib-options {
          margin-left: 10px;
        }
      }

      .org-data {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .org-logo {
          width: 60px;
          margin-right: 10px;
        }
      }
    }

    .bimmatch-popover.create-product-drop-down-btn {
      .bimmatch-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: max-content;
        padding: 10px;

        .bimmatch-button-name {
          display: flex;
          gap: 20px;
          align-items: center;
        }
      }

      .popover-content-bottom {
        left: unset;
        right: -1px;
      }
    }

    .bimmatch-three-dot {
      margin-right: 10px;
    }

    .bimmatch-popover.org-lib-options {
      .popover-content-bottom {
        left: unset;
        right: 15px;
      }

      .popover-arrow {
        left: unset;
        right: 13px;
      }
    }

    .bimmatch-product-filter-container {
      padding: unset;

      .filter-box {
        margin: unset;
      }
    }
  }

  .organization-items-main-container {
    padding-left: unset;
    padding-right: unset;
    padding-top: 150px;
    display: flex;

    .tags-filter-wrapper {
      position: fixed;
      width: 295px;
      max-height: calc(100vh - 200px);
      padding-bottom: 40px;
      overflow: scroll;
    }

    .main-page-container {
      margin-left: 315px;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .org-lib-list-item{

        .left-section{
          .cat-icon-and-name {
            display: flex;
            // max-width: 70%;
            align-items: center;
          }
  
          .cat-icon {
            margin-right: 10px;
            display: flex;
            gap: 10px;
  
            .category-icon{
              width: 24px;
              height: 24px;
            }
          }

          .title{
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .right-section{

          .item-additional-right-section{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 160px;
          }

          .product-status-wrapper {
            display: flex;
            padding: 4px 8px 4px 4px;
            align-items: center;
            border-radius: 24px;
            margin-right: 18px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            width: fit-content;
  
            img {
              margin-right: 4px;
              width: 18px;
              height: 18px;
            }
  
            p {
              width: max-content;
            }
          }
  
          .public-status {
            background-color: $light-green;
            color: $white;
          }
  
          .organization-view-status {
            background: $white;
            color: $black;
          }
  
          .hidden-status {
            background: $darkgray;
            color: $mid-gray;
          }
        }
      }
    }
  }

  // model css

  // delete product model css
  .bimmatch-modal.delete-product {
    .modal-body-wrapper {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 68px;
        height: 68px;
        margin: 50px auto;
      }
    }
  }

  // share org lib model css
  .bimmatch-modal.share-org-lib .modal-container {
    .bimmatch-modal-footer {
      display: none;
    }
  }

  .organization-library-wrapper {
    padding: 16px 25px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    height: 75vh;
    overflow: auto;

    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $black;
    }

    .provide-access-wrapper {
      display: flex;
      gap: 10px;

      .input-text {
        width: 70%;
      }

      .bimmatch-button {
        padding: 10px 12px;
      }
    }

    .org-library-items {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .org-lib-item {
        background-color: $lightgrayshade;
        border-radius: 8px;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $black;

        .info {
          max-width: 80%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .bimmatch-popover {
          cursor: pointer;
        }

        .popover-content {
          width: max-content;
        }
      }
    }
  }

  // rfa model css
  .bimmatch-modal.rfa-upload .modal-container,
  .bimmatch-modal.rvt-upload .modal-container {
    width: unset !important;
    min-width: 640px;
  }

  .rfa-modal-body,
  .rvt-modal-body {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;

    .heading {
      margin-bottom: 10px;
    }

    .instructions {
      margin-bottom: 18px;
    }

    .row-content {
      display: flex;
      gap: 6px;

      &.items-center {
        align-items: center;
      }

      .list-content {
        display: flex;
        flex-direction: column;
        color: $alt-black;
        line-height: 20px;
      }

      img {
        width: 18px;
        height: 18px;
      }

      img.description-icon {
        width: 24px;
        height: 24px;
      }
    }

    .input-file {
      width: unset;
      padding: 0;
      margin: auto;
    }

    .input-file-container {
      label {
        width: 100% !important;
        min-height: 200px;
        min-width: 600px;
      }

      .rfa-file-container {
        max-width: 80%;

        .rfa-files {
          display: flex;
          justify-content: center;
          align-items: center;

          h4 {
            color: #696969;
          }

          p {
            color: #696969;
          }

          &.empty-data {
            flex-direction: column;
          }

          &.with-data {
            position: relative;
            z-index: 3;
            flex-wrap: wrap;
            gap: 10px;
          }
        }
      }
    }

    .error-text {
      text-align: left;
    }
  }
}