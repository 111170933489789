.project-detail {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 60px 0 0;
  }

  .hide-menu {
    font-size: 10px;
    padding: 6px 0px;
    width: 90px;
    position: fixed;
    left: 0;
    top: 70px;
    text-align: center;
    color: $white;
    background-color: $gray;
    text-transform: uppercase;
    display: inline-block;
    z-index: 9;
    cursor: pointer;

    @media (max-width: $breakpoint-md) {
      position: absolute;
      top: 60px;
    }
  }

  .content-left {
    @include calc(width, "100% - 575px");
    float: left;
    height: calc(100% - 70px);
    background-color: $offwhite;
    position: fixed;
    left: 0;
    z-index: 11;
    // overflow-y: scroll;
    overflow: hidden;

    @media (max-width: $breakpoint-md) {
      min-height: 400px !important;
      position: relative;
      width: 100%;
      top: 0;
    }

    &.height-100 {
      top: 0;
    }

    .project-cube-wrap {
      padding-top: 58px;
      height: 100%;

      &.height-100 {
        top: 0;
      }

      .cube-edit-wrap {
        position: absolute;
        top: 80px;
        right: 300px;

        @media (max-width: $breakpoint-sm) {
          right: 20px;
        }
      }

      .gallery-thumb {
        position: fixed;
        left: 30px;
        bottom: 10px;
        max-width: 90px;
        max-height: 90px;

        .edit-wrap {
          position: absolute;
          right: -20px;
          top: -15px;
        }

        @media (max-width: $breakpoint-md) {
          position: absolute;
        }

        &:hover .hover {
          display: block !important;
        }

        .img-wrap {
          position: relative;

          .hover {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            @include background-opacity($black, 0.6);
            display: none;

            a {
              font-weight: 600;
              color: $white;
              text-transform: uppercase;
              white-space: nowrap;
              display: block;
              text-align: center;
              height: 100%;
              line-height: 90px;

              img {
                width: 16px;
                margin-right: 3px;
                margin-top: -2px;
              }
            }
          }
        }
      }

      background: #eeeeee;
    }

    .bim-processing-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      h3 {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $alt-black;
      }

      .bimmatch-button {
        margin-top: 70px;
      }
    }

    .project-viewer-toggle-container {
      position: fixed;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.5);
      top: 70px;
      left: 0px;
      @include calc(width, "100% - 575px");
      display: flex;
      padding: 9px 30px;
      align-items: center;
      gap: 10px;

      .project-viewer-toggle-button-wrapper {
        width: 120px;
      }

      .btn-danger {
        background-color: $black;
        border-color: $black;
      }

      .btn-danger:focus {
        box-shadow: 0 0 0 0.2rem rgba($color: $black, $alpha: 0.5);
      }

      .carbon-value-wrapper {
        margin-left: auto;
      }

      .standard-report-wrapper {
        margin-right: 18px;
      }

      // select views css
      .bimmatch-select {
        padding: 0;
      }

      .view-drop-down {
        .search-input {
          height: 39px;
          border: none;
        }

        .single-select-selected-value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .bom-generate-failure-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        margin-bottom: 30px;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: $err-red;
      }

      p {
        color: $alt-black;
        font-weight: 400;
      }

      h4+p {
        font-size: 14px;
        line-height: 20px;
      }

      .bimmatch-button {
        margin: 90px 0 20px 0;
      }

      .bimmatch-button+p {
        font-size: 12px;
        line-height: 16px;
      }
    }

    .project-viewer-button-cotainer {
      position: fixed;
      z-index: 99;
      top: 79px;
      left: 40px;
    }
  }

  .content-right {
    width: 575px;
    float: right;
    background: $white;
    // height: 91.5vh;
    height: calc(100vh - 90px);

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    .content-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: $black;

      .bimmatch-product-filter-container {
        .match-header {
          font-size: 24px;
        }
      }

      .image-wrap {
        position: relative;
        min-height: 70px;

        // &:hover .overlay-hover {
        //   opacity: 1;
        // }

        &:hover .overlay-text {
          @include background-opacity(white, 0.75);
        }

        .overlay-text {
          position: absolute;
          left: 0;
          bottom: -1px;
          right: 0;
          padding: 10px 20px;
          @include background-opacity(white, 0.6);

          @include media-breakpoint-down(xs) {
            bottom: 0;
            position: relative;
          }

          h4 {
            margin: 0 0 15px;
          }

          h5 {
            font-size: 18px;
            font-weight: 600;
            margin: 0 0 8px;
          }

          .edit-content-wrap {
            @include flexbox();
            @include align-items(center);

            .text-wrap {
              padding-left: 20px;
            }

            .edit-wrap {
              margin-left: 15px;
            }
          }

          .location-with-date {
            display: flex;

            .address,
            .creation-date {
              display: flex;

              img {
                width: 17px;
                height: 17px;
                margin-right: 10px;
              }
            }

            .creation-date {
              margin-left: 20px;
            }
          }

          .activity-list {
            float: right;
            margin: 0 -10px;

            @media (max-width: $breakpoint-xs) {
              float: left;
              margin: 5px -10px 0;
            }

            li {
              position: relative;
              display: inline-block;
              padding: 12px 0;

              &:first-child:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 18px;
                right: -1px;
                top: 14px;
                background-color: $offwhite;
              }

              a {
                color: $gray;
                font-weight: 600;
                padding: 2px 10px;

                @media (max-width: $breakpoint-xs) {
                  font-size: 12px;
                }

                img {
                  width: 15px;
                  margin: -3px 2px 0 0;

                  @media (max-width: $breakpoint-xs) {
                    width: 9px;
                  }
                }
              }
            }
          }

          .row {
            justify-content: space-between;
          }
        }

        // .overlay-hover {
        //   position: absolute;
        //   top: 10px;
        //   left: 10px;
        //   right: 10px;
        //   bottom: 70px;
        //   opacity: 0;
        //   opacity: 0;
        //   cursor: pointer;
        //   @include transition(all 0.5s ease);
        //   @include background-opacity($gray, 0.6);

        //   @media (max-width: $breakpoint-xs) {
        //     bottom: 84px;
        //   }

        //   p {
        //     position: absolute;
        //     top: 50%;
        //     left: 50%;
        //     @include translate(-50%, -50%);

        //     a {
        //       font-weight: 600;
        //       color: $white;

        //       img {
        //         width: 16px;
        //         margin-right: 3px;
        //       }
        //     }
        //   }
        // }
      }

      .own-details {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        padding: 10px 20px;
        border-bottom: 1px solid $offwhite;

        .own-image {
          display: flex;
          flex-direction: column;
          width: 50px;
          height: 50px;
          border-radius: 25px;
          float: left;
          overflow: hidden;
        }

        .content {
          @include calc(width, "100% - 90px");
          display: flex;
          flex-direction: column;
          float: left;

          .grid {
            height: 100%;
            display: grid;

            .align-auto {
              padding: 0 12px;
              margin: auto 0;

              .info-list {
                li {
                  color: $gray;
                  display: inline-block;
                  padding-right: 30px;
                  position: relative;

                  &:first-child:after {
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 18px;
                    background-color: $offwhite;
                    right: 13px;
                    top: 0;
                  }

                  a {
                    font-size: 14px;
                    font-weight: 600;
                    color: $blue;

                    &:hover {
                      color: $darkblue;
                    }
                  }
                }
              }

              .designation {
                font-size: 12px;
                margin: 0 0 5px;
              }
            }
          }
        }
      }

      .recommended-wrap {
        padding: 35px 15px 0 15px;
      }

      .successfully-message {
        font-size: 18px;
        font-weight: 600;
        background-color: $red;
        color: $white;
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
        position: fixed;
        bottom: 30px;
        right: 30px;
        padding: 21px 22px;

        img {
          margin-right: 15px;
        }
      }
    }
  }

  .bom-view-container {
    height: 100%;
    overflow: auto;
  }

  .rfq {
    .modal-container {
      .bimmatch-modal-footer {
        padding: 15px;
      }
    }
  }

  .distributor {
    .modal-container {
      .bimmatch-modal-head {
        padding-right: 10px;
        padding-left: 15px;
      }
    }
  }

  .bom-viewer {
    .total-price-container {
      margin: 10px 10px;
      height: 15px;

      .tota-price {
        width: 100%;
        font-family: "Open Sans", sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        text-align: right;
        color: $black;
        z-index: 99;
        padding-right: 35px;

        .total-price-circle {
          position: relative;
          margin: 0;
          float: right;
          width: 18px;
          height: 18px;
          margin-left: 5px;
        }

        img {
          width: 15px;
          height: 15px;
          margin-left: 10px;
        }
      }

      .card-total-price {
        height: 20px;
        padding-right: 50px;
      }
    }

    .nav-breadcrumb {
      cursor: pointer;

      svg {
        fill: $black;
      }

      b {
        color: $black;
        font-weight: 600;
        font-size: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .cards-container {
      display: grid;
      padding: 20px 30px;
      grid-template-columns: 1fr 1fr 1fr;
      justify-items: stretch;
      align-items: center;
      column-gap: 20px;
      row-gap: 20px;
    }

    .card {
      border-radius: 5px;
      cursor: pointer;
    }

    .card-body {
      padding: 10px;
    }

    .card-title {
      display: flex;
      align-items: center;
      font-size: 13px;

      img {
        width: 20px;
        height: 20px;
      }

      .main-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding-left: 10px;
        max-width: 90%;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
        color: $black;
      }
    }

    .card-subtitle {
      font-size: 12px;
      display: flex;
      align-items: center;
      color: $black;
      padding-left: 30px;
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      padding: unset;
      background-color: unset;
      border-top: unset;
      margin-top: 5px;
    }

    .certificate-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }

    .card-price {
      display: flex;
      align-items: center;

      .price-text {
        font-size: 12px;
        color: $black;
        padding-right: 5px;
      }
    }

    .price-icon {
      width: 15px;
      height: 15px !important;
      margin-right: 5px;
    }

    .back-icon {
      width: 12px;
      height: 24px;
      cursor: pointer;
    }

    .bom-tile-card:hover {
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);

      .category {
        .bom-tile-card-options {
          display: block;

          .popover-content {
            display: block;
          }
        }
      }
    }

    .bom-tile-card {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      background-color: $white;
      border-radius: 4px;
      cursor: pointer;

      .category {
        display: flex;
        gap: 12px;
        align-items: center;

        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $black;

        .bom-tile-card-options {
          margin-left: auto;
          display: none;

          .popover-content,
          .popover-content .popover-arrow {
            content: none;
            left: unset;
            right: 0;
            margin-right: 5px;
            margin-top: -15px;
            display: none;
          }

          .popover-content {
            // margin: 10px 8px;
            min-width: 200px;
            align-items: center;
            font-family: $opensans;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $black;
            padding: 8px;

            .menu-wrapper {
              padding: 5px;
              display: flex;
              cursor: pointer;
            }

            .menu-wrapper:hover {
              background-color: $offwhite;
              border-radius: 4px;
            }

            img {
              margin-right: 10px;
              height: 20px;
              width: 20px;
            }
          }

          .popover-arrow {
            display: none;
          }
        }
      }

      .count-info {
        display: flex;
        gap: 10px;
      }

      .count-info,
      .calculated-cost {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .items-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        &.space-between {
          justify-content: space-between;
        }
      }
    }

    //TABLE TREE
    .table-wrapper {
      padding: 10px 20px;

      .table-container {
        padding: 10px 10px;
        background-color: $white;

        .row-wrapper {
          display: flex;
          gap: 10px;
          padding: 6px 0;

          .object-name {
            display: flex;
            width: 30%;
            align-items: center;
            min-width: 30%;

            .standard-report {
              margin-left: auto;
            }
          }
        }
      }

      .table-header {
        display: flex;
        align-items: center;
        padding: 10px 0 20px 0;
        gap: 10px;

        .object-name {
          display: flex;
          gap: 10px;
          width: 30%;
          min-width: 30%;
          align-items: center;

          .standard-report {
            margin-left: auto;
          }
        }
      }

      .bom-table-row-options {
        margin-left: auto;
        cursor: pointer;
        // visibility: hidden;

        .popover-content,
        .popover-content .popover-arrow {
          left: unset;
          right: 0;
          content: none;
          margin-right: 1px;
          margin-top: -5px;
        }

        .popover-content {
          min-width: 200px;
          font-family: $opensans;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          padding: 8px;

          div {
            display: flex;
            cursor: pointer;
            padding: 5px;
          }

          div:hover {
            background-color: $lightgrayshade;
            border-radius: 4px;
          }

          img {
            margin-right: 10px;
            height: 20px;
            width: 20px;
          }
        }

        .popover-arrow {
          display: none;
        }
      }
    }

    .table-wrapper {
      .table-container {
        .row-wrapper:hover {
          .bom-table-row-options {
            visibility: visible;
          }
        }
      }
    }

    .col,
    .col-2,
    .col-4,
    .col-6 {
      padding-left: 0px;
      padding-right: 0px;
    }

    .row-wrapper {
      height: 40px;
      border-bottom: 1px solid #eee;
      margin-left: 0px;
      margin-right: 0px;

      .row-title {
        margin-left: 10px;
        overflow-wrap: break-word;
        word-break: break-all;
        text-decoration: underline;
        cursor: pointer;
      }

      &>div,
      & .back-icon {
        display: flex;
        height: 100%;
        align-items: center;
      }
    }

    // .row-level-3,
    // .row-level-2 {
    //   cursor: pointer;
    // }

    .row-selected {
      border: 1px solid $black;
    }

    .row>div:last-child {
      justify-content: center;
    }

    .row>div:last-child:hover {
      text-decoration: underline;
    }

    .row-level-1:hover,
    .row-level-2:hover {
      background-color: #f5f4f4;
    }
  }

  .bom-viewer,
  .table-wrapper,
  .bom-table-wrapper {
    height: 100%;
  }

  .bimmatch-table-container {
    height: 100%;

    colgroup col:first-child,
    th.item-name,
    td.item-name {
      width: 350px !important;
      max-width: 350px;
    }

    colgroup col:last-child {
      width: 20px !important;
      max-width: 20px;
    }

    thead {
      z-index: 2 !important;
    }

    .items-row {
      color: $black;
      font-weight: 600;
      height: 100%;
    }

    .align-right .carbon-value {
      margin-left: auto;
    }

    .additional-row-cell-container {
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: center;

      img {
        width: 12px;
      }
    }

    th .items-row {

      .row-title,
      .history-wrapper {
        pointer-events: none;
      }
    }

    td .items-row {

      .row-title,
      .history-wrapper {
        cursor: pointer;
      }
    }

    .additional-header {
      th {
        color: $mid-gray;
        padding: 5px;
        font-weight: 400;
        font-size: 12px;
      }

      th.sortable {
        cursor: pointer;
      }

      th:first-child {
        background-color: $offwhite;
      }
    }

    thead tr th:first-child {
      gap: 10px;
      font-size: 14px;
    }

    tr.material-applied td:has(.material-wrapper) {
      background-color: $offwhite;
    }

    .row-title.clickable {
      text-decoration: underline;
    }

    .tree-level {
      width: 16px;
    }

    .tree-level.level-3+.row-title {
      margin-left: 10px;
    }

    .bimmatch-popover .popover-content {
      white-space: initial;
      font-size: 14px;
      line-height: 17px;
    }

    .sustainibility-icons-wrapper {
      justify-content: center;
    }

    .divisions {
      .bimmatch-tag {
        margin-right: 5px;
      }
    }

    .material-wrapper {
      color: $black;
      font-size: 12px;
      font-weight: 400;
      line-height: normal;
      display: flex;
      gap: 8px;
      align-items: center;

      .material-bullet {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-left: 2px;
        min-width: 14px;
      }

      .material-name {
        max-width: 100px;
        color: $lightgray;

        &.material-applied {
          color: $black;
        }
      }

      .compound-layer-wrapper {
        display: flex;
        gap: 4px;
        align-items: center;
        font-style: italic;
        font-weight: 400;
        color: $black;

        img {
          width: 12px;
          height: 12px;
        }
      }

      .compound-material-tooltip-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
        white-space: nowrap;

        .material-row {
          display: flex;
          gap: 4px;
        }
      }
    }
  }
}

.location-and-search {
  display: flex;

  .select-location {
    margin-right: 10px;
  }
}

.select-material {
  .selectBox {
    height: 410px;

    .categorised-search .select-tree-container {
      max-height: 100%;
    }
  }
}

.sandbox-email-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: $mid-gray;
  }
}

.bimmatch-modal.materials-carbon-emission {
  .reset-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    img {
      width: 18px;
      height: 18px;
    }

    p {
      line-height: 22px;
      white-space: nowrap;
    }
  }
}

.material-options-container {
  animation: appear 800ms ease-in 1;
}

.total-count {
  margin-top: 15px;
  display: flex;
  width: 510px;
  // justify-content: space-between;
  align-items: center;
  color: #363636;

  p {
    font-size: 18px;
  }

  .ver-line {
    border: 1px solid #cccccc;
    width: 0px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .clear-btn {
    font-size: 14px;
    color: #363636;
  }

  .clear-btn:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.specific-match-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 400px;

  .left-arrow {
    margin-right: 10px;
  }
}

.export-bom {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;

  .btn {
    padding: 0;
  }
}

.categorised-search .select-open-main {
  max-height: 350px;
}

.history-wrapper {
  margin-left: 10px;
}

.dropdown.share-dropdown {
  position: relative;

  &:hover .dropdown-content {
    display: none;
  }

  .dropbtn {
    padding: 2px 10px;
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);

    svg {
      margin-right: 6px;
    }
  }

  .dropdown-content {
    &.share_drop_content {
      // right: -30px;
      // min-width: 302px;
      padding: 0;
      -webkit-box-shadow: 0px 2px 6px rgba(21, 21, 21, 0.5);
      -moz-box-shadow: 0px 2px 6px rgba(21, 21, 21, 0.5);
      box-shadow: 0px 2px 6px rgba(21, 21, 21, 0.5);

      @media (max-width: $breakpoint-xs) {
        right: inherit;
        left: 0;
      }

      &::after {
        right: 42px;

        @media (max-width: $breakpoint-xs) {
          right: inherit;
          left: 20px;
        }
      }

      a {
        display: block;
        float: left;
        width: 60px;
        height: 60px;
        line-height: 60px;
        padding: 0 !important;
        text-align: center;

        svg {
          color: $black;
        }

        &:hover {
          background-color: $offwhite;

          svg {
            color: $blue;

            &.pinterest-icon {
              color: #c8232c;
            }

            &.houzz-icon {
              color: #73ba37;
            }

            &.facebook-icon {
              color: #3b5998;
            }

            &.linkedin-icon {
              color: #007ebb;
            }

            &.mail-icon {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

.options-popover-content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .option-content-item {
    display: flex;
    padding: 5px;
    align-items: center;
    cursor: pointer;
    width: max-content;

    img {
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      white-space: nowrap;
    }

    &:hover {
      background-color: $lightgrayshade;
      border-radius: 4px;
    }
  }

  hr {
    margin: 12px 0;
  }
}

.no-projects {
  display: flex;
  margin-top: 10px;
  color: #151515;

  img {
    height: 250px;
  }

  .message {
    color: #151515;
    font-size: 32px;
    font-weight: 275;
    font-family: $barlow;
    line-height: 38px;
    margin-bottom: 12px;
  }

  .message-container {
    display: flex;
    flex-direction: column;
    justify-content: end;
    margin-left: 28px;
  }

  .secondary-message {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    font-family: $barlow;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .create-project-btn {
    font-family: $opensans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: $black;
    font-stretch: 100;
    cursor: pointer;
  }
}

.bimmatch-popover .popover-content:has(.bimmatch-tooltip) {
  background-color: #000;
  z-index: 11;
  color: #fff;
  border-color: #000;
  left: 0;
  right: 0;
  margin: auto;
  // bottom: 100%;
  font-weight: initial;

  max-width: 312px;
  width: max-content;
  text-align: start;

  margin-left: 0 !important;
  transform: translateX(-50%);

  &.popover-content-top .popover-arrow {
    border-color: #000 transparent transparent transparent !important;
  }

  &.popover-content-bottom .popover-arrow {
    border-color: transparent transparent #000 transparent !important;
  }
}

.sustainibility-icons-wrapper {
  display: flex;
  gap: 6px;

  img {
    width: 18px;
    height: 18px;
  }

  img.blank-pillar-icon {
    width: 22px;
    height: 22px;
  }

  .bimmatch-tooltip {
    display: flex;
    gap: 10px;
  }
}

.carbon-value {
  svg {
    width: 16px;
    height: 16px;

    * {
      fill: $mid-gray;
    }
  }

  background-color: $lightgrayshade;
  border-radius: 4px;
  display: flex;
  gap: 4px;
  padding: 4px;
  width: fit-content;
  max-width: 100%;

  p {
    margin: 0 auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    width: 100%;
  }

  .light-color {
    color: $mid-gray;
  }

  .extream-light-color {
    color: #aaaaaa;
  }

  &.type-gwp {
    background-color: $black;
    color: $white;

    .value {
      color: $white;
    }

    .unit {
      color: $darkgray;
    }
  }

  &.type-material {
    .value {
      color: $black;
    }

    .unit {
      color: $mid-gray;
    }
  }

  &.type-icon {
    background-color: $white;

    .unit,
    .value {
      color: $mid-gray;
    }

    color: $mid-gray;
  }

  &.empty-value {

    .value,
    .unit {
      color: $lightgray !important;
    }
  }
}

.calculated-cost {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: $black;
}

.acc-project-chip {
  background: $offwhite;
  border-radius: 24px;
  padding: 5px 8px;
  display: flex;
  gap: 5px;
  color: $alt-black;
}

.bimmatch-standard-report {
  margin: auto;
}

.bimmatch-standard-report-small {
  width: 24px;

  .highcharts-container,
  .highcharts-root {
    width: 24px !important;
    height: 24px !important;
  }
}

.bimmatch-standard-report-medium {
  width: 36px;

  .highcharts-container,
  .highcharts-root {
    width: 60px !important;
    height: 60px !important;
  }
}

.circular-sustainibility-piller-wrapper {
  display: flex;
  gap: 5px;
  white-space: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

// right panel css

.project-detail .content-right .content-wrap .recommended-wrap,
.bimmatch-plugin .plugin-content {
  .tab-heading-text {
    color: $black;
    font-size: 16px;
    font-weight: 600;
    line-height: 32px;
  }

  .horizontal-section-separator {
    background: $offwhite;
  }

  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;

  .filter-box {
    .mulifield-filter-item {
      flex-direction: column;
    }
  }

  .invite-team-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // p {
    //   margin: 20px 0;
    // }

    .copy-wrapper {
      display: flex;

      .btn-outline-primary {
        color: $red;
        border-color: $red;

        &:hover {
          color: $white;
          background-color: $red;
        }
      }

      button {
        margin: 0 20px;
        padding: 0 30px;
      }
    }

    .team-list-wrapper {
      .team-list {
        display: flex;
        flex-direction: column;
        gap: 5px;

        li {
          height: 40px;
          align-items: center;
          display: flex;
        }

        .own-image {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
        }

        .team-name {
          margin: 0 10px;
          font-size: 16px;
          margin-right: auto;
        }

        .team-member-email {
          cursor: pointer;
          text-decoration: underline;
          color: $alt-black;
        }
      }
    }
  }

  .recommended-list {
    margin: 0 0 15px;

    &.first {
      li {
        &:first-child {
          font-size: 18px;

          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
        }
      }
    }

    li {
      font-weight: 600;
      display: inline-block;
      margin-right: 24px;
      position: relative;

      @include media-breakpoint-down(lg) {
        margin-right: 16px;
      }

      @include media-breakpoint-down(xs) {
        display: block;
        margin: 0 0 10px;
      }

      &:not(:last-child):after {
        content: "";
        position: absolute;
        top: 0;
        right: -12px;
        width: 1px;
        height: 100%;
        background-color: $offwhite;

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  .filter-list {
    margin: 0 -8px;

    li {
      text-decoration: none;
      font-size: 14px;
      color: $black;
      display: inline-block;
      position: relative;
      margin-right: 10px;
      cursor: pointer;

      // &.filter-prod-detail {
      //     display: block;
      //     float: left;
      // }

      &:first-child,
      &:last-child {
        font-weight: 600;
        color: $blue;
        background-color: transparent !important;
        padding: 5px 0 5px 8px !important;
      }

      &.clear-all-filter {
        padding: 5px 0 5px !important;
      }

      &:not(:first-child) {
        background: $offwhite;
        padding: 5px 25px 5px 8px;
        border-radius: 5px;
      }

      // &:hover {
      //     color: $white;
      //     background: $blue;

      //     .close-tag {
      //         color: $white;
      //     }
      // }

      .close-tag {
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 5px;
        padding: 2px 2px;
        font-size: 22px;
      }
    }
  }

  .system-price {
    // overflow: hidden;
    width: 100%;
    float: left;
    margin: 30px 0 25px;

    .text {
      width: 100px;
      float: left;
      font-size: 14px;
      color: $lightgray;

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin: 0 0 25px;
      }
    }

    .price-drag-wrap {
      float: left;
      @include calc(width, "100% - 100px");

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }

  .applied-product-header {
    display: flex;
    align-items: center;
    gap: 10px;

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $alt-black;
    }

    .default-fixed-chip {
      .chip {
        border: none;
        cursor: default;
        background-color: #cccccc;
        border-radius: 24px;

        .chip-icon {
          border-radius: unset;
        }

        .chip-name {
          color: $black;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  .product-list {
    margin-top: 20px;
    padding: 5px;
    overflow-y: scroll;
    height: 100%;

    .product-row {
      justify-content: space-between;
      row-gap: 10px;

      .product-card-item {
        // width: calc(33% - 8px);
        width: calc(33.333% - 30px);
      }

      .product-wrap {
        height: 100%;
        box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);
        margin: 0 0 20px;
        position: relative;

        &.active {
          border: 1px solid $red;
        }

        .add-to-project-icon {
          position: absolute;
          top: 0;
          right: 0;
          width: auto;
          height: 15px;
          font-size: 10px;
          color: $white;
          padding: 2px 5px;
          background-color: $red;
          z-index: 9;
          text-align: center;
          line-height: 12px;
          display: none;

          &.active {
            display: block;
          }

          img {
            width: 10px;
            display: inline-block;
            margin: -4px 0 0;
          }
        }

        .image-wrap {
          position: relative;

          .image {
            min-height: 125px;

            @include media-breakpoint-down(md) {
              min-height: 195px;
            }

            @include media-breakpoint-down(xs) {
              min-height: 250px;
            }

            @media (max-width: 420px) {
              min-height: 190px;
            }
          }

          // .overlay-hover {
          //   position: absolute;
          //   left: 5px;
          //   top: 5px;
          //   right: 5px;
          //   bottom: 5px;
          //   opacity: 0;
          //   z-index: 10;
          //   background-color: transparent;
          //   @include transition(all 0.5s ease);

          //   .add-project-button {
          //     color: $white;
          //     @include background-opacity($red, 0.6);
          //     display: block;
          //     width: 100%;
          //     @include calc(height, "50% - 2.5px");
          //     text-align: center;
          //     margin: 0 0 5px;
          //     position: relative;

          //     &.active {
          //       .center {
          //         .icon {
          //           background-image: url(#{$assetPath}/icon/pin-fill-white.svg);
          //         }
          //       }
          //     }

          //     .center {
          //       position: absolute;
          //       width: 100%;
          //       top: 50%;
          //       left: 50%;
          //       @include translate(-50%, -50%);

          //       .icon {
          //         background-image: url(#{$assetPath}/icon/pin-white.svg);
          //         background-repeat: no-repeat;
          //         width: 16px;
          //         height: 16px;
          //         display: inline-block;
          //         margin-right: 5px;
          //         vertical-align: middle;
          //       }
          //     }

          //     &:hover {
          //       @include background-opacity($red, 0.85);
          //     }
          //   }

          //   .view-detail-button:first-child {
          //     margin-bottom: 5px;
          //   }

          //   .view-detail-button {
          //     color: $white;
          //     @include background-opacity($black, 0.6);
          //     width: 100%;
          //     @include calc(height, "50% - 2.5px");
          //     display: block;
          //     border-radius: 3px;

          //     span {
          //       width: 100%;
          //       height: 100%;
          //       display: flex;
          //       justify-content: center;
          //       align-items: center;

          //       img {
          //         width: 16px;
          //         display: inline-block;
          //         margin-right: 5px;
          //       }
          //     }

          //     &:hover {
          //       @include background-opacity($black, 0.85);
          //     }
          //   }

          //   .action-button-disabled {
          //     opacity: 0.5;
          //     pointer-events: none;
          //     // span {
          //     //   pointer-events: none;
          //     // }

          //     &:hover {
          //       @include background-opacity($black, 0.6);
          //     }
          //   }
          // }
        }

        .card-block {
          padding: 10px 5px;

          .card-title {
            color: $black;
            margin: 0 0 7px;
          }

          .quality-img {
            float: right;

            img {
              width: 18px;
              display: inline-block;

              &:last-child {
                margin-left: -12px;
              }
            }
          }
        }

        .item-highlight {
          position: absolute;
          top: 0;
          right: 0;
          width: auto;
          height: 15px;
          font-size: 10px;
          color: #fff;
          padding: 2px 5px;
          background-color: $red;
          z-index: 9;
          text-align: center;
          line-height: 12px;

          &.pin {
            padding-left: 15px;
            background-image: url(#{$assetPath}/icon/pin-fill-white.svg);
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: 2px 2px;
          }

          &.lock {
            padding-left: 12px;
            background-image: url(#{$assetPath}/icon/lock-white.svg);
            background-repeat: no-repeat;
            background-size: 7px;
            background-position: 2px 3px;
          }
        }

        // &:hover .overlay-hover {
        //   opacity: 1 !important;
        // }

        &.lock {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            background-color: $red;
            background-image: url(#{$assetPath}/icon/lock-white.svg);
            background-repeat: no-repeat;
            background-position: center center;
            z-index: 9;
          }
        }

        .image-wrap {
          position: relative;

          // .overlay-hover {
          //     position: absolute;
          //     left: 5px;
          //     top: 5px;
          //     right: 5px;
          //     bottom: 5px;
          //     opacity: 0;
          //     z-index: 10;
          //     background-color: transparent;
          //     @include transition(all 0.5s ease);

          //     .view-detail-button {
          //         color: $white;
          //         @include background-opacity ($black, 0.6);
          //         display: block;
          //         width: 100%;
          //         @include calc (height, '100% - 2.5px');
          //         text-align: center;
          //         position: relative;
          //         span {
          //             position: absolute;
          //             width: 100%;
          //             top: 50%;
          //             left: 50%;
          //             @include translate(-50%, -50%);
          //             img {
          //                 width: 16px;
          //                 display: inline-block;
          //                 margin-right: 5px;
          //             }
          //         }
          //         &:hover {
          //             @include background-opacity ($black, 0.85);
          //         }
          //     }
          // }
        }

        .product-content {
          padding: 8px 6px;

          h6 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            float: left;
            @include calc(width, "100% - 24px");
          }

          .quality-img {
            margin: -3px 0 0;
            width: 24px;
            float: left;

            img {
              width: 16px;

              &:last-child {
                margin-left: -12px;
              }
            }
          }

          .category {
            color: $gray;
          }

          .price {
            font-size: 11px;
            color: $black;
          }
        }
      }
    }

    .centered-text {
      margin: auto;
    }

    .scroll-loader {
      margin-bottom: 20px;
    }

    .page-loader:not(.scroll-loader) {
      height: 100%;
    }
  }

  h5 {
    margin: 10px 0 8px;
  }

  .more {
    margin: 10px 0 0;
    font-weight: 600;

    .custom-button {
      background-color: transparent;
      border: none;
      opacity: 0.2;

      &:hover {
        opacity: 1;
      }

      img {
        height: 18px;
        margin: -3px 0 0;
      }
    }

    .show-all {
      padding: 0 25px;

      @media (max-width: $breakpoint-xs) {
        padding: 0 10px;
      }
    }
  }

  .thumb-slider {
    margin: 0 -4px;
    position: relative;
    overflow: hidden;

    .slick-list {
      .slick-track {
        margin: 0 auto;
        left: 8px;

        .slick-slide {
          position: relative;

          &.item {
            margin: 0 8px 10px;
            box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);

            &:hover {
              box-shadow: 0 0 10px 0 rgba(21, 21, 21, 0.1);
            }

            // &:hover .overlay-hover {
            //   opacity: 1 !important;
            // }

            &.add-to-project-list {
              border: 2px solid $red;
            }

            .add-to-project-icon {
              position: absolute;
              top: 0;
              right: 0;
              width: auto;
              height: 15px;
              font-size: 10px;
              color: $white;
              padding: 2px 5px;
              background-color: $red;
              z-index: 9;
              text-align: center;
              line-height: 12px;
              display: none;

              &.active {
                display: block;
              }

              img {
                width: 10px;
                display: inline-block;
                margin: -4px 0 0;
              }
            }

            .image-wrap {
              position: relative;
              min-height: 95px;

              .image {
                min-height: 95px;
              }

              // .overlay-hover {
              //   position: absolute;
              //   left: 5px;
              //   top: 5px;
              //   right: 5px;
              //   bottom: 5px;
              //   opacity: 0;
              //   z-index: 10;
              //   background-color: transparent;
              //   @include transition(all 0.5s ease);

              //   .add-project-button {
              //     color: $white;
              //     @include background-opacity($red, 0.6);
              //     display: block;
              //     width: 100%;
              //     @include calc(height, "50% - 2.5px");
              //     text-align: center;
              //     margin: 0 0 5px;
              //     position: relative;

              //     &.active {
              //       .center {
              //         .icon {
              //           background-image: url(#{$assetPath}/icon/pin-fill-white.svg);
              //         }
              //       }
              //     }

              //     .center {
              //       position: absolute;
              //       width: 100%;
              //       top: 50%;
              //       left: 50%;
              //       @include translate(-50%, -50%);

              //       .icon {
              //         background-image: url(#{$assetPath}/icon/pin-white.svg);
              //         background-repeat: no-repeat;
              //         width: 16px;
              //         height: 16px;
              //         display: inline-block;
              //         margin-right: 5px;
              //         vertical-align: middle;
              //       }
              //     }

              //     &:hover {
              //       @include background-opacity($red, 0.85);
              //     }
              //   }

              //   .view-detail-button {
              //     color: $white;
              //     @include background-opacity($black, 0.6);
              //     display: block;
              //     width: 100%;
              //     @include calc(height, "50% - 2.5px");
              //     text-align: center;
              //     position: relative;

              //     span {
              //       position: absolute;
              //       width: 100%;
              //       top: 50%;
              //       left: 50%;
              //       @include translate(-50%, -50%);

              //       img {
              //         width: 16px;
              //         display: inline-block;
              //         margin-right: 5px;
              //       }
              //     }

              //     &:hover {
              //       @include background-opacity($black, 0.85);
              //     }
              //   }
              // }
            }

            .card-block {
              padding: 10px 5px;

              .card-title {
                color: $black;
                margin: 0 0 7px;
              }

              .quality-img {
                float: right;

                img {
                  width: 18px;
                  display: inline-block;

                  &:last-child {
                    margin-left: -12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .project-card {
    column-gap: 12px;

    a {
      .card {
        margin: 0 0 30px !important;

        .card-block {
          padding: 8px 5px 10px;

          h4 {
            font-size: 18px;
            margin: 0 0 5px;
          }

          .card-text {
            font-size: 14px;

            span {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  .bill-wrap {
    position: fixed;
    width: 30%;
    right: 0;
    bottom: 0;
    background-color: $white;
    text-align: center;
    box-shadow: 0 -3px 3px rgba(33, 33, 33, 0.15);

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    a {
      font-weight: 600;
      display: inline-block;
      padding: 16px 10px;
      color: $blue;

      &:hover {
        color: $darkblue;
      }
    }
  }

  .reports-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: scroll;

    .accordion-head img.down-icon {
      align-self: flex-start;
      margin: 7px 12px 7px 0;
    }

    .accordion-name {
      img {
        width: 22px;
        height: 22px;
      }
    }

    .accordion {
      min-height: unset;
    }

    .bimmatch-standard-report {
      background: transparent;
    }

    .empty-report-wrapper {
      h2 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $black;
      }

      &.standard-report {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 30px 20px;

        .page-loader {
          margin: 0;
        }
      }
    }
  }

  .project-tab {
    overflow: auto;

    .header-section {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      .project-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;
        }

        h3 {
          color: $alt-black;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
        }

        img {
          cursor: pointer;
        }

        .popover-content {
          padding: 6px 8px;
          right: 5px;
          left: unset;
        }

        .popover-arrow {
          top: calc(50% - 56px);
        }
      }

      .created-at-section,
      .location-section {
        display: flex;
        align-items: center;
        gap: 5px;

        img {
          width: 16px;
          height: 16px;
        }
      }
    }

    .match-engine-section {
      margin-bottom: 20px;

      .product-match-engine {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .sustainibility-pillars-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
      }

      .section-title {
        color: $mid-gray;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }

      .error-text {
        display: none;
      }

      .save-settings {
        padding-top: 10px;
        border-top: 1px solid $darkgray;

        .bimmatch-button {
          margin-left: auto;
          width: fit-content;
        }
      }
    }

    .teams-section {
      margin-bottom: 20px;

      .teams-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .bimmatch-button {
          height: 40px !important;
        }
      }

      .info,
      .invite-member {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .invite-member {
        // .error-text{
        //   display: none;
        // }

        .bimmatch-button {
          align-self: flex-start;
        }
      }
    }

    .org-lib-section {
      margin-bottom: 30px;

      .org-lib-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .project-info-wrapper {
          display: flex;
          gap: 10px;
          align-items: center;

          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }

          .bimmatch-button {
            padding: 7px 10px;
            height: unset;
          }

          .bimmatch-button-name {
            display: flex;
            gap: 10px;
            justify-content: center;

            svg {
              * {
                fill: $red;
              }
            }
          }
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .categorised-search .data-container .data-main {
      max-height: 300px;
    }
  }

  .apply-material-wrapper {
    .info-section {
      display: flex;
      gap: 10px;
      color: $alt-black;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .match-bar {
    gap: 4px;
  }

  //Tab - Industry Data
  .industry-data {
    height: 100%;

    .industry-data-container {
      height: calc(100% - 70px);
    }

    .industry-data-footer {
      hr {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .bimmatch-button {
        float: right;
        max-width: 40%;
        padding: 10px 15px;
      }
    }
  }
}

.project-detail~.bimmatch-floating-panel .panel-content {
  .right-panel {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .property {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .key-field {
      color: $mid-gray;
    }

    .value-field {
      // color:#151515;
      margin-left: 8px;
      word-break: break-word;
    }

    .title {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
    }

    .content-wrap-pdp,
    .product-info,
    .product-properties-container,
    .other-products {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .product-info {
      .carousel-wrapper .mySlide img {
        max-height: 240px;
      }
    }

    .history-title-wrapper {
      display: flex;
      gap: 5px;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }
    }

    hr {
      background-color: $lightgray;
      width: 100%;
    }

    .history-details {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;

      .date,
      .user-details {
        width: 30%;
      }

      .product-details {
        width: 40%;
      }

      .product-name {
        font-weight: 600;

        a {
          text-decoration: underline;
        }
      }

      .user-name,
      .date {
        font-weight: 400;
      }

      .single-history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
      }

      .product-details,
      .user-details {
        display: flex;
        align-items: center;

        img {
          height: 24px;
          width: 24px;
          object-fit: cover;
          margin-right: 10px;
        }
      }
    }
  }
}