// .loader {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     font-size: 10px;
//     grid-gap: 2px;
//     gap: 2px;
//     line-height: 2px;

//     span {
//         display: inline-block;
//         animation: blink 1.4s infinite both;
//     }

//     span:nth-child(1) {
//         animation-delay: 0s;
//     }

//     span:nth-child(2) {
//         animation-delay: 0.2s;
//     }

//     span:nth-child(3) {
//         animation-delay: 0.4s;
//     }

// }

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px; // Space between dots

    .dot {
        width: 2px;
        height: 2px;
        background-color: $alt-black;
        border-radius: 50%;
        animation: blink 1.4s infinite both;

        &:nth-child(1) {
            animation-delay: 0s;
        }

        &:nth-child(2) {
            animation-delay: 0.2s;
        }

        &:nth-child(3) {
            animation-delay: 0.4s;
        }
    }
}

@keyframes blink {

    0%,
    20% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}

@keyframes blink {

    0% {
        opacity: 0.2;
    }

    20% {
        opacity: 0.2;
    }

    50% {
        opacity: 1;
    }
}