.image-card {
    padding: 5px;

    .image-card-image {
        display: block;
        margin: 0 auto;
    }

    .image-name {
        color: $black;
        font-weight: 600;
        line-height: 32px;
        margin: 0 !important;
    }

    .title {
        color: $mid-gray;
        margin-right: 5px;
    }

    .description,
    .page {
        font-weight: 600;
    }

    .page {
        text-decoration: underline;
        cursor: pointer;
    }

    .image-description {
        font-size: 14px;
        display: flex;
        margin-bottom: 10px;
        color: $black;

        .title {
            width: 100px;
        }
    }
}


//for Zoom
.image-card-zoom {



    .image-card-main {
        position: relative;

        .background-zoom {

            position: absolute;
            width: 100%;
            height: 100%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgba(0, 0, 0, 0.5);
            cursor: zoom-in;
            opacity: 0;

            transition: opacity 0.5s;

            //aligning zoom icon in center
            align-items: center;
            justify-content: center;
            display: flex;

            .image-zoom {
                width: 28px;
                height: 28px;
                filter: brightness(0) invert(1);
            }
        }

        &:hover {
            .background-zoom {

                opacity: 1;
            }
        }

    }
}