.bimmatch-anchor-tag {
    color: $black;
    font-weight: 600;
    text-decoration: underline !important;
    cursor: pointer;
}

.bimmatch-anchor-disabled {
    pointer-events: none;
    opacity: 0.5;
}