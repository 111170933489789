.create-product {
  .create-product-container {
    height: calc(100vh - 82px);
    overflow: scroll;
  }

  .create-product-main {
    max-width: 900px;
    margin: 0 auto;
    padding-top: 15px;

    hr {
      background: #cccccc;
      height: 1px;
    }

    .brand-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      margin-right: 20px;
    }

    .brand-data-main {
      display: flex;
      align-items: center;
    }

    .change-brand {
      display: flex;

      .label-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        text-decoration: underline;
        color: $black;
        cursor: pointer;
      }

      .label-container {
        margin-bottom: 0;
      }

      .bimmatch-popover {
        margin-left: 5px;

        .info-brand {
          color: white;
          width: 230px;
          text-align: left;
        }

        .popover-content.popover-content-right {
          left: calc(100% + 10px);
        }
      }
    }

    .accordion {
      margin-bottom: 15px;

      .repeatComponent-horizontal {
        padding: 0;
      }
    }

    .brand-and-visibility {
      // height: 50px;
      // justify-content: space-between;
      padding: 5px 0;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .visibility-data,
      .brand-data {
        display: flex;
        align-items: center;
      }

      .org-data {
        display: flex;
        align-items: center;
        margin-right: 10px;

        .org-logo {
          width: 60px;
          margin-right: 10px;
        }
      }

      .visibility-data {
        margin-left: auto;

        .visibility-required {
          color: $alt-black;
          font-weight: 800;
          font-size: 14px;
          margin-right: 5px;
        }

        .error-text {
          display: none;
        }
      }

      .org-name,
      .brand-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $black;
        max-width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .brand-name {
        padding-left: 10px;
        border-left: 2px solid #cccccc;
        line-height: 20px;
      }

      .options-data {
        margin-left: 20px;

        .popover-content {
          transform: unset;
          left: unset;
          right: 6px;
        }

        .product-popover-content {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .product-content-item {
            display: flex;
            padding: 5px;
            align-items: center;
            cursor: pointer;

            img {
              height: 20px;
              width: 20px;
              margin-right: 7px;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: $black;
              white-space: nowrap;
            }

            &:hover {
              background-color: $lightgrayshade;
              border-radius: 4px;
            }
          }

          hr {
            margin: 0;
          }
        }
      }

      .visibility-heading {
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $alt-black;
      }

      .bimmatch-select {
        width: 220px;
        padding: unset;
        margin-left: 20px;
      }
    }

    .general-properties {

      .category-and-releveantcategory,
      .subcategory-and-discipline,
      .project-and-searchTags {
        display: flex;
        justify-content: space-between;
      }

      .bimmatch-select,
      .input-text {
        width: 48%;
      }
    }

    .options-data {
      margin-left: 20px;

      .popover-content {
        transform: unset;
      }

      .hamhamburger-icon-img {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      .product-popover-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .product-content-item {
          display: flex;
          padding: 5px;
          align-items: center;
          cursor: pointer;

          img {
            height: 20px;
            width: 20px;
            margin-right: 7px;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $black;
            white-space: nowrap;
          }

          &:hover {
            background-color: $lightgrayshade;
            border-radius: 4px;
          }
        }

        hr {
          margin: 0;
        }
      }
    }
  }

  .create-product-header {
    background: rgba(239, 239, 239, 0.5);
    margin-bottom: 10px;
    padding: 12px 0px;

    .header-container {
      max-width: 900px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
    }

    .create-product-heading {
      font-family: $opensans;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $black;
    }

    .last-updated-data,
    .right-header {
      display: flex;
      align-items: center;
      // justify-content: space-between;
    }

    .last-updated-data {

      .updated-date,
      .updated-email {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $alt-black;
      }

      .updated-email {
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid $alt-black;
      }

      .updated-email-noborder {
        margin-left: unset;
        border-left: unset;
        padding-left: unset;
      }
    }

    .right-header {
      .bimmatch-button-secondary {
        margin-left: 10px;
      }
    }
  }

  // .accordion-body {
  //   display: grid;
  //   grid-template-columns: auto auto;
  // }

  .create-product-form {

    // --- Custom CSS for Sections ----- //
    #dimensions.accordion-open {
      .accordion-body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .input-number {
          margin-right: 20px;
        }
      }
    }

    #valves.accordion-open,
    #rfa-parameters.accordion-open {
      .accordion-body {
        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-column-gap: 30px;
      }
    }

    #identity-data.accordion-open,
    #features.accordion-open,
    #demo-clips.accordion-open,
    #pricing-warranty.accordion-open,
    #environmental-codes.accordion-open {
      .accordion-body {
        display: grid;
        grid-template-columns: 47% 47%;
        column-gap: 30px;

        .checkbox {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    #environmental-codes.accordion-open {
      .accordion-body {
        display: flex;
        flex-wrap: wrap;

        .inputMultipleFiles-shadow {
          width: 100%;
        }
      }
    }

    #sales-distributor.accordion-open {
      .accordion-body {
        min-height: 120px;

        .org-contacts {
          margin-bottom: 21px;

          .org-contacts-container {
            background: $lightgrayshade;
            padding: 5px 5px;
            border-radius: 5px;

            .contact-details:not(.contact-details:first-child) {
              margin-top: 10px;
            }

            .contact-detail:not(.contact-detail:first-child) {
              margin-left: 10px;
            }

            .contact-detail:first-child {
              font-weight: 600;
            }

            .contact-details {
              display: flex;
            }

            .contact-detail {
              max-width: 22%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .categorised-search .select-open-main {
          max-height: 250px;
        }
      }
    }

    #images.accordion-open {
      .inputMultipleFiles {
        margin-top: 0;
      }
    }

    #files.accordion-open {
      .accordion-body {
        display: grid;
        grid-template-columns: 20% 20% 20% 20%;
        column-gap: 60px;

        .input-file {
          width: fit-content;
        }
      }
    }

    #ghg-emission.accordion-open {
      .accordion-body {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .input-number {
        width: 220px;
        margin-right: 40px;

        .input-number-container .unit {
          width: 150px;
        }
      }
    }

    // xxx End of Custom CSS for Sections xxxxxx //

    .product-tab-hidden {
      display: none;
    }

    .create-product-types {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      .product-types-container {
        display: flex;
        max-width: 840px;

        .bimmatch-button-name {
          max-width: 130px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .bimmatch-button {
          min-width: unset;
        }

        .add-type-btn {
          margin-left: 20px;
        }

        .custom-product-types {
          display: flex;
          overflow-x: scroll;
          // max-width: calc(100% - 262px);
          align-items: center;
          margin-left: 20px;
          padding-bottom: 1px;

          .bimmatch-button:not(:first-child) {
            margin-left: 20px;
          }
        }

        .custom-product-types::-webkit-scrollbar {
          -webkit-appearance: none;
          height: 4px;
          width: 4px;
        }

        .custom-product-types::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
      }

      .options-data {
        margin-left: auto;

        .popover-content-bottom {
          left: unset;
          right: 6px;
        }
      }

      .bimmatch-popover-black {
        margin-left: 15px;

        .popover-content.popover-content-top {
          margin-left: -135px;
        }

        .info-types {
          width: 240px;
          text-align: left;
        }
      }

      .product-name-popover {

        .popover-content.popover-content-top {
          margin-left: -61px;
          text-transform: capitalize;
        }
      }

      .product-name-popover:first-child {
        margin-left: unset;
      }

    }
  }

  .distributor .modal-container {
    width: 50%;
    min-width: 910px;
    height: auto;
    min-height: 560px;

    .bimmatch-modal-head,
    .bimmatch-modal-footer {
      height: 15%;
    }

    .bimmatch-modal-body {
      height: auto;
      // padding: 15px;
      overflow-y: auto;


      .pop-up-container {
        width: 100%;
        display: flex;

        .create-distributor-maps {
          width: 40%;
          padding: 0px 10px;
        }

        .categories-create-distributor {
          width: 60%;

          .categories-inside-popup {
            padding: 0px 15px;
          }

          .create-distributor-repeat {
            max-height: 300px;

            .repeatComponent {
              max-height: 300px;
            }
          }
        }
      }
    }
  }

  .bimmatch-modal-body {

    .info {
      display: flex;
      margin-bottom: 20px;

      img {
        margin-right: 10px;
        height: 18px;
        width: 18px;
        margin-top: 2px;
      }
    }



    .select-brand {
      .selectBox {
        height: 410px;
      }
    }

    .parameter-settings {
      .selectBox {
        padding: unset;

        .bimmatch-select .categorised-dropdown-open .data-container {
          height: 225px;
          margin-top: 2px;
        }
      }
    }

    .modal-body-wrapper {
      margin: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      img {
        width: 68px;
        height: 68px;
        margin: 50px auto;
      }
    }
  }

  // Property settings css
  .property-settings {
    padding: 0 20px;

    .popover-content.popover-content-right {
      width: 320px;
      top: 200%;

      .popover-arrow {
        top: 12%;
      }
    }

    .rename-and-lod {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .input-text,
      .bimmatch-select {
        width: 48%;
      }
    }

    .settings-options {
      padding: 5px 10px;
      background: #efefef;
      border-radius: 4px;

      .settings-options-standard {

        .popover-content-top {
          width: 375px;
          margin-left: -185px;
        }

      }

      .settings-options-rename {
        .popover-content-top {
          margin-left: -92px;

          .popover-arrow {
            left: 31%;
          }
        }
      }

      .settings-options-instruction {
        .popover-content-top {
          margin-left: -95px;

          .popover-arrow {
            left: 32%;
          }
        }
      }
    }

    .add-properties-modal {
      .repeatComponent-vertical {
        height: 277px;
        padding: 0;

        .repeatComponent-children-container {
          .repeatComponent-children {
            padding: 2px 0 0 0;
          }

          .repeatComponent-children:not(:first-child) {
            margin-top: 15px;
          }

          .inputWithToggle {
            padding: 0;

            .inputWithToggle-container {
              width: 100%;
              min-width: unset;
              padding: 0 0 0 4px;
            }

            .inputWithToggle-input {
              width: 100%;
            }
          }
        }
      }
    }

    .mainCategory-and-property {
      font-family: $opensans;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $black;
      margin-bottom: 20px;

      .property-name {
        border-left: 2px solid #cccccc;
        margin-left: 10px;
        padding-left: 10px;
      }
    }

    .properties-visibility {
      height: 300px;
      overflow-y: scroll;
      margin-top: 5px;

      .single-property-visibility {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        margin-right: 1px;

        .toggle-container {
          width: -webkit-fill-available;
        }

        .property-name {
          margin-right: 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          color: $black;
          width: 100%;
        }

        .bimmatch-popover {
          margin-left: 10px;

          .info-visibility {
            width: 120px;
          }

          .popover-content.popover-content-bottom {
            right: 0%;
            margin-left: unset;
            left: unset;

            .popover-arrow {
              left: unset;
              right: 2%;
            }
          }
        }

        .toggle-with-info {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }

    .property-search {
      margin-bottom: 5px;
    }

  }

  .property-option-list {
    height: 310px;

    .property-option-single {
      display: flex;
      margin-bottom: 14px;
    }

    .bimmatch-popover {
      margin-top: 10px;

      .popover-content {
        padding: 6px 10px;
      }

      .popover-content.popover-content-left {
        top: 0
      }
    }
  }
}