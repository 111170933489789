.bimmatch-priority-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black;

    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        overflow: auto;
        gap: 8px; 
    }

    .importance-tag-wrapper{
        display: flex;
        gap: 10px;
        align-items: center;
        margin-top: 4px;
    }

    .description{
        font-family: "Open Sans";
        line-height: 1.5;
        margin-bottom: 0;
    }
}