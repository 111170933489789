.repeatComponent {

  .repeatComponent-children-container::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
    width: 5px;
  }

  .repeatComponent-children-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  .add-button-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.repeatComponent-vertical {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  padding-top: 20px;

  .repeatComponent-container {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 40px);
  }

  .repeatComponent-children-container {
    display: flex;
    max-width: 100%;
    flex-direction: column;
    max-height: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;

    .repeatComponent-children {
      margin-top: 10px;
      padding: 0 8px;
    }

    .repeatComponent-children:first-child {
      margin-top: unset;
    }
  }

  .add-button {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 5px;
    margin-top: 10px;
    margin-left: 5px;
    cursor: pointer;
    font-weight: 600;

    .add-more-icon {
      margin-right: 6px;
      height: 15px;
      width: 15px;
    }

    .add-more-text {
      color: $black;
      font-size: 15px;
    }
  }

  .add-button:hover {
    background-color: #cccccc;
    border-radius: 5px;
  }
}

.repeatComponent-horizontal {
  display: flex;
  padding: 10px;
  align-items: center;
  width: 100%;

  .add-button {
    border: 1px solid $err-red;
    border-radius: 2px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin-left: 20px;
  }

  .repeatComponent-container {
    display: flex;
    max-width: calc(100% - 60px);
  }

  .repeatComponent-children-container {
    display: flex;
    max-width: 100%;
    overflow-x: scroll;
    overscroll-behavior: contain;

    .repeatComponent-children {
      margin-left: 20px;
      padding-right: 5px;
      height: fit-content;
    }

    .repeatComponent-children:first-child {
      margin-left: unset;
    }
  }
}

.repeatComponent-max {
  .add-button {
    display: none;
  }
}

.repeatComponent-vertical.repeatComponent-max {
  .repeatComponent-container {
    max-height: 100%
  }
}

.repeatComponent-horizontal.repeatComponent-fixed {
  .repeatComponent-container {
    max-width: 100%;
  }
}