.specTrees {
    height: 100%;

    .specTrees-container {
        height: calc(100% - 115px);
        overflow-y: scroll;

        .tree-container {
            margin-bottom: 10px;
            background: $lightgrayshade;
            padding: 5px;
            border-radius: 5px;
        }

        .tree-container>.branch-container>.branch-childrens {
            overflow-y: scroll;
            max-height: calc(100vh - 270px);
        }
    }
}

.specTrees-with-masterSearch {
    height: 100%;
    flex: 1;

    .bimmatch-product-filter-container {
        margin-bottom: 15px;

        .filter-box {
            max-height: unset;
            overflow: unset;
        }
    }
}