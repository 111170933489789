.bimmatch-plugin {

    .plugin-container {
        padding: 15px 20px 0px 20px;

        .plugin-header {
            margin-bottom: 15px;
            display: flex;
            justify-content: space-between;

            .bimmatch-popover.units-popover {

                // to remove units css when added fn of selected to popover content comp
                .units-list-container {
                    width: 136px;

                    .single-unit-container {
                        padding: 5px;
                        width: 100%;
                        display: flex;
                        cursor: pointer;
                        border-radius: 4px;

                        .unit-icon {
                            margin-right: 12px;
                            height: 18px;
                            width: 18px;
                        }
                    }

                    .single-unit-container:not(:first-child) {
                        margin-top: 5px;
                    }

                    .single-unit-container:hover {
                        background-color: $lightgrayshade;

                    }

                    .selected-unit,
                    .selected-unit:hover {
                        background: $black;
                        color: $white;
                        cursor: unset;
                    }

                }

                .popover-content.popover-content-bottom {
                    margin-left: -86px;
                }

            }

            .bimmatch-popover.avatar-popover {
                .popover-content {
                    &:has(.bimmatch-menu-popover-content) {
                        padding: 4px 6px;
                    }
                }

                .popover-content.popover-content-bottom {
                    left: 0;
                }
            }

            .bimmatch-avatar,
            .unit-container,
            .close-icon {
                cursor: pointer;
            }

            .unit-container {
                &:first-child {
                    .unit-name {
                        display: none;
                    }
                }
            }

            .units-popover,
            .avatar-popover {
                margin-right: 24px;
            }

            .close-icon {
                height: 18px;
                width: 18px;
            }

            .plugin-header-right {
                display: flex;
                align-items: center;
            }

        }

        .plugin-project {
            display: flex;
            margin-bottom: 15px;
            align-items: center;

            .project-name {
                max-width: 150px;
                margin-right: 10px;
            }

            .label-container {
                width: unset;
                margin-bottom: unset;
                margin-right: 5px;
            }

            .bimmatch-anchor-tag {
                margin-right: 5px;
            }

            .popover-content {
                text-align: left;
                margin-left: -181px;
            }

            .link-project-info {
                width: 330px;



                .info-heading {
                    margin-bottom: 10px;
                }

                .info-list {
                    list-style: disc;
                    list-style-position: inside;

                    li::marker {
                        margin-right: 10px;
                    }
                }
            }
        }

        .plugin-content {
            height: calc(100vh - 102.25px);

            .bimmatch-tabs.menu-list {
                margin: 0 0 10px;
            }

            .master-product-filter {
                padding-top: 5px;
                background-color: $lightgrayshade;
                padding-bottom: 5px;
            }

            .product-list {
                flex: 1;
                margin-top: 15px;
                padding: unset;

                // to remove (temp)
                .product-row {
                    gap: 10px;
                    justify-content: center;

                    .product-card-item {
                        min-width: 220px;
                        max-width: 250px;
                    }

                    @media screen and (max-width: $breakpoint-xxs) {
                        .product-card-item {
                            max-width: unset;
                            width: 90%;
                        }
                    }
                }
            }

            // --- changes to minimise height for small screen
            .tab-item {
                font-size: 13px;
            }

            .match-bar {
                min-height: 35px;

                .match-header {
                    font-size: 25px;
                    line-height: 35px;
                }

                .chip-icon {
                    height: 14px;
                    width: 14px;
                }

                .chip-name {
                    font-size: 13px;
                }
            }

            //-----
        }
    }

    .select-project {
        height: 300px;
    }

    .bimmatch-floating-panel,
    .bimmatch-floating-panel.filter-floating-panel,
    .bimmatch-floating-panel.specs-detail-floating-panel,
    .bimmatch-floating-panel.product-detail-floating-panel{
        width: 100%;
        min-width: auto;
        max-width: $floating-panel-width-medium;
    }
}