.bimmatch-product-filter-container {
  width: 100%;
  position: relative;

  .categorised-search {
    width: 100% !important;
  }

  .match-bar {
    display: flex;
    align-items: center;
    gap: 18px;
    border-bottom: 1px solid black;
    min-height: 50px;
    cursor: pointer;

    .default-fixed-chip {
      .chip {
        border: none;
        cursor: default;
        background-color: #cccccc;
        border-radius: 24px;

        .chip-icon {
          border-radius: unset;
        }

        .chip-name {
          color: $black;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .match-header {
      font-style: italic;
      font-size: 32px;
      line-height: 44px;
      color: #696969;
    }

    .selected-chips-container {
      .chip {
        height: 24px;
        border-radius: 24px;
      }

      display: flex;
      gap: 5px;
      // flex-wrap: wrap;
      overflow-x: auto;
      padding: 0.5rem 0;
    }

    .clear-all {
      margin-left: auto;
      color: #363636;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
      min-width: fit-content;
    }
  }

  .filter-box {
    position: absolute;
    z-index: $product-filter-zindex;
    left: 0;
    right: 0;

    background-color: #ffffff;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    padding: 22px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 15px;
    overscroll-behavior: contain;
    max-height: 60vh;
    overflow: auto;

    .mulifield-filter-item {
      display: flex;
      gap: 30px;

      .section-container {
        flex: 1;
      }

      &.items-group {
        background-color: $lightgrayshade;
        padding: 10px;
      }
    }

    .chips-container {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .section-container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .section-title {
        font-size: 14px;
        line-height: 18px;
        color: $alt-black;
        margin: 0;
      }
    }

    &.hide {
      display: none;
    }

    .error-text {
      display: none;
    }

    .data-container {
      max-height: 335px;
      overflow: auto;

      .data-main {
        overscroll-behavior: unset;
        max-height: unset;
        overflow-y: unset;
      }
    }

    .label-text {
      color: $alt-black;
    }

    //has selector is not working with saas in prod so done with js
    // Increase the height of the dropdown in matchbar if parameter dropdown is open
    // &:has(.extended-drop-down .categorised-search.categorised-dropdown-open) {
    //   height: 80vh;
    //   max-height: 80vh;
    // }

    .extended-drop-down .categorised-search.categorised-dropdown-open {
      .data-container {
        max-height: 400px;
      }
    }
  }
}