/* Scss Document */

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(8, 62, 100, 0.7);
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(8, 62, 100, 0.7);
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(8, 62, 100, 0.7);
}

:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(8, 62, 100, 0.7);
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: $opensans;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $black;
  background-color: $white;
  overflow-y: scroll;

  &.custom-hide-menu {
    padding-top: 0;

    .project-cube-wrap,
    .hide-menu,
    .project-detail.project-create .content-right {
      top: 0;
    }

    .project-detail {
      padding: 0;
    }
  }
}

.wrapper {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

a,
button {
  color: $main-black;
  cursor: pointer;
}

a {
  &:hover {
    color: $main-black;
    text-decoration: none;
  }
}

.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.equal>[class*="col-"] {
  display: flex;
  flex-direction: column;
}

.text-center-verticle {
  display: flex;
  align-items: center;
  align-content: center;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.container {
  @media (min-width: 1340px) {
    max-width: 1310px;
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 100%;
    padding: 0 10px;
  }
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.adsk-viewing-viewer .adsk-toolbar {
  bottom: 80px !important;
}

.progress-bar {
  background-color: #f3516b;
}

.address-input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #696969;
  text-indent: 10px;
}

.address-input::placeholder {
  @include common-input-text-style($lightgray);
  opacity: 1;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bimmatch-popover .popover-content:has(.bimmatch-menu-popover-content) {
  padding: 8px 10px;
}

// to remove when component implemented at all places
.bimmatch-menu-popover-content {
  display: flex;
  flex-direction: column;
  gap: 5px;

  hr {
    margin: 0;
    width: 100%;
    color: $darkgray;
  }

  .bimmatch-menu-item {
    display: flex;
    padding: 5px;
    align-items: center;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
      margin-right: 7px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: $black;
      white-space: nowrap;
    }

    .bimmatch-button-loading::after {
      border-top-color: $black;
    }

    &:hover {
      background-color: $lightgrayshade;
      border-radius: 4px;
    }

    &.loader-wrapper,
    &:has(.bimmatch-button-loading) {
      position: relative;
    }
  }
}

.bimmatch-row-container {
  background: $lightgrayshade;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.bimmatch-three-dot {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

* {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

*::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}


//--- small loading-spinner css
@keyframes circle-loader-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.circle-loader {
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-top-color: $verydarkgrey;
  border-radius: 50%;
  animation: circle-loader-spinner 1s ease infinite;
}

//--- spinner loader css ends ----

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: rgba(0, 0, 0, 0.5);
//   box-shadow: 0 0 1px rgba(255, 255, 255, 0.5)
// }

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}