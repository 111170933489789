.bimmatch-tabs.menu-list {
  margin: 0 0 20px;
  border-bottom: 1px solid $offwhite;

  &.link {
    li {
      cursor: pointer;
    }
  }

  .tab-item {
    font-weight: 400;
    display: inline-block;
    position: relative;
    padding: 0 10px 5px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.selected {
      border-bottom: 3px solid $red;
    }

    @include media-breakpoint-down(lg) {
      margin-right: 16px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // @include media-breakpoint-down(xs) {
    //   display: block;
    //   margin: 0 0 10px;
    // }
  }

  .title-with-images {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    color: black;
    font-weight: 600;

    .tab-icon {
      width: 18px;
      height: 18px;
    }

    .badge-counter {
      width: 100%;
      height: 100%;
      font-size: 12px;
      padding: 0 5px;

      .count {
        position: unset;
      }
    }
  }
}