.chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 8px 4px 4px;
  background: #ffffff;
  border: 1px solid #cccccc;
  width: fit-content;
  max-width: 128px;
  border-radius: 24px;
  height: 24px;

  .chip-name {
    font-size: 12px;
    color: $alt-black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .chip-cancel {
    height: 14px;
    cursor: pointer;
    margin-left: 10px;
  }

  .chip-icon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
    border-radius: 50%;
    object-fit: cover;
  }

  .chip-info {
    margin-left: 4px;
  }
}

.chip-clickable {
  cursor: pointer;
}

.chip-unclickable {
  cursor: default;
}

.chip-normal {
  border-radius: 2px;
  height: 26px;

  .chip-cancel {
    display: none;
  }

  .chip-name {
    font-size: 12px;
  }
}

.chip-dark {
  background: #151515;
  border: 1px solid #151515;

  .chip-name {
    color: #ffffff;
  }
}

.chip:hover {
  border: 1px solid #151515;
}