.bimmatch-sustainability-icons-wrapper {
    display: flex;
    gap: 6px;
  
    img {
      width: 18px;
      height: 18px;
    }
  
    img.blank-pillar-icon {
      width: 22px;
      height: 22px;
    }
  
    .bimmatch-tooltip {
      display: flex;
      gap: 10px;
    }

    .circular-sustainibility-piller-wrapper {
        display: flex;
        gap: 5px;
        white-space: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}