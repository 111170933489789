// Product Card Styles Starts Here

.product-card {
  // margin: 0 -15px;

  .card {
    @include calc(width, "33.333% - 30px");
    float: left;
    border-radius: 0;
    border: none;
    box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);
    margin: 15px 15px 15px 15px;
    position: relative;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    height: unset; // Added due to conflict with Autodesk Forge Viewer css

    @media (max-width: $breakpoint-lg) {
      @include calc(width, "50% - 30px");
    }

    @media (max-width: $breakpoint-md) {
      @include calc(width, "50% - 30px");
    }

    @media (max-width: $breakpoint-xs) {
      margin: 0 0px 18px;
      @include calc(width, "50% - 30px");
    }

    @media (max-width: 440px) {
      width: 100%;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(21, 21, 21, 0.1);
    }

    // &:hover .overlay-hover {
    //   opacity: 1 !important;
    // }

    &.add-to-project-list {
      border: 2px solid $red;
    }

    .add-to-project-icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 28px;
      height: 28px;
      background-color: $red;
      z-index: 9;
      text-align: center;
      line-height: 24px;
      display: none;

      &.active {
        display: block;
      }
    }

    // .image-wrap {
    //   position: relative;

    //   .overlay-hover {
    //     position: absolute;
    //     left: 10px;
    //     top: 10px;
    //     right: 10px;
    //     bottom: 10px;
    //     opacity: 0;
    //     z-index: 10;
    //     @include transition(all 0.5s ease);

    //     // .add-project-button {
    //     //   color: $white;
    //     //   @include background-opacity($red, 0.6);
    //     //   display: block;
    //     //   width: 100%;
    //     //   @include calc(height, "50% - 5px");
    //     //   text-align: center;
    //     //   margin: 0 0 10px;
    //     //   position: relative;

    //     //   &.active {
    //     //     .center {
    //     //       .icon {
    //     //         background-image: url(#{$assetPath}/icon/pin-fill-white.svg);
    //     //       }
    //     //     }
    //     //   }

    //     //   .center {
    //     //     position: absolute;
    //     //     width: 100%;
    //     //     top: 50%;
    //     //     left: 50%;
    //     //     @include translate(-50%, -50%);

    //     //     .icon {
    //     //       background-image: url(#{$assetPath}/icon/pin-white.svg);
    //     //       background-repeat: no-repeat;
    //     //       width: 16px;
    //     //       height: 16px;
    //     //       display: inline-block;
    //     //       margin-right: 5px;
    //     //       vertical-align: middle;
    //     //     }
    //     //   }

    //     //   &:hover {
    //     //     @include background-opacity($red, 0.85);
    //     //   }
    //     // }

    //     // .action-button {
    //     //   color: $white;
    //     //   @include background-opacity($black, 0.6);
    //     //   display: block;
    //     //   width: 100%;
    //     //   @include calc(height, "50% - 5px");
    //     //   text-align: center;
    //     //   position: relative;
    //     //   margin: 0 0 10px;

    //     //   span {
    //     //     position: absolute;
    //     //     width: 100%;
    //     //     top: 50%;
    //     //     left: 50%;
    //     //     @include translate(-50%, -50%);

    //     //     img {
    //     //       width: 16px;
    //     //       display: inline-block;
    //     //       margin-right: 5px;
    //     //     }
    //     //   }

    //     //   &:hover {
    //     //     @include background-opacity($black, 0.85);
    //     //   }
    //     //   // Added by sudhir - start
    //     //   img {
    //     //     height: unset;
    //     //     object-fit: unset; // added by sudhir
    //     //   }
    //     //   // Added by sudhir - end
    //     // }
    //   }
    // }

    img {
      width: 16px;
      cursor: pointer;
      height: 220px;
      object-fit: cover;
    }

    .card-block {
      padding: 15px 10px;
      overflow: hidden;

      .card-title {
        margin: 0 0 10px;
        // float: left;

        // Added by sudhir - start
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // Added by sudhir - end
      }

      .quality-img {
        float: right;

        img {
          width: 18px;

          &:last-child {
            margin-left: -12px;
          }
        }
      }

      .card-text {
        width: 100%;
        float: left;
        display: block;
        color: $lightgray;

        // Added by sudhir - start
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // Added by sudhir - end

        span {
          display: inline-block;
          margin-right: 15px;
          margin-bottom: 12px;
        }
      }

      .card-bottom-text {
        float: left;

        // Added by sudhir - start
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // Added by sudhir - end

        img {
          width: 18px;
        }

        li {
          padding-right: 24px;
          float: left;
          position: relative;
          color: $gray;

          &:first-child {
            font-size: 16px;
          }

          &:first-child:after {
            content: "";
            position: absolute;
            right: 10px;
            top: 2px;
            width: 1px;
            height: 14px;
            background-color: $offwhite;
          }

          &:last-child {
            font-size: 14px;
            color: $lightgray;
          }
        }
      }

      .card-price {
        float: right;
        color: $gray;

        @include media-breakpoint-down(sm) {
          float: left;
          margin: 10px 0 0;
        }

        @media (max-width: 440px) {
          float: right;
          margin: 0;
        }
      }
    }
  }

  .card-skeleton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 328px;
  }
}

.project-card {
  margin: 0 -15px;

  .card {
    @include calc(width, "33.333% - 30px");
    float: left;
    border-radius: 0;
    margin: 0 15px 30px;
    border: none;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);
    height: unset; // Added due to conflict with Autodesk Forge Viewer css

    img.project-icon {
      width: 16px;
      cursor: pointer;
      height: 220px;
      object-fit: cover;

      &.default-icon {
        object-fit: none;
      }
    }

    @media (max-width: $breakpoint-md) {
      @include calc(width, "50% - 30px");
    }

    @media (max-width: $breakpoint-md) {
      @include calc(width, "100% - 30px");
    }

    @media (max-width: $breakpoint-xs) {
      margin: 0 15px 18px;
    }

    &:hover {
      box-shadow: 0 0 10px 0 rgba(21, 21, 21, 0.1);
    }

    &.white-bg {
      .icon-wrap {
        background-color: $gray;

        .cube-icon {
          .cube-svg {
            fill: $white;
          }
        }
      }
    }

    .tag-wrap {
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 5px;
      text-align: center;
      padding: 2px 0 0;
    }

    .icon-wrap {
      width: 25px;
      height: 25px;
      background-color: $white;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
      padding: 2px 0 0;

      .cube-icon {
        width: 15px;

        .cube-svg {
          fill: $gray;
        }
      }
    }

    .cube-wrap {
      display: none;
    }

    .card-block {
      padding: 15px 10px;
      overflow: hidden;

      .card-title {
        font-weight: 600;
        margin: 0 0 15px;
      }

      .card-text {
        font-size: 16px;
        color: $gray;
        margin: 0 0 10px;

        &:last-child {
          margin: 0;
        }

        span {
          color: $lightgray;
          margin-right: 15px;
          margin-bottom: 12px;
        }
      }
    }

    .custom-spinner {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: $white;

      .spinner-grow {
        color: $offwhite;
        left: 50%;
        top: 50%;
        position: absolute;
        margin: -15px 0 0 -15px;
      }
    }
  }
}

// Product Card Styles Ends Here

.card-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  column-gap: 30px;
  position: relative;

  @media (max-width: $breakpoint-md) {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  @media (max-width: $breakpoint-xs) {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }

  &:after {
    content: "";
    position: absolute;
    height: 12px;
    background-color: $white;
    bottom: -5px;
    left: 0;
    right: 0;
  }

  // Project Card Styles Starts Here

  &.project-card {
    .card {
      border-radius: 0;
      margin: 0 0 30px;
      border: none;
      position: relative;
      box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);

      @media (max-width: $breakpoint-xs) {
        margin: 0 0 18px;
      }

      &:hover {
        box-shadow: 0 0 10px 0 rgba(21, 21, 21, 0.1);
      }

      &.white-bg {
        .icon-wrap {
          background-color: $gray;

          .cube-icon {
            .cube-svg {
              fill: $white;
            }
          }
        }
      }

      .icon-wrap {
        width: 25px;
        height: 25px;
        background-color: $white;
        position: absolute;
        top: 0;
        right: 0;
        text-align: center;
        padding: 2px 0 0;

        .cube-icon {
          width: 15px;

          .cube-svg {
            fill: $gray;
          }
        }
      }

      .cube-wrap {
        display: none;
      }

      .card-block {
        padding: 15px 10px;
        overflow: hidden;

        .card-title {
          font-weight: 600;
          margin: 0 0 15px;
        }

        .card-text {
          font-size: 16px;
          color: $gray;
          margin: 0 0 10px;

          &:last-child {
            margin: 0;
          }

          span {
            color: $lightgray;
            margin-right: 15px;
            margin-bottom: 12px;
          }
        }
      }
    }
  }

  // Project Card Styles Ends Here
}
