.project-list {
  position: relative;

  .project-list-header {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
  }

  .project-card-item {
    cursor: pointer;

    .project-details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 10px;

      .project-title,
      .created-by,
      .last-updated,
      .members {
        display: flex;
        gap: 10px;
        align-items: center;
      }

      .bimmatch-avatar {
        width: 24px;
        height: 24px;
      }

      .light-text,
      .dark-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .light-text {
        color: $mid-gray;
      }

      .dark-text {
        color: $black;
      }

      .project-title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
          color: $black;
          font-weight: 600;
          font-size: 16px;
          line-height: 32px;
        }
      }
    }
  }
}

.create-project-modal-container {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $alt-black;

  .create-project-options {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .bmc-option,
  .acc-option {
    background-color: rgba(239, 239, 239, 0.5);
    padding: 20px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    color: $black;
    flex: 1;
  }

  .title {
    display: flex;
    gap: 10px;
    align-items: center;

    .autodesk-icon {
      width: 18px;
      height: 18px;
    }

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: $black;
    }
  }

  .acc-option {
    .bimmatch-button {
      background: $black;
      border-color: $black;
      color: $white;
      padding: 10px 26px;
    }

    .bimmatch-button-name {
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }
}

.create-bmc-project-container,
.create-acc-project-container {
  padding: 20px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.create-bmc-project-container {
  .bmc-row {
    display: flex;
    gap: 40px;
  }

  .bmc-location-date {
    .calendar-container {
      z-index: 5;
    }

    .location-cotainer {
      width: 100%;
    }
  }

  .bmc-img {

    .input-file,
    .input-file-container,
    .input-file-container label {
      width: 100%;
    }

    .input-file-container label .end-name {
      display: none;
    }
  }
}

.create-acc-project-container {
  height: 50vh;
  max-height: 50vh;
  overflow: hidden;

  .info-raw {
    color: $alt-black;
    display: flex;
    gap: 8px;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    a {
      color: $alt-black;
      text-decoration: underline;
      font-weight: 600;
    }
  }

  .bread-crumbs-container {
    display: flex;
    gap: 12px;
    align-items: center;

    font-size: 14px;
    font-weight: 600;
    color: $alt-black;

    .link {
      cursor: pointer;
      text-decoration-line: underline;
    }

    .prev-arrow {
      cursor: pointer;
      width: 18px;
      height: 18px;
    }
  }

  .hubs {
    display: flex;
    overflow: auto;
    width: 100%;
    gap: 5px;

    .item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      text-align: center;

      color: $black;
      background: white;
      border: 1px solid $darkgray;
      border-radius: 2px;
      padding: 8px;

      &.selected {
        color: white;
        background: $black;
        border: 1px solid $black;
      }
    }
  }

  .selection {
    display: flex;
    gap: 20px;
    overflow: hidden;

    .projects-container,
    .models-container {
      flex: 1;
      overflow: auto;
    }

    .projects,
    .models {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        gap: 10px;

        .right-arrow {
          margin-left: auto;
          height: 18px;
          display: none;
        }

        &.selected,
        &:hover {
          background: $lightgrayshade;
          border-radius: 4px;

          .right-arrow {
            display: block;
          }
        }

        input[type="radio"] {
          width: 20px;
          height: 20px;
          accent-color: $black;
        }
      }

      .item label {
        margin: 0;
      }
    }
  }
}

.bim-file-upload-form {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;

  .project-cube-wrap {
    padding: 0 !important;
  }

  .bimmatch-button {
    width: fit-content;

    .bimmatch-button-name {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }

  .bim-file-upload-buttons-wrapper {
    display: flex;
    gap: 30px;
  }

  .content-wrap {
    .drag-button {
      font-family: "Open Sans";
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      color: $alt-black !important;
    }

    .selected-file-container {
      h3 {
        font-family: "Open Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $alt-black;
        margin: 10px;
      }
    }
  }
}