.date-container {
  width: 100%;
  min-width: 275px;
  border: none;
  position: relative;
  user-select: none;


  .date-input {
    width: 100%;
    height: 40px;
    text-indent: 10px;
    border: 1px solid $mid-gray;
    display: flex;
    padding: 3px;

    .placeholder-with-date {
      display: flex;
      align-items: center;
      width: inherit;
    }


    .date-placeholder {
      @include common-input-text-style($lightgray);
      opacity: 1;
    }

    .selected-date {
      margin: auto 5px;
      font-family: $opensans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $black;
      border: none;
      background-color: transparent;
    }

    .icons {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-right: 0px;
      padding: 5px;
      margin-left: auto;


      .close-icon {
        cursor: pointer;
        margin-right: 10px;
        height: 20px;
      }


      .date-icon {
        cursor: pointer;
        margin-right: 10px;
        height: 20px;
      }
    }
  }

  .calendar-container {
    // top: 55px;
    position: absolute;
    left: 0;
    width: inherit;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px #ccc;
    z-index: 1;
    box-sizing: border-box;
    padding: 5px 5px 5px 5px;


    .nav-container {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $opensans;
      background-color: $white;
      text-decoration: underline;
      font-weight: 600;
      font-size: 14px;
      padding: 10px;


      .prev-year-btn {
        background-color: transparent;
        margin-right: 5px;
        padding: 2px;
        border: none;
        color: white;
        cursor: pointer;
      }

      .prev-year-btn:hover {
        outline: 1px solid $black;
      }

      .next-year-btn {
        padding: 2px;
        cursor: pointer;
      }

      .next-year-btn:hover {
        outline: 1px solid $black;
      }

      .next-month-btn {
        background-color: transparent;
        margin-right: 5px;
        border: none;
        padding: 5px;
        color: white;
        cursor: pointer;
      }

      .next-month-btn:hover {
        outline: 1px solid $black;
      }

      .prev-month-btn {
        padding: 5px;
        cursor: pointer;
      }

      .prev-month-btn:hover {
        outline: 1px solid $black;
      }
    }

    .calendar {
      width: 100%;
      border-collapse: collapse;
      box-sizing: border-box;
    }


    .calendar th {
      padding: 10px;
      background-color: $offwhite;
      color: white;
      text-align: center;
      color: $black;

    }

    .calendar td {
      position: relative;
      padding: 10px;
      text-align: center;
      cursor: pointer;

    }

    .calendar td:hover {
      outline: 1px solid $black;
    }

    .calendar td.empty {
      background-color: transparent;
    }

    .calendar td.empty:hover {
      outline: none;
    }

    .calendar td.selected {
      background-color: $black;
      color: $white;
    }

    .current-month {
      margin: auto;
      cursor: pointer;
    }

    .current-year {
      margin: auto;
      cursor: pointer;

    }
  }

  ::placeholder {

    /* Firefox */
  }
}


.months-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

}

.months-container div {
  padding: 10px;
  margin: 2px;
  cursor: pointer;
}

.months-container div.selected {
  background-color: #000000;
  color: white;
}

.months-container div:hover {
  outline: 1px solid $black;
}

.years-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.years-container div {
  padding: 10px;
  cursor: pointer;
}

.years-container div.selected {
  background-color: #000000;
  color: white;
}

.years-container div:hover {
  outline: 1px solid $black;
}