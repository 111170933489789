.selectBox {
  width: 100%;
  padding: 0px 15px;

  .selectBox-container {
    position: relative;

    .bimmatch-select {
      .categorised-search-container {
        position: unset;
      }

      .categorised-dropdown-open .data-container {
        height: 368px;
        margin-top: 10px;
        border: unset;
        padding: 10px 0px;
        box-shadow: unset;

        .data-main {
          height: 100%;
        }

        .section-container {
          padding: unset;
        }
      }
    }
  }

}