.specs-right-panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    .spec-requirement-title, .spec-requirement-summary{
        margin-bottom: 16px;
    }

    .spec-requirement-title{
        font-size: 16px;
        font-weight: 600;

        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // line-height: 1.2;
    }

    .spec-requirement-summary{
        font-size: 12px;
        font-weight: 400;
        
        font-family: "Open Sans";
        line-height: 1.5;
    }

    .bimmatch-tabs{
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .tab-content-wrapper{
        // height: 100%;
        overflow: auto;
    }

    .ai-analytics-tab-container {
        .properties {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .tags-wrapper{
            flex-wrap: wrap;
        }
    }
}

.specs-detail-floating-panel{
    .importance-tag-wrapper, .spec-requirement-summary, .ai-analytics-tab-container, .image-cards, .parsed-content-image{
        max-width: $floating-panel-width-large;
    }
}