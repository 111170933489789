// Product view popup styles starts here
.product-view {
  padding: 0 !important;
  background-color: $white;
  margin: 27px 0 0;
  padding-right: 20px;

  .main-slider {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 100%;
      top: 0;
      right: 0px;
      background-color: $white;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .main-item {
      .left-content {
        // width: 60%;
        // float: left;
        // position: fixed;
        left: 40px;
        top: 97px;
        // width: calc(100% - 600px);
        @include calc(width, "100% - 585px");

        @include media-breakpoint-down(lg) {
          @include calc(width, "100% - 535px");
        }

        @media (max-width: $breakpoint-md) {
          width: 100%;
          position: relative;
          left: auto;
          top: auto;
        }

        .productview-toggle-container {
          padding: 3px;
          display: flex;
          gap: 2px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          width: fit-content;
          border-radius: 2px;

          // .productview-item-container{
          //   background-color: #EFEFEF;
          //   padding: 4px 10px;
          //   border-radius: 2px;
          //   color:#363636;
          //   .bimmatch-button{
          //     color:#363636;
          //     border:none;
          //     padding: 0;
          //     height: auto;
          //   }
          //   p{
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //   }
          //   svg{
          //     width: 18px;
          //     height: 18px;
          //     margin-right: 6px;
          //     *{
          //       fill:black;
          //     }
          //   }
          //   &.active{
          //     background-color:#151515;
          //     .bimmatch-button{
          //       color:#ffffff;
          //     }
          //     svg{
          //       *{
          //         fill:white;
          //       }
          //     }
          //   }
          // }
        }

        .product-slider-wrap {
          .large-view-slide {
            .slick-list {
              margin: 0;
            }

            .slick-track {
              .item {
                margin: 0;

                img {
                  width: 100%;
                }
              }
            }
          }

          .small-view {
            @include media-breakpoint-down(sm) {
              display: none;
            }

            .slick-list {
              margin: 0;
            }

            .slick-track {
              margin: 20px -5px 0;

              .item {
                margin: 0 5px;
                position: relative;
                cursor: pointer;

                &:hover:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  @include background-opacity($red, 0.69);
                }

                &.slick-current {
                  &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    @include background-opacity($red, 0.69);
                  }
                }
              }
            }
          }
        }
      }

      .right-content {
        width: 545px;
        float: right;

        @include media-breakpoint-down(lg) {
          width: 475px;
        }

        @media (max-width: $breakpoint-md) {
          width: 100%;
          margin: 30px 0 0;
        }

        .content-wrap-pdp {
          padding: 0 40px 10px 20px;

          @media (max-width: $breakpoint-lg) {
            padding: 0 20px;
          }

          @include media-breakpoint-down(md) {
            padding: 0;
          }

          @media (max-width: $breakpoint-sm) {
            padding: 10px 0;
            height: auto !important;
            overflow: auto;
          }
        }
      }
    }
  }
}

@keyframes fade-out {
  from {
    background-color: #efefef;
    color: $black;
  }

  to {
    background-color: $white;
    color: $white;
  }
}

.product-deleting {
  animation: fade-out 1.5s;

  .product-info {
    animation: fade-out 3s;
  }

  .product-status-wrapper {
    animation: fade-out 1.5s;
  }

  .table-raw-section {
    animation: fade-out 1.5s;
  }

  .title {
    animation: fade-out 1.5s;
  }
}

.popover {
  &.share-popup {
    .arrow {
      width: 30px;
      top: -20px;
      height: 20px;
    }

    .popover-body {
      padding: 10px;

      .popover-content {
        .react-share__ShareButton {
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.bimmatch-product-details {
  .product-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $black;

    .menu-list {
      border-bottom: 1px solid #eeeeee;

      &.link {
        li {
          cursor: pointer;
        }
      }

      li {
        font-weight: 400;
        display: inline-block;
        position: relative;
        padding: 0 10px 5px;

        &:first-child {
          padding-left: 0;
          padding-right: 0;
        }

        &.selected {
          border-bottom: 3px solid #f3516b;
        }

        @include media-breakpoint-down(lg) {
          margin-right: 16px;
        }

        @include media-breakpoint-down(xs) {
          display: block;
          margin: 0 0 10px;
        }
      }
    }

    .organization {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      color: $black;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;

      .org-logo {
        width: 48px;
        height: 48px;
        margin-right: 10px;
      }

      .org-name {
        padding-right: 10px;
      }

      .brand-name {
        padding-left: 10px;
        border-left: 1px solid $darkgray;
      }

      .share-icon{
        width: 18px;
        height: 18px;
        margin-left: auto;
        filter: brightness(0) saturate(100%);
        cursor: pointer;
      }
    }

    .category {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 2px;

      .category-row {
        display: flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        .main-category {
          color: $alt-black;
        }

        .sub-category {
          color: $black;
        }

        .separator {
          margin: 0 10px;
        }
      }
    }

    .product-name {
      font-weight: 275;
      font-size: 32px;
      line-height: 38px;
      color: $black;
      font-family: "Barlow";
      font-style: normal;
      margin-bottom: 12px;
    }
    .tooltip-content{
      word-break: break-all;
    }
  }

  .product-properties-container {
    font-size: 14px;
    line-height: 20px;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    // .green-sustainability-section-container{
    //   display: flex;
    //   flex-direction: column;
    //   gap: 16px;
    //   .green-sustainability-section{
    //     .items{
    //       display: flex;
    //       flex-wrap: wrap;
    //       gap: 5px;
    //       .chip{
    //         border-radius: 24px;
    //       }
    //     }
    //   }
    // }

    .display-section-main-container {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &.bim-files-section {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &.small-gap {
        gap: 12px;
      }

      .bimmatch-button {
        // width: fit-content;
        .bimmatch-button-name {
          // display: flex;
          // align-items: center;
          .download-icon{
            filter: brightness(0) saturate(100%) invert(29%) sepia(88%) saturate(1858%) hue-rotate(330deg) brightness(91%) contrast(92%);
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }

        &:hover {
          // background-color: #F3516B;
          .bimmatch-button-name {
            color: $red-hover;
            .download-icon{
              filter: brightness(0) saturate(100%) invert(29%) sepia(88%) saturate(1858%) hue-rotate(330deg) brightness(91%) contrast(92%);
            }
          }
        }
      }

      .download-all-file {
        .bimmatch-button {
          .bimmatch-button-name {
            .download-icon{
              filter: brightness(0) saturate(100%) invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
            }
          }

          &:hover {
            background-color: $red-hover;

            .bimmatch-button-name {
              color: $white;
              .download-icon{
                filter: brightness(0) saturate(100%) invert(93%) sepia(100%) saturate(0%) hue-rotate(248deg) brightness(106%) contrast(106%);
              }
            }
          }
        }
      }

      h4 {
        font-size: 14px;
        line-height: 18px;
        // color: #151515;
      }

      .key-field {
        color: $mid-gray;
      }

      .value-field {
        // color:#151515;
        margin-left: 8px;
        word-break: break-word;
      }

      .multiselection-with-section-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px 0;

        .section-container {
          display: flex;
          flex-direction: column;
          padding-left: 12px;

          .items {
            display: flex;
            flex-direction: column;
            // flex-wrap: wrap;
            padding: 0 40px;
            gap: 4px;
          }

          .chip {
            border-radius: 24px;
          }

          & > h4 {
            margin-bottom: 5px;
          }
        }
      }

      .country-container {
        .items {
          display: flex;
          // flex-direction: column;
          gap: 10px;

          .country-data {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              object-fit: cover;
            }
          }
        }
      }

      .section-title {
        display: flex;
        gap: 12px;
        align-items: center;

        .badge-counter {
          width: 16px;
          height: 16px;
        }

        h4 {
          margin: 0;
        }

        // margin-bottom: 12px;
      }

      .custom-property-value-wrapper {
        display: flex;
        align-items: center;
      }

      .url-container {
        .value-field-url {
          color: $black;
          text-decoration: underline !important;
          word-break: break-all;
        }
      }
    }
  }
}

.bimmatch-product-details .product-properties-container, //product detail page
.project-detail ~ .bimmatch-floating-panel .panel-content .right-panel //right panel of project detail page
{
  .accordion {
    display: flex;
    align-items: center;

    .accordion-name {
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
    }

    .accordion-container {
      flex: 1;
    }

    .accordion-body {
      padding: 20px 24px;
    }

    &.accordion-close {
      background-color: #efefef;
    }

    &.accordion-open {
      background-color: rgba(239, 239, 239, 0.5);
    }
  }
}
