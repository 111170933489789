.bimmatch-table-container {
  overflow-x: auto;
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    height: 1px;
  }

  td, th {
    border-left: 1px solid $offwhite;
    border-right: 1px solid $offwhite;
    padding: 7px 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: $alt-black;
    white-space: nowrap;
    text-align: center;
  }

  td, th {
      background-color: $white;

      &.freezed {
        position: sticky;
        z-index: 1;
      }

      &.left-freezed {
        left: 0;
      }

      &.right-freezed {
        right: 0;
      }

      &.freezed:has(.popover-content) {
        z-index: 2;
      }
    }

  th {
    padding: 14px 5px;
  }

  &.sticky-header {
    max-height: 100%;
    thead {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  // thead tr:first-child{
  //   border-top: 1px solid $offwhite;
  // }
  
  tbody tr:last-child, thead .additional-header{
    border-bottom: 1px solid $offwhite;
  }

  tbody tr {
    border-top: 1px solid $offwhite;
    
    &.selected{
      border-top-color: $black;
      & + tr{
        border-top-color: $black;
      }
      td:first-child{
        border-left-color: $black;
      }

      td:last-child{
        border-right-color: $black;
      }
    }

    &:last-child.selected{
      border-bottom-color: $black;
    }
  }

  tr.row-selectable:hover{
    td{
      background: rgba(255, 255, 255, 0.5);
    }
  }

  td.align-right, th.align-right {
    text-align: right;
  }

  td.align-center, th.align-center {
    text-align: center;
  }

  .sort-icon{
    width: 16px;
  }

  .items-row{
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .tree-level {
    width: 20px;
  }

  .tree-icon {
    display: flex;
    align-items: center;
    width: 16px;
    border-radius: 2px;
    justify-content: center;
    cursor: pointer;
  }

  .tree-expand-icon-spacer{
    width: 16px;
  }

  .tree-icon.open {
    background: $black;
    color: $white;
    fill: $white;
  }

  .tree-icon.closed {
    color: $black;
    fill: $black;
  }

  .row-loading {
    .bimmatch-button-loading {
      width: 20px;
      position: relative;
      display: flex;

      &::after {
        border-top-color: $black;
      }
    }
  }

  .table-data-loading{
    height: 300px;
  }
}
