.bimmatch-switch {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 52px);

  &.reverse {
    flex-direction: row-reverse;
  }

  .label-container {
    margin: 0;
    width: auto;
    max-width: 100%;
  }

  .switchCtrl {
    margin: 0;
    padding: 0;
    height: 24px;
    cursor: pointer;
  }

  .switchCtrl.right-side {
    float: right;
  }

  .switchCtrl.right-side+label.title {
    float: right;
  }

  .switchCtrl+label.title {
    font-weight: normal;
    display: inline-block;
    margin: 0;
    cursor: pointer;
  }

  .switchCtrl+label.title::after {
    content: ' ';
    white-space: pre;
  }

  .switchCtrl+label.title::before {
    content: ' ';
    white-space: pre;
  }

  .switchCtrl input {
    display: none;
  }

  .switchCtrl input:disabled+.toggle {
    cursor: not-allowed;
  }

  .switchCtrl input:disabled+.toggle::before {
    background-color: #e6e6e6;
  }

  .switchCtrl input:disabled+.toggle::after {
    background-color: #e6e6e6;
  }

  //   .switchCtrl input:checked + .toggle::before {
  //     background-color: seagreen;
  //   }
  .switchCtrl input:checked+.toggle::after {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
    background-color: #151515;
  }

  .switchCtrl input:checked:disabled+.toggle::before {
    background-color: #caeeda;
  }

  .switchCtrl input:checked:disabled+.toggle::after {
    background-color: #caeeda;
  }

  .switchCtrl .toggle {
    position: relative;
    // display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 24px;
    overflow: hidden;
    vertical-align: top;
    padding: 2px 2px 2px 20px;
    background: #FFFFFF;
    border: 1px solid #AAAAAA;
    border-radius: 2px;

    &:hover {
      border: 1px solid #151515;
    }
  }

  .switchCtrl .toggle::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 20px;
    // top: 4px;
    left: 2px;
    // bottom: 4px;
    display: inline-block;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    box-sizing: border-box;
    background: #AAAAAA;
    border-radius: 2px;
  }

  .switchCtrl .toggle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: #ffffff;
    display: inline-block;
    -webkit-transition: 0.3s;
    transition: 0.3s;

  }

  .switchCtrl.rounded .toggle {
    border-radius: 10px;
  }

  .switchCtrl.rounded .toggle::after {
    border-radius: 50%;
  }

  .switchCtrl.rounded .toggle::before {
    border-radius: 10px;
  }
}