.carbon-fp-tab {
  overflow: auto;

  .input-number .label-container {
    display: none;
  }
  //header

  .header-section-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;

    .info-icon {
      margin-right: auto;
    }
  }

  .header-section {
    display: flex;
    align-items: center;
    padding: 16px 8px 0;
    margin-left: -8px;

    .carbon-emission {
      margin-left: auto;

      input {
        font-weight: 700;
        color: $black;
      }
    }
  }

  .bimmatch-popover .popover-content.popover-content-bottom {
    padding: 6px 8px;
    right: 5px;
    left: unset;

    hr {
      margin: 0;
    }
  }

  .material-bullet {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 2px;

    // unselect material
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    font-weight: 300;

    img {
      width: 10px;
      height: 10px;
    }
  }

  .carbon-sandbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 8px;
  }

  .carbon-sandbox-row-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .error-message{
      color: $red;
      font-size: 10px;
      line-height: 18px;
    }
  }

  .carbon-sandbox-row {
    width: 100%;
    display: flex;
    gap: 16px;

    .input-number{
      width: 100%;
    }

    .material{
      flex: 4;
    }

    .carbon-emission, .value-unit {
      flex: 3;
    }

    .material, .carbon-emission, .value-unit {
      overflow: hidden;
    }
  }

  .material {
    padding-top: 12px;

    overflow: hidden;
    color: $black;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;

    .material-wrapper {
      display: flex;
      gap: 8px;

      .material-name {
        width: calc(100% - 52px);
      }

      .edit-icon {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }

    &.selected-material {
      background-color: $offwhite;
    }
  }

  .carbon-emission {
    flex: 3;
    border-radius: 4px;
    display: flex;
    justify-content: end;
    align-items: center;

    .input-number {
      .unit, .value-field {
        width: max-content;
      }
    }
  }

  .carbon-sandbox-row.non-bm-material {
    .material-name {
      color: $lightgray;
    }
  }

  .create-material-button .bimmatch-button{
    min-width: unset;
    padding: 10px;

    .button-img{
      width: 18px;
      height: 18px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7464%) hue-rotate(205deg) brightness(103%) contrast(101%);
    }

    &.bimmatch-button-loading{
      padding: 10px 30px;
    }
  }
}
