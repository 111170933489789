.bimmatch-tag-selector{
    width: 100%;
    border-radius: 8px;
    border: 1px solid $lightgray;
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 8px;

    .tag-selector-title{
        color: $alt-black;
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
    }

    .tags-list .select-open-main{
        max-height: 130px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;

        .chip{
            margin-right: 5px;
            margin-bottom: 5px;
            max-width: 100%;
            cursor: pointer;
        }
    }

    .highlight-text {
        font-weight: 900;
    }

    &.highlighted{
        background: $darkgray;
    }
}