.input-file {
  padding: 0 0 2px 0;
  width: 165px;

  .input-file-container {
    display: flex;

    label {
      background: $white;
      border: 2px dashed $darkgray;
      border-radius: 8px;
      height: 120px;
      width: 165px;
      margin-bottom: 0;

      .preview-img {
        height: 110px;
        width: 150px;
        object-fit: cover;
      }
    }

    .input-file-label::before {
      position: absolute;
      z-index: 1;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      background-color: rgba(255, 255, 255, .5);
      opacity: 0;
      transition: all .3s;
      content: " ";
    }

    input {
      display: none;
    }

    .input-file-label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
      position: relative;
    }

    .file-details {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      z-index: 3;
    }


    .file-action {
      background-image: url(#{$assetPath}/icon/success-dark.svg);
      background-repeat: no-repeat;
      width: 18px;
      height: 18px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 5px;
    }

    .file-action-remove {
      background-image: unset;
    }

    .file-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: $mid-gray;
      width: 100%;

      .start-name,
      .end-name {
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        color: $black;
      }

      .start-name {
        width: 42%;
        text-overflow: ellipsis;
      }

      .end-name {
        width: 40%;
        direction: rtl;
        text-align: right;
      }
    }

    .label-des {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      margin-top: 17px;
      color: $mid-gray;
    }

    .remove {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid $alt-black;
      padding: 2px;
      top: -6px;
      right: -6px;
      background: $white;
      display: none;
    }

    label:hover {
      border: 2px dashed $alt-black;
      cursor: pointer;

      .file-action {
        background-image: url(#{$assetPath}/icon/trash.svg);
        opacity: 0.5;
        cursor: pointer;
      }

      .file-action:hover {
        opacity: 1;
      }

      .file-name {
        color: $alt-black;
      }

      .remove {
        display: block;
      }

      .input-file-label::before {
        opacity: 1;
      }
    }
  }
}

.input-file-error {
  .input-file-container {
    label {
      border: 2px dashed $err-red;

      .remove {
        border: 1px solid $err-red;
      }
    }

    label:hover {
      border: 2px dashed $err-red;
      cursor: pointer;

      .file-name {
        color: $alt-black;

        .start-name,
        .end-name {
          color: $red;
        }
      }

    }
  }
}

.input-file-loading {

  label {
    position: relative;
  }

  label::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: $red;
    border-radius: 50%;
    animation: loading-spinner 1s ease infinite;
  }

  .preview-img {
    opacity: 0.5;
    pointer-events: none;
  }
}

@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}