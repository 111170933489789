.distributor-data-container {
    min-width: 730px;
    height: 490px;
    min-height: 400px;
    display: flex;

    .create-distributor-maps {
        width: 40%;
        height: 480px;
        padding: 0px 10px 0px 0px;

    }

    .categories-create-distributor {
        width: 60%;

        .categories {
            padding: 0px 20px;
        }

        .create-distributor-repeat {
            .repeatComponent {
                padding-top: 0px;

                .create-distributor-main {
                    position: relative;
                    padding: 16px 20px;

                    .remove-distributor {
                        position: absolute;
                        top: 12px;
                        right: 15px;
                        cursor: pointer;
                        display: none;
                        height: 20px;
                        width: 20px;
                    }

                }

                .add-button {
                    align-items: center;
                    width: 120px;
                    padding: 4px 5px;
                    margin-bottom: 5px;
                    cursor: pointer;
                    display: flex;


                    &:hover {
                        background-color: $darkgray;
                        border-radius: 5px;
                    }

                    .add-more-icon {
                        height: 14px;
                        width: 14px;
                        margin-right: 6px;
                    }

                    .add-more-text {
                        font-style: normal;
                        text-decoration: none;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 22px;
                        color: $black;
                    }
                }
            }

            .create-distributor-main:hover {
                .remove-distributor {
                    display: block;
                }
            }

        }
    }


}