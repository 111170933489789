.register-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    max-width: 600px;
    margin: auto;
    text-align: center;



    // verification page
    &.verify-user {
        max-width: 700px;
        align-items: center;

        .title {
            color: $gray;
            font-family: Barlow;
            font-size: 32px;
            font-weight: 275;
            margin-bottom: 50px;
        }

        .content-wrapper {
            background-color: $lightgraytransparent;
            padding: 20px;
            display: flex;
            flex-direction: column;
            max-width: 400px;

            color: $gray;
            font-size: 14px;
            font-weight: 400;

            .content-title {
                margin-bottom: 24px;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

            .info {
                margin-top: 12px;

                .info-icon {
                    width: 16px;
                    height: 16px;
                }

                display: flex;
                gap: 8px;

                .info-msg {
                    text-align: left;
                }
            }

            .resend,
            .email-sent {
                margin-top: 40px;
            }

            .register,
            .new-account {
                margin-top: 10px;
            }

            .code {
                margin-top: 20px;
                margin-bottom: 8px;
            }

            .circle-loader {
                display: inline-block;
                margin-left: 5px;
                width: 14px;
                height: 14px;
            }
        }
    }

    .header-text {
        color: $gray;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 15px;
    }

    .checkbox-container .label-text {
        white-space: unset;
    }

    @media (max-width: $breakpoint-lg) {
        justify-content: unset;
        align-items: unset;
    }

    // registration page
    .register-container {

        .bimmatch-marketing {
            margin-top: 20px;
        }
    }


}