/* Scss Document */

#back-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 30px;
  background-color: $gray;
  color: $white;
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  opacity: 0;

  &:hover {
    background-color: $blue;
    color: $white;
  }

  &.show {
    opacity: 1;
  }
}
