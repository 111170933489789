.categorised-search {
  width: 100%;

  .categorised-search-container {
    width: 100%;
    position: relative;
  }

  //main input
  .search-with-btn {
    width: 100%;
    position: relative;

    .search-chip-container {
      position: absolute;
      display: flex;
      top: 50%;
      transform: translateY(-50%);
      left: 8px;
      right: 88px;
      overflow-y: hidden;

      .chip {
        margin-right: 5px;
      }
    }

    .search-chip-container-noclear {
      right: 37px;
      background-color: $white;
    }

    .search-input {
      width: 100%;
      height: 50px;
      border-radius: 2px;
      border: 1px solid $lightgray;
      text-indent: 5px;
    }

    .search-input:focus {
      border: 1px solid $mid-gray;
    }

    .btn-container {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: text;

      .clear-btn {
        font-size: 12px;
      }

      .close-icon {
        height: 13px;
        cursor: pointer;
      }

      .clear-btn:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      .search-btn {
        margin-left: 10px;
        height: 18px;
        width: 18px;
      }
    }
  }

  .single-select-selected-value {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $alt-black;
  }

  //dropdown css in separate file

}