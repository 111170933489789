.bimmatch-progress-container {
  width: 90%;
  margin: 0 auto;

  .progress-text {
    font-size: 0.875rem;
    color: $mid-gray;
    margin-bottom: 0.5rem;
  }
  
  .progress {
    display: flex;
    height: 40px;
    overflow: hidden;
    background-color: $offwhite;
    border-radius: 0.25rem;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: center;
    white-space: nowrap;
    background-color: $darkgray;
    transition: width 0.3s ease;
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    animation: progress-stripe 1s linear infinite;
  }
  
  @keyframes progress-stripe {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
}