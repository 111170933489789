.contact-bimmatch-modal .modal-container {

    .contact-bimmatch-container {
        padding: 0 20px;
        font-size: 15px;
        text-align: left;
    }

    .list-title {
        line-height: 24px;
        margin-bottom: 16px;
    }

    .lists {
        list-style: disc;
        list-style-position: inside;

        .list {
            margin-bottom: 8px;
        }
    }

    .contact-bimmatch-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}