.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $header-zindex;
  padding: 15px 0;
  background: transparent;
  @include transition(all 0.5s ease);

  @media (max-width: $breakpoint-md) {
    padding: 10px 0;
  }

  &.stick {
    background: $white;

    .menu-row .menu-wrap .menu-left .main-navigation .nav .main-menu-list li a {
      color: $black;
    }

    .menu-row .logo-wrap p a {
      color: $black;
    }

    .menu-row .logo-wrap p a svg .st1 {
      stroke: $black;
    }

    .menu-row .menu-wrap .menu-left .menu-mobile-icon #toggle-icon span {
      background: $black;
    }
  }

  &.white-header {
    position: fixed;
    background-color: $white;
    border-bottom: 1px solid $offwhite;

    .menu-row {
      .logo-wrap {
        p {
          a {
            color: $black;

            svg {
              .st0 {
                fill: $red;
              }

              .st1 {
                fill: none;
                stroke: $black;
                stroke-width: 2;
                stroke-linecap: square;
                stroke-miterlimit: 10;
              }
            }
          }
        }
      }

      .menu-wrap {
        .menu-left {
          .menu-mobile-icon {
            #toggle-icon {
              span {
                background: $gray;
              }
            }
          }

          .main-navigation {
            .nav {
              .main-menu-list {
                li {
                  a {
                    color: $black;

                    @media (max-width: $breakpoint-md) {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-row {
    position: relative;

    .logo-wrap {
      float: left;
      width: 190px;

      @media (max-width: $breakpoint-md) {
        width: 95px;
      }

      p {
        padding: 3px 0;

        a {
          font-size: 24px;
          font-weight: 300;
          color: $white;

          @media (max-width: $breakpoint-md) {
            font-size: 0;
          }

          svg {
            width: 32px;
            display: inline-block;

            .st0 {
              fill: $red;
            }

            .st1 {
              fill: none;
              stroke: $white;
              stroke-width: 2;
              stroke-linecap: square;
              stroke-miterlimit: 10;
            }
          }

          img {
            width: 32px;
            display: inline-block;
            @include transition(all 0.25s ease);
          }
        }
      }
    }

    .menu-wrap {
      float: left;
      text-align: left;
      @include calc(width, "100% - 190px");

      @media (max-width: $breakpoint-md) {
        @include calc(width, "100% - 95px");
      }

      .menu-left {
        float: left;
        text-align: left;

        .menu-mobile-icon {
          display: none;

          @media (max-width: $breakpoint-md) {
            display: block;
            position: absolute;
            left: 52px;
            top: 12px;
          }

          #toggle-icon {
            width: 15px;
            height: 22px;
            position: relative;
            @include rotate(0deg);
            @include transition(all 0.5s ease);
            cursor: pointer;

            span {
              display: block;
              position: absolute;
              height: 1.5px;
              width: 100%;
              background: $white;
              border-radius: 1px;
              opacity: 1;
              left: 0;
              @include rotate(0deg);
              @include transition(all 0.25s ease);

              &:nth-child(1) {
                top: 0px;
              }

              &:nth-child(2),
              &:nth-child(3) {
                top: 6px;
              }

              &:nth-child(4) {
                top: 12px;
              }
            }

            &.open {
              span {
                &:nth-child(1) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }

                &:nth-child(2) {
                  @include rotate(45deg);
                }

                &:nth-child(3) {
                  @include rotate(-45deg);
                }

                &:nth-child(4) {
                  top: 18px;
                  width: 0%;
                  left: 50%;
                }
              }
            }
          }
        }

        .main-navigation {
          .nav {
            .main-menu-list {
              li {
                float: left;
                position: relative;

                @media (max-width: $breakpoint-md) {
                  width: 100%;
                }

                a {
                  font-size: 14px;
                  color: $white;
                  padding: 11px 24px;
                  display: inline-block;
                  position: relative;

                  @media (max-width: $breakpoint-md) {
                    color: $white;
                  }

                  &:hover:after,
                  &.active:after {
                    content: "";
                    position: absolute;
                    left: 24px;
                    bottom: -14.5px;
                    @include calc(width, "100% - 48px");
                    height: 2px;
                    background-color: $red;

                    @include media-breakpoint-down(md) {
                      bottom: 6px;
                    }
                  }
                }
              }
            }
          }
        }

        @media (max-width: $breakpoint-md) {
          .pushmenu {
            background-color: $black;
            text-align: center;
            width: 250px;
            height: 100%;
            top: 0;
            z-index: $pushmenu-zindex;
            position: fixed;
            overflow-x: scroll;
            float: none !important;
            margin-top: 0 !important;
            @include transition(all 0.5s ease);
          }

          .pushmenu-left {
            left: -250px;
          }

          .pushmenu-open {
            left: 0;
          }
        }
      }

      .menu-right {
        float: right;
        align-items: center;
        display: flex;

        .units-popover {
          margin-right: 30px;

          .popover-content.popover-content-bottom {
            margin-left: -100px;
          }

          .units-list-container {
            width: 136px;

            .single-unit-container {
              padding: 5px;
              width: 100%;
              display: flex;
              cursor: pointer;
              border-radius: 4px;

              .unit-icon {
                margin-right: 12px;
                height: 18px;
                width: 18px;
              }
            }

            .single-unit-container:not(:first-child) {
              margin-top: 5px;
            }

            .single-unit-container:hover {
              background-color: $lightgrayshade;

            }

            .selected-unit,
            .selected-unit:hover {
              background: $black;
              color: $white;
              cursor: unset;
            }

          }
        }

        .unit-container {
          cursor: pointer;

          .unit-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }

          .unit-icon {
            height: 18px;
            width: 18px;
            margin-right: 5px;
          }
        }

        .button-wrap {
          float: left;

          .btn-outline-secondary {
            margin: 0;
          }

          .notification {
            display: inline-block;
            float: left;

            a {
              font-weight: 600;
              color: $red;
              text-align: center;
              display: inline-block;
              margin-right: 20px;
              cursor: pointer;

              img {
                width: 16px;
              }
            }
          }

          .profile {
            display: inline-block;

            .bimmatch-avatar {
              cursor: pointer;
            }

            .bimmatch-popover {
              .profile-content-item {
                display: flex;
                padding: 5px;
                align-items: center;
                cursor: pointer;

                img {
                  height: 20px;
                  width: 20px;
                  margin-right: 7px;
                }

                span {
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  color: $black;
                }
              }

              .profile-content-item:hover {
                background-color: $lightgrayshade;
                border-radius: 4px;
              }

              hr {
                margin: 12px 0;
              }

              .popover-content {
                padding: 8px 10px;
                width: 200px;
              }

              .popover-content.popover-content-bottom {
                top: calc(135% + 2px);
                margin-left: -179px;
              }

              // organization list

              .organizations-list {
                max-height: 165px;
                overflow-y: scroll;
              }

              .organization-details {
                background: $lightgrayshade;
                border-radius: 4px;
                color: $black;
                cursor: pointer;
                padding: 5px 10px;
                text-align: left;

                .organization-name,
                .organization-role-name {
                  font-family: $opensans;
                  font-style: normal;
                  font-weight: 400;
                }

                .organization-roles {
                  margin-top: 4px;
                  // height: 45px;
                  // overflow-y: scroll;
                }

                .organization-name {
                  font-size: 14px;
                  line-height: 20px;

                  .no-org-name {
                    font-style: italic;
                    color: $darkgray;
                  }
                }

                .organization-role-name {
                  font-size: 12px;
                  line-height: 16px;
                }
              }

              .organization-details:not(:first-child) {
                margin-top: 10px;
              }

              .organization-details-active,
              .organization-details:hover {
                background: $black;
                color: $white;
              }

              .organization-details-active {
                cursor: unset;
              }
            }

            .btn {
              padding: 0;
            }

            .btn-link:hover,
            .btn-link:focus {
              color: #000000;
              text-decoration: none;
            }

            a {
              font-weight: 600;
              color: $black;
              display: inline-block;
              cursor: pointer;

              &:hover {
                color: $darkblue;
              }

              .image-wrap {
                width: 35px;
                height: 35px;
                border-radius: 18px;
                overflow: hidden;
                margin-right: 10px;
                float: left;

                img {
                  width: 35px;
                }
              }

              .name {
                margin: 9px 0 0;
                float: left;
              }
            }
          }
        }
      }
    }
  }
}

.pushmenu-push {
  overflow-x: hidden;
  // overflow-y: scroll;
  position: relative;
  left: 0;
  @include transition(all 0.5s ease);

  &.pushmenu-push-toright {
    left: 250px;
    @include transition(all 0.5s ease);

    // header {
    //   &.stick {
    //     .menu-row {
    //       .menu-wrap {
    //         .menu-left {
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

.pushmenu-push.pushmenu-push-toright {
  header {
    .menu-mobile-icon {
      @media (max-width: $breakpoint-xs) {
        left: 40px !important;
      }
    }
  }
}

.emailverify-container {
  @media (min-width: $breakpoint-md) {
    max-width: 80%;
  }

  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .emailverify-middle-text {
    text-align: left;
    margin-top: 40px;
    max-width: 70%;

    h3 {
      color: $black;
    }

    div {
      margin-top: 40px;
    }
  }

  .emailverify-button {
    color: $red;
    border-color: $red;
    width: 100%;
    margin-top: 5px;

    &:hover {
      color: $white;
      background-color: $red;
    }
  }
}