.analytics-wrapper {
  background: $white;
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  margin: 15px 0px;

  padding: 10px 20px 10px 20px;
  // overflow: hidden;
  display: flex;
  flex-direction: column;

  &.active {
    border-color: #696969;
  }

  .analytics-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    select {
      border: 0;
      border-bottom: 1px solid #696969;
      font-style: normal;
    }

    h4 {
      margin: 15px 0;
    }
  }

  .view-events {
    font-family: "Open Sans";
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  }

  .highcharts-container {
    .highcharts-point {
      cursor: pointer;
    }
  }

  .analytics-footer-wrapper {
    border-top: 1px solid #696969;
    padding-top: 8px;
    margin: auto -20px;
    padding: 8px 20px 0px 20px;

    .analytics-footer-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      margin-top: 8px;

      h4 {
        margin: 0;
      }

      .close-icon {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }

      .series-name {
        display: flex;
        align-items: center;

        .legend-symbol-line {
          width: 18px;
          height: 0;
          position: relative;
          margin-right: 4px;
          border-width: 1.5px;
          border-style: solid;

          .legend-symbol-circle {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }

    .analytics-footer-row {
      display: flex;
      flex-direction: row;
      margin-top: 6px;
      gap: 10px;

      .username {
        // width: 120px;
        width: 20%;
      }

      .productname {
        width: 50%;

        p {
          // width: 250px;

          cursor: pointer;
          // margin-right: 25px;
          text-decoration: underline;
          color: #363636;
          font-weight: 600;
        }
      }
    }

    .user-location {
      width: 20%;
    }

    .analytics-footer-row> :last-child {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .empty-wrapper {
    display: flex;
    flex-direction: column;
    padding: 12px;

    .title {
      font-weight: 600;
      margin: 0 0 15px;
      font-size: 18px;
    }

    img {
      align-self: center;
    }
  }
}

.empty-analytics-page,
.empty-project-page {
  .empty-report-title {
    margin: 32px 0;
    font-family: "Barlow";
    font-weight: 275;
    color: #000000;
  }

  .description {
    line-height: 20px;
  }

  .contact-us {
    margin-left: 12px;
    cursor: pointer;
    font-weight: 600;
    text-decoration-line: underline;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .item-container {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 18px;
      }

      p {
        line-height: 20px;
        margin-left: 8px;
      }
    }
  }

  .empty-analytics-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      block-size: fit-content;
    }
  }

  .contactus-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .contact-us {
      font-weight: 600;
      line-height: 19px;
      text-decoration-line: underline;
      margin-left: 12px;
      cursor: pointer;
    }

    .project-title {
      h3 {
        color: $black;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}

.unauthorised-analytics-page {
  .description {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}