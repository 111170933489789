.input-text {
  width: 100%;
  padding: 0 0 2px 0;

  input {
    width: 100%;
    height: 40px;
    background: $white;
    border: 1px solid $mid-gray;
    text-indent: 10px;
  }

  .input-icon-wrapper{
    position: relative;

    input{
      padding-right: 38px;
    }

    .input-icon{
      width: 18px;
      height: 18px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  ::placeholder {
    @include common-input-text-style($lightgray);
    opacity: 1;
    /* Firefox */
  }
}

.input-text-error {
  input {
    border: 1px solid $err-red;
  }

  ::placeholder {
    @include common-input-text-style($err-red);
    opacity: 1;
    /* Firefox */
  }
}

.input-text-disabled {
  input {
    background: $offwhite;
    pointer-events: none;
  }
}