.bimmatch-floating-panel {
  position: fixed;
  z-index: 999;
  padding: 13px 20px;
  background: #fff;

  &.right-panel {
    top: 0;
    height: 100vh;
    transition: right 0.3s ease-out;
    overflow: hidden;

    // Default size (medium)
    right: -$floating-panel-width-medium;
    width: $floating-panel-width-medium;
    
    &.panel-open {
      right: 0;
      box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.25);
    }
  }

  // Large size
  &.floating-panel-large {
    right: -$floating-panel-width-large;
    width: $floating-panel-width-large;
  }

  // auto width
  &.floating-panel-auto{
    width: auto;
    min-width: $floating-panel-width-medium;
  }

  .panel-header {
    display: flex;
    gap: 10px;
    justify-content: center;

    img {
      width: 24px;
      height: 24px;
    }

    h2.title {
      font-family: Barlow;
      font-size: 32px;
      font-style: normal;
      font-weight: 275;
      color: $black;
    }

    .close-btn {
      margin-left: auto;
      cursor: pointer;
    }
  }

  .panel-content {
    margin-top: 20px;
    height: calc(100% - 48px);
    
    &.scroll-content{
      overflow: auto;
    }
  }
}
