.error-text {
  margin-top: 3px;
  font-family: $opensans;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $err-red;
  width: 100%;
  padding-right: 2px;
  min-height: 18px;
}

.error-text-left {
  text-align: left;
}

.error-text-right {
  text-align: right;
}

.error-text-normal {
  color: $alt-black;
}