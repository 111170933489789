.settings-organization {
    position: relative;
    width: 100%;

    .settings-organization-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;

        .settings-organization-heading {
            font-family: $barlow;
            font-style: normal;
            font-weight: 275;
            font-size: 32px;
            line-height: 38px;
            color: $black;
        }

        .bimmatch-button {
            min-width: 140px;
        }

    }

    .settings-organization-main {

        .accordion {
            margin-bottom: 20px;
            background: rgba(239, 239, 239, 0.5);

            .repeatComponent-vertical {
                .add-button {
                    margin-top: 25px;
                }

                .repeatComponent-children-container {
                    overflow-y: unset;
                }
            }
        }

        .input-file {
            width: fit-content;
        }

        .address-content {
            padding: 0 0 23px 0;
        }

        .organization-name-url {
            display: flex;
            justify-content: space-between;

            .input-text,
            .input-url {
                width: 47%;
            }

            .label-container {
                .bimmatch-popover {
                    .info-label {
                        width: 205px;
                    }

                    .popover-content.popover-content-top {
                        margin-left: -149px
                    }
                }
            }

        }
    }

    .settings-organization-contacts {

        background: $lightgrayshade;
        padding: 16px 20px;
        padding-top: 24px;
        position: relative;

        .contacts-name-email,
        .contacts-phone-role {
            display: flex;
            justify-content: space-between;
        }

        .input-text,
        .input-number,
        .bimmatch-select {
            width: 47%;
        }

        .input-number-container {
            width: 100%;
        }

        .remove-contact {
            position: absolute;
            top: 12px;
            right: 15px;
            cursor: pointer;
            display: none;
        }
    }

    .settings-organization-contacts:hover {
        .remove-contact {
            display: block;
        }

    }

    .info {
        margin-bottom: 12px;
    }
}