.bimmatch-truncate-text{
    .description{
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .break-word{
        word-break: break-all;
    }

    .bimmatch-anchor-tag{
        font-size: 12px;
        font-weight: 500;
    }
}