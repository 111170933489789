/* Scss Document */

/* Box Shadow */
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  }

  @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

/* Transition */
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

/* Transform Rotate */
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

/* Width Calc */
@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

/* Background Opacity Color */
@mixin background-opacity($color, $opacity: 0.3) {
  background: $color;
  /* The Fallback */
  background: rgba($color, $opacity);
}

/* Border Color */
@mixin background-opacity-border($color, $opacity: 0.3) {
  border-color: $color;
  /* The Fallback */
  border-color: rgba($color, $opacity);
}

@mixin background-opacity-color($color, $opacity: 0.3) {
  color: $color;
  /* The Fallback */
  color: rgba($color, $opacity);
}

/* Animation Key Frame */

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }

  @-moz-keyframes #{$name} {
    @content;
  }

  @-o-keyframes #{$name} {
    @content;
  }

  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($delay,
  $duration,
  $animation,
  $direction: forward,
  $fillmode: fowards) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillmode;
  -webkit-animation-direction: $direction;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillmode;
  -moz-animation-direction: $direction;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fillmode;
  animation-direction: $direction;
}

/* translate3d */
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

/* translate */
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

/* Background Image */
@mixin background-image-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

%flexbox {
  @include flexbox;
}

//----------------------------------

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

%inline-flex {
  @include inline-flex;
}

//----------------------------------------------------------------------

// Flexbox Direction
// Values: row | row-reverse | column | column-reverse
// Default: row
@mixin flex-direction($value: row) {
  @if $value ==row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  }

  @else if $value ==column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  }

  @else if $value ==column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  }

  @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }

  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

@mixin flex-dir($args...) {
  @include flex-direction($args...);
}

// Flexbox Wrap
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;

  @if $value ==nowrap {
    -ms-flex-wrap: none;
  }

  @else {
    -ms-flex-wrap: $value;
  }

  flex-wrap: $value;
}

// Flexbox Flow (shorthand)
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap

@mixin flex-flow($values: (row nowrap,
  )) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

// Flexbox Order
// Default: 0

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

// Flexbox Grow
// Default: 0
@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

// Flexbox Shrink
// Default: 1
@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

// Flexbox Basis
// Values: Like "width"
// Default: auto
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

// Flexbox "Flex" (shorthand)
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).

@mixin flex($fg: 1, $fs: null, $fb: null) {
  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg)=="list" {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

// Flexbox Justify Content
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start

@mixin justify-content($value: flex-start) {
  @if $value ==flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  }

  @else if $value ==flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }

  @else if $value ==space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  }

  @else if $value ==space-around {
    -ms-flex-pack: distribute;
  }

  @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }

  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin flex-just($args...) {
  @include justify-content($args...);
}

// Flexbox Align Items
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch

@mixin align-items($value: stretch) {
  @if $value ==flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  }

  @else if $value ==flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  }

  @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }

  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

// Flexbox Align Self
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;

  @if $value ==flex-start {
    -ms-flex-item-align: start;
  }

  @else if $value ==flex-end {
    -ms-flex-item-align: end;
  }

  @else {
    -ms-flex-item-align: $value;
  }

  align-self: $value;
}

// Flexbox Align Content

// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;

  @if $value ==flex-start {
    -ms-flex-line-pack: start;
  }

  @else if $value ==flex-end {
    -ms-flex-line-pack: end;
  }

  @else {
    -ms-flex-line-pack: $value;
  }

  align-content: $value;
}

// Filter Effect
@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: unquote($filter-type + unquote("(#{$filter-amount})"));
  -moz-filter: unquote($filter-type + unquote("(#{$filter-amount})"));
  -ms-filter: unquote($filter-type + unquote("(#{$filter-amount})"));
  -o-filter: unquote($filter-type + unquote("(#{$filter-amount})"));
  filter: unquote($filter-type + unquote("(#{$filter-amount})"));
}

@mixin inputPlaceholder() {
  $selector: "";
  $prefixes: (moz: "::-moz",
    webkit: "::-webkit",
    ie: ":-ms",
  );

  @each $prop,
  $value in $prefixes {
    @if $prop !="moz" {
      $selector: #{$value}-input-placeholder;
    }

    @else {
      $selector: #{$value}-placeholder;
    }

    @if & {
      &#{$selector} {
        @content;
      }
    }

    @else {
      #{$selector} {
        @content;
      }
    }
  }

  &::placeholder {
    @content;
  }
}

//input placeholder style
@mixin common-input-text-style($color) {
  color: $color;
  font-family: $opensans;
  font-style: italic;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}