//password tootlip
.input-password {
    .info-icon {
        height: 15px;
        width: 15px;
        margin-top: -4px;
    }

    .password-info {

        .heading {
            margin-bottom: 10px;
        }

        .requirement-list {
            list-style: disc;
            list-style-position: inside;

            li::marker {
                margin-right: 10px;
            }
        }
    }
}


// password strength
.password-strength {
    display: flex;
    justify-content: flex-end;
    height: 18px;
    margin-top: 3px;

    .single-strength {
        display: flex;
        align-items: center;

        .strength-icon {
            width: 11px;
            margin-right: 3px;
        }

        .strength-message {
            font-size: 12px;
            color: $alt-black;
        }
    }

    .single-strength:not(:last-child) {
        margin-right: 8px;
    }

    .strength-failed {
        .strength-message {
            color: $err-red;
        }
    }

}