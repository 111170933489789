.bimmatch-tag {
  .chip{
    height: unset;
    border-radius: 4px;
    background: $offwhite;
    padding: 0px 4px;

    &, &:hover{
        border: none;
    }
  }

  .chip-name{
      font-size: 12px;
  }

  .chip-icon{
    margin-right: 4px;
  }
}
