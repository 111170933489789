.notification-container {
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    z-index: $toast-zindex;

    .notification {
        transition: .3s ease;
        position: relative;
        pointer-events: auto;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 30px;
        margin-bottom: 15px;
        width: 300px;
        max-height: 130px;
        color: $black;
        opacity: 1;
        background-position: 15px;
        background-repeat: no-repeat;
        border-radius: 4px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 25%);

        button {
            color: $darkgray;
        }

        button:hover {
            color: $black;
        }


    }

    .notification:hover {
        box-shadow: 0 0 8px $white;
        cursor: pointer
    }

    .notification-title {
        text-align: left;
        margin-top: 0;
        margin-bottom: 10px;
        width: 300px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    .notification-message {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        text-align: left;
        margin-left: -1px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $black;
    }

    .notification-image {
        float: left;
        margin-right: 8px;
    }

    .notification-image img {
        width: 18px;
        height: 18px;
    }

    .toast {
        min-height: 70px;
        width: 255px;
        color: $black;
        padding: 15px 15px 10px 10px;
    }

    button {
        position: relative;
        right: -.3em;
        top: -0.7em;
        float: right;
        font-weight: 700;
        color: $black;
        outline: none;
        border: none;
        text-shadow: 0 1px 0 $white;
        opacity: .8;
        line-height: 1;
        font-size: 16px;
        padding: 0;
        cursor: pointer;
        background: 0 0;
        border: 0;
    }

    .notification-error.toast {
        color: $err-red;
    }

    .notification-success.toast {
        color: $light-green;
    }

    .notification-alert.toast {
        color: #E2BD52
    }

    .notification-info.toast {
        color: $alt-black
    }


    .notification-error {
        background: $white;
        border: 1px solid $err-red;
    }

    .notification-success {
        background: $white;
        border: 1px solid $light-green;
    }

    .notification-alert {
        background: $white;
        border: 1px solid #F4E6C6;
    }

    .notification-info {
        background: $white;
        border: 1px solid $alt-black;
    }
}

.notification-container.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification-container.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}

.notification-container.top-left {
    top: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

.notification-container.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform .6s ease-in;
    animation: toast-in-left .7s;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);

    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);

    }

    to {
        transform: translateX(0);
    }
}