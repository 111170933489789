.code {
    .code-container {
        display: flex;
        justify-content: center;
        gap: 10px;

        .code-input {
            height: 49px;
            width: 33px;
            border: 1px solid $mid-gray;
            border-radius: 4px;
            text-indent: 6px;
            font-size: 25px;
        }

        /* Chrome, Safari, Edge, Opera */
        .code-input::-webkit-outer-spin-button,
        .code-input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        .code-input[type="number"] {
            -moz-appearance: textfield;
        }
    }

    .error-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-top: 10px;
        width: auto;
        padding-right: 0;
    }

    .message-icon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
    }

    .label-text-container {
        justify-content: center;

        .label-text {
            color: $gray;
        }
    }

    .code-message-container {
        display: flex;
        align-items: end;
        justify-content: center;

        .loader {
            margin-bottom: 5px;
        }
    }
}

.code-normal {
    .message-icon {
        height: 20px;
        width: 20px;
        filter: brightness(1) invert(1);
    }
}

.code-error {
    .code-container .code-input {
        border: 1px solid $err-red;
    }
}