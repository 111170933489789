.inputWithToggle {
  padding: 15px 0 5px 0;

  .inputWithToggle-container {
    display: flex;
    min-width: 550px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    background: #efefef;
    border-radius: 4px;
    position: relative;

    .remove {
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid $alt-black;
      padding: 2px;
      top: -6px;
      right: -6px;
      background: $white;
      display: none;
      cursor: pointer;
    }

    .inputWithToggle-toggle {
      width: 45%;

      .toggle-container {
        max-width: 100%;
      }
    }
  }

  .inputWithToggle-container:hover {
    .remove {
      display: block;
    }
  }

  .inputWithToggle-input {
    width: 52%;
  }
}