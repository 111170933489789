h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

h1 {
  font-size: 32px;
  font-weight: 300;
  color: $gray;
  line-height: 38px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  color: $gray;
  line-height: 28px;
}

h3 {
  font-size: 18px;
  font-weight: 400;
  color: $red;
  line-height: 22px;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  color: $gray;
  line-height: 22px;
  margin: 0 0 15px;
}

h5 {
  font-size: 16px;
  font-weight: 400;
  color: $gray;
}

h6 {
  font-size: 14px;
  font-weight: 600;
}
