.toggle-container {
  padding: 0 0 2px 0;
  max-width: 240px;

  .toggle-main {
    border-radius: 2px;
    height: 36px;
    padding: 3px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .toggle-option {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 2px;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: $alt-black;
      cursor: pointer;
      width: 49%;

      .toggle-icon {
        height: 18px;
        width: 18px;
        margin-right: 5px;
        filter: invert(15%) sepia(14%) saturate(9%) hue-rotate(11deg) brightness(107%) contrast(86%);
      }
    }

    .toggle-option-active {
      background: #151515;
      color: $white;

      .toggle-icon {
        filter: brightness(0) invert(1);
      }
    }

    .toggle-option-normal:hover {
      border: 1px solid $black;
    }

    .left-option {
      margin-right: 1px;
    }

    .right-option {
      margin-left: 1px;
    }
  }
}

.toggle-container-disabled {

  .toggle-option {
    pointer-events: none;
  }

  .toggle-option-normal {
    opacity: 0.5;
  }
}

.toggle-container-white {
  .toggle-main {
    background: $white;
  }
}

.toggle-container-grey {
  .toggle-main {
    background: $lightgrayshade;
  }
}