.bimmatch-note-info {
    display: flex;
    gap: 4px;
    align-items: center;
    font-size: 10px;
    font-weight: 400;
    color: $mid-gray;
    margin: 16px 0;

    .info-icon {
        width: 16px;
        height: 16px;
    }

}