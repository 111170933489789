.filter {
    font-family: $opensans;
    color: $darkgray ;

    .filter-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: $alt-black;
        text-transform: uppercase;
        margin-bottom: 10px;
        padding-top: 16px;
    }

    .filter-row {
        padding-bottom: 75px;
        padding-right: 10px;
        margin-left: 0;
        margin-right: 0;
        // margin-bottom: 15px;
        max-height: calc(100vh - 200px);
        overflow: scroll;


        .filter-row-item {
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            display: flex;
            align-items: center;

            .filter-arrow {
                height: 12px;
                width: 12px;
                cursor: pointer;
                margin-right: 4px;
            }

            .no-arrow {
                margin-left: 16px;
            }
        }

        .filter-arrow-open {
            transform: rotate(90deg);
        }

        .sub-category {
            margin-left: 20px;
            flex: 0 0 100%;
            max-width: calc(100% - 30px);

            .filter-container {
                //   max-width: calc(100% - 20px);
                width: fit-content;
            }
        }
    }

    .filter-container {
        padding: 6px 8px;
        max-width: 100%;
        width: fit-content;
        display: flex;
        align-items: center;
        border: 1px solid $darkgray;
        border-radius: 2px;
        height: 36px;
        cursor: pointer;
        background: $white;

        .category-icon {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        .filter-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: $black;
        }

        &.sub-item-selected {
            background-color: $mid-gray;

            .filter-name {
                color: $white;
            }

            .category-icon {
                filter: brightness(0.6) invert(1);
            }
        }

        &.selected {
            border: 1px solid $black;
            background-color: $black;

            .filter-name {
                color: $white;
            }

            .category-icon {
                filter: invert(1)
            }
        }
    }
}