.bimmatch-select {
  width: 100%;
  padding: 0 0 2px 0;

  .bimmatch-select-container {
    width: 100%;


    .search-input {
      height: 40px;
      border-radius: unset;
      border: 1px solid $mid-gray;
      text-indent: 10px;
    }

    .labels {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .select-head-right {
        align-items: center;
        padding: 4px 5px;
        margin-bottom: 5px;
        cursor: pointer;
        display: flex;


        &:hover {
          background-color: $darkgray;
          border-radius: 5px;
        }

        .add-icon {
          height: 14px;
          width: 14px;
          margin-right: 6px;
        }

        .head-text {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          width: max-content;
        }

      }
    }


    .search-input::placeholder {

      @include common-input-text-style($lightgray);
      opacity: 1;
      /* Firefox */
    }

    .data-main {
      overscroll-behavior: unset;
    }

  }
}

.bimmatch-select-error {

  .bimmatch-select-container {
    .search-input {
      border: 1px solid $err-red;
    }

    .search-input::placeholder {
      @include common-input-text-style($err-red);
      opacity: 1;
    }
  }
}

.bimmatch-select-disabled {
  opacity: 0.5;
  pointer-events: none;

  .search-input::placeholder {
    pointer-events: none;
  }
}