.page-loader {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;

  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: #f3516b;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    animation: ease-in-out 2s infinite;
  }

  &::before {
    opacity: 0.7;
    animation-name: upperDot;
  }

  &::after {
    opacity: 0.5;
    animation-name: lowerDot;
  }
}

@keyframes upperDot {
  25% {
    transform: translateY(-50%);
  }

  47% {
    transform: translateY(0);
  }

  52% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(50%);
  }
}

@keyframes lowerDot {
  25% {
    transform: translateY(50%);
  }

  47% {
    transform: translateY(0);
  }

  52% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-50%);
  }
}
