.unlock-premium-button {
    background-color: $offwhite;
    padding: 3px 4px;
    display: flex;
    gap: 10px;
    border-radius: 4px;
    cursor: pointer;

    span {
        text-align: left;
    }
}