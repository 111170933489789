.options-container {
    z-index: $options-zindex;
    background: $white;
    border: 1px solid $mid-gray;
    position: fixed;
    display: none;

    .section-container {
        padding: 11px;
        height: 100%;
    }

    hr {
        margin: 0;
        border-top: 1px solid $darkgray;
    }

    .suggest-us {
        padding: 11px;
        font-family: $opensans;
        font-style: normal;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
        width: fit-content;
    }

    .options-main {
        overflow-y: scroll;
        overscroll-behavior: contain;
    }

    .category-container {
        border-radius: 8px;
        background: $lightgrayshade;
        margin-bottom: 5px;

        .category-main {
            .category-data-close {
                display: flex;
                align-items: center;
                overflow-y: hidden;
                overflow-x: auto;
                cursor: auto;
            }

            .category-data-open-container {
                padding: 0px 10px 10px 10px;
            }

            .category-data-open {
                max-height: 300px;
                overflow-y: scroll;
                display: flex;
                flex-wrap: wrap;

                .chip {
                    margin-right: 5px;
                    margin-bottom: 5px;
                    max-width: unset;
                }
            }
        }

        .category-head {
            display: flex;
            align-items: center;
            padding: 10px;
            height: 44px;
            cursor: pointer;

            .chip {
                margin-right: 5px;
            }

            .category-name {
                margin-bottom: 0;
                font-weight: unset;
                font-size: 16px;
                color: $alt-black;
                text-transform: capitalize;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .category-icon,
            .category-name {
                margin-right: 10px;
            }

            .no-category-data {
                margin-left: auto;
            }
        }

        .category-main-no-data {
            .category-head {
                cursor: not-allowed;
                pointer-events: none;
                opacity: 0.5;
            }

            .category-data-open-container {
                display: none;
            }
        }
    }

    .highlight-text {
        font-weight: 900;
    }

    .select-open-main {
        max-height: 500px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;

        .chip {
            margin-right: 5px;
            margin-bottom: 5px;
            max-width: unset;
        }
    }
}

.options-container-selectBox {
    border: unset;

    .section-container {
        padding: 11px 0;
    }
}

.options-container-visible {
    display: block;
}

.options-container-shadow {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}