.empty-catalog {

    .empty-catalog-heading {
        font-family: $opensans;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 32px;
        color: $black;
        margin-bottom: 12px;
    }

    .empty-catalog-list {
        width: 90%;
    }

    .list-item {
        display: flex;
        margin-bottom: 15px;

        .arrow {
            margin-right: 10px;
            align-self: flex-start;
            display: flex;
            margin-top: 3px;
        }

        .item-des {
            font-family: $opensans;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black;
        }
    }

    .create-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 32px 0 32px 26px;

        .unlock-premium-button {
            margin-top: 4px;
            background-color: #ffffff;
            box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25)
        }
    }

    .plugin-link,
    .contact {
        margin-left: 26px;
    }

    a {
        font-family: $opensans;
        font-style: normal;
        font-weight: 600;
        line-height: 19px;
        text-decoration: underline;
    }

    .plugin-link {
        margin-bottom: 15px;
        display: block;
    }
}