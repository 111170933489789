.bimmatch-grouped-tag-selector{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .line-separator{
        width: 100%;
        margin: 0;
    }

    .title{
        color: $alt-black;
        font-size: 16px;
        font-weight: 600;
        line-height: 32px;
    }
}