.input-textarea {
  width: 100%;
  padding: 0 0 2px 0;

  textarea {
    width: 100%;
    height: 100px;
    background: $white;
    border: 1px solid $mid-gray;
    text-indent: 10px;
  }

  textarea::placeholder {
    @include common-input-text-style($lightgray);
    opacity: 1;
    /* Firefox */
  }
}

.input-textarea-error {
  textarea {
    border: 1px solid $err-red;
  }

  textarea::placeholder {
    @include common-input-text-style($err-red);
    opacity: 1;
    /* Firefox */
  }
}