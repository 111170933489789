.tree-container {

    .branch-container {

        .branch-main {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 5px 3px;

            .branch-icon,
            .circle-loader {
                height: 16px;
                width: 16px;
                margin-right: 3px;
                padding: 2px;
            }

            .no-branch-icon {
                margin-right: 20px;
            }

            .branch-icon-arrow {
                cursor: pointer;
            }

            .branch-name {
                border-radius: 2px;
                padding: 2px 4px;
            }

            .branch-name:hover {
                background-color: $lightgrayshade;
            }

            .branch-button {
                margin-left: 5px;
                background-color: $lightgrayshade;
                border-radius: 25px;
                padding: 4px;
                min-width: 90px;

                .branch-button-logo {
                    margin-right: 5px;
                    height: 16px;
                    width: 16px;
                }

                .branch-button-name {
                    font-size: 12px;
                }
            }

            .branch-button-selectable {
                cursor: pointer;
            }
        }

        // branch is open
        .branch-open {
            .branch-icon-arrow {
                filter: brightness(100) invert(0);
                background: black;
                border-radius: 3px;
            }
        }

        // branch is close
        .branch-close {
            .branch-icon-arrow {
                filter: brightness(0) invert(0);
                transform: rotate(-90deg);
            }
        }

        // branch has been selected
        .branch-selected {
            .branch-name {
                background-color: $black;
                color: $white;
            }

            .branch-name:hover {
                background-color: $black;
            }
        }

        .branch-childrens {
            margin-left: 20px;
        }


        .branch-parent.parent-unselectable,
        .row-unselectable {
            cursor: default;

            .branch-name:hover {
                background-color: unset;
            }
        }

        //branch types css
        //-common for all types
        .branch-type-button,
        .branch-type-table,
        .branch-type-figure {
            .branch-name {
                font-weight: 600;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .branch-type-table,
        .branch-type-figure {
            .branch-icon {
                margin-left: 20px;
            }
        }
    }
}