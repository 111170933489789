.dropdown {
  position: relative;
  display: inline-block;

  &:hover .dropbtn {
    color: $blue;
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    height: 30px;
  }

  .dropbtn {
    font-weight: 600;
    color: $gray;
    border: none;
    background-color: transparent;

    img {
      display: inline-block;
      margin-right: 6px;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 45px;
    background-color: $white;
    min-width: 160px;
    z-index: 1;
    border: 1px solid $gray;
    padding: 15px;
    z-index: 99;

    &:after {
      content: "";
      position: absolute;
      background-image: url(#{$assetPath}/icon/top-arrow.png);
      background-repeat: no-repeat;
      width: 30px;
      height: 14px;
      top: -14px;
      right: 12px;
    }

    a {
      color: black;
      padding: 7px 5px;
      text-decoration: none;
      display: block;

      &:hover {
        color: $blue;
      }
    }
  }
}
