// Product System popup styles ends here
// Image gallery popup styles starts here
.image-gallery {
  padding: 0 !important;
  .modal-dialog {
    max-width: 58%;
    @media (max-width: $breakpoint-sm) {
      max-width: 100%;
    }
    .modal-content {
      border: none;
      background-color: transparent;
      border-radius: 0;
      .close {
        opacity: 1;
        margin: 0;
        padding: 0;
        position: absolute;
        right: -65px;
        top: 0;
        @media (max-width: $breakpoint-sm) {
          right: 0;
          top: -40px;
        }
        &:hover {
          opacity: 1;
        }
        img {
          width: 24px;
        }
      }
      .modal-body {
        margin: 0;
        padding: 0;
        .large-view-slide {
          .item {
            margin: 0;
          }
        }
        .small-view {
          .slick-track {
            margin: 20px -5px 0;
            .item {
              margin: 0 5px;
              position: relative;
              cursor: pointer;
              &:hover:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                @include background-opacity($red, 0.69);
              }
              &.slick-current,
              &.works {
                &:after {
                  content: "";
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  @include background-opacity($red, 0.69);
                }
              }
            }
          }
        }
      }
    }
  }
}
// Image gallery popup styles Ends here
// Contact me styles starts here
.contact-me {
  .modal-dialog {
    max-width: 700px;

    .modal-content {
      border-radius: 0;
      border: none;
      box-shadow: 0 2px 4px 0 rgba(21, 21, 21, 0.5);

      .modal-header {
        border-color: $offwhite;

        .close {
          img {
            width: 25px;
          }
        }
      }

      .modal-body {
        padding: 20px 20px 0;

        .form-wrap {
          padding: 10px 110px 70px;

          @include media-breakpoint-down(sm) {
            padding: 10px 30px 30px;
          }

          @include media-breakpoint-down(xs) {
            padding: 0 15px 20px;
          }

          p {
            margin: 0 0 12px;
          }
        }

        .form-group {
          margin: 0 0 20px;

          .textarea-two {
            height: 210px !important;
          }
        }
      }
      .modal-footer {
        display: block;
        align-items: inherit;
        justify-content: inherit;
        padding: 1rem;
        border-color: $offwhite;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        button {
          width: 100px;
          font-weight: 600;
        }
      }
    }
  }
}
// Contact me styles ends here

// Sign In With Social Media Styles Starts Here
// .sign-in {
//   .modal-dialog {
//     // max-width: 700px;
//     .modal-content {
//       border-radius: 0;
//       border: none;
//       box-shadow: 0 2px 4px 0 rgba(21, 21, 21, 0.5);
//       .modal-header {
//         border-color: $offwhite;
//         h2 {
//           svg {
//             display: none;
//             width: 30px;
//             margin-right: 3px;
//             @media (max-width: $breakpoint-sm) {
//               display: inline-block;
//             }
//             .st0 {
//               fill: $red;
//             }
//             .st1 {
//               fill: none;
//               stroke: $black;
//               stroke-width: 2;
//               stroke-linecap: square;
//               stroke-miterlimit: 10;
//             }
//           }
//         }
//         .close {
//           img {
//             width: 25px;
//           }
//         }
//       }
//       .modal-body {
//         padding: 30px;
//         @media (max-width: $breakpoint-sm) {
//           padding: 30px 12px;
//         }

//         h3 {
//           font-weight: 700;
//           text-align: center;
//           margin: 10px 0 48px;
//         }

//         .social-list {
//           max-width: 300px;
//           margin: 0 auto;
//           li {
//             width: 100%;
//             display: block;
//             &:not(:last-child) {
//               margin: 0 0 20px;
//             }
//             &:nth-child(1) a {
//               background-color: $google;
//             }
//             &:nth-child(2) a {
//               background-color: $facebook;
//             }
//             &:nth-child(3) a {
//               background-color: $linkedin;
//             }
//             &:nth-child(4) a {
//               background-color: $autodesk;
//             }
//             a {
//               font-weight: 600;
//               color: $white;
//               padding: 11px 10px;
//               border-radius: 5px;
//               display: block;
//               @media (max-width: $breakpoint-sm) {
//                 padding: 8px 10px;
//               }
//               span {
//                 width: 25px;
//                 display: inline-block;
//                 text-align: center;
//                 margin-right: 15px;
//                 img {
//                   height: 24px;
//                 }
//               }
//             }
//           }
//         }
//         .form-wrap {
//           max-width: 300px;
//           margin: 0 auto;
//           .form-group {
//             &:not(:last-child) {
//               margin: 0 0 20px;
//             }
//           }
//         }
//       }
//       .modal-footer {
//         display: block;
//         align-items: inherit;
//         justify-content: inherit;
//         text-align: center;
//         // padding: 30px;
//         border-color: $offwhite;
//         border-bottom-right-radius: 0;
//         border-bottom-left-radius: 0;
//         @media (max-width: $breakpoint-sm) {
//           padding: 30px 10px;
//         }

//         h6 {
//           margin: 0 0 17px;

//           a {
//             font-size: 14px;
//             font-weight: 600;
//           }
//         }

//         p {
//           a {
//             font-weight: 600;
//           }
//         }
//       }
//     }
//   }
//   &.without-social-media {
//     .modal-footer {
//       h6 {
//         margin: 0 !important;
//         img {
//           width: 9px;
//           margin: -2px 3px 0 0;
//         }
//       }
//     }
//   }
// }
// Sign In and Sign UP Styles Starts Here

// Show all styles starts here
.modal-backdrop {
  &.bg-remove {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
  }
}

body {
  &.bg-remove {
    padding-right: 0 !important;
  }
}

.show-all-popup {
  right: 0;
  top: 0;
  bottom: 0;
  left: unset;
  position: fixed;
  width: 40%;
  height: 100%;
  background-color: $white;
  padding-right: 0 !important;
  overflow: auto;
  overflow-y: auto;
  @media (max-width: $breakpoint-md) {
    width: 100%;
    left: 0;
  }
  .modal-dialog {
    margin: 0;
    max-width: 100%;
    .modal-content {
      border-radius: 0;
      border: none;
      box-shadow: none;
      .modal-header {
        border-color: $offwhite;
        display: block;
        padding: 30px 20px 20px;
        h4 {
          display: block;
        }
        .porduct-detail-list {
          display: block;
          li {
            display: inline-block;
            margin-right: 20px;
            position: relative;
          }
        }
        .close {
          position: absolute;
          right: 20px;
          top: 20px;
          img {
            width: 25px;
          }
        }
      }
      .modal-body {
        padding: 20px 20px 0;
        .recommended-list {
          margin: 0 0 15px;
          &.first {
            li {
              &:first-child {
                font-size: 18px;
              }
            }
          }
          li {
            font-weight: 600;
            display: inline-block;
            margin-right: 24px;
            position: relative;
            &:not(:last-child):after {
              content: "";
              position: absolute;
              top: 0;
              right: -12px;
              width: 1px;
              height: 100%;
              background-color: $offwhite;
            }
          }
        }
        h5 {
          margin: 25px 0 20px;
        }

        .custom-card-list {
          margin: 0 -9px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-wrap: wrap;

          li {
            @include calc(width, "33.333% - 18px");
            display: flex;
            flex-direction: column;

            @media (max-width: $breakpoint-lg) {
              @include calc(width, "50% - 18px");
            }

            @media (max-width: $breakpoint-md) {
              @include calc(width, "25% - 18px");
            }

            @media (max-width: $breakpoint-sm) {
              @include calc(width, "33.333% - 18px");
            }

            @media (max-width: $breakpoint-xs) {
              @include calc(width, "50% - 18px");
            }

            &.card {
              border-radius: 0;
              margin: 0 9px 20px;
              box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);

              &:hover {
                box-shadow: 0 0 10px 0 rgba(21, 21, 21, 0.1);
              }

              // &:hover .overlay-hover {
              //   opacity: 1 !important;
              // }

              &.add-to-project-list {
                border: 2px solid $red;
              }

              .add-to-project-icon {
                position: absolute;
                top: 0;
                right: 0;
                width: auto;
                height: 15px;
                font-size: 10px;
                color: $white;
                padding: 2px 5px;
                background-color: $red;
                z-index: 9;
                text-align: center;
                line-height: 12px;
                display: none;

                &.active {
                  display: block;
                }

                img {
                  width: 10px;
                  display: inline-block;
                  margin: -4px 0 0;
                }
              }

              .image-wrap {
                position: relative;
                min-height: 95px;

                .image {
                  min-height: 95px;
                }

                // .overlay-hover {
                //   position: absolute;
                //   left: 5px;
                //   top: 5px;
                //   right: 5px;
                //   bottom: 5px;
                //   opacity: 0;
                //   z-index: 10;
                //   background-color: transparent;
                //   @include transition(all 0.5s ease);

                //   .add-project-button {
                //     color: $white;
                //     @include background-opacity($red, 0.6);
                //     display: block;
                //     width: 100%;
                //     @include calc(height, "50% - 2.5px");
                //     text-align: center;
                //     margin: 0 0 5px;
                //     position: relative;

                //     span {
                //       position: absolute;
                //       width: 100%;
                //       top: 50%;
                //       left: 50%;
                //       @include translate(-50%, -50%);
                //       img {
                //         width: 16px;
                //         display: inline-block;
                //         margin-right: 5px;
                //       }
                //     }
                //     &:hover {
                //       @include background-opacity($red, 0.85);
                //     }
                //   }
                //   .view-detail-button {
                //     color: $white;
                //     @include background-opacity($black, 0.6);
                //     display: block;
                //     width: 100%;
                //     @include calc(height, "50% - 2.5px");
                //     text-align: center;
                //     position: relative;
                //     span {
                //       position: absolute;
                //       width: 100%;
                //       top: 50%;
                //       left: 50%;
                //       @include translate(-50%, -50%);
                //       img {
                //         width: 16px;
                //         display: inline-block;
                //         margin-right: 5px;
                //       }
                //     }
                //     &:hover {
                //       @include background-opacity($black, 0.85);
                //     }
                //   }
                // }
              }
              .card-block {
                padding: 10px 5px;
                .card-title {
                  color: $black;
                  margin: 0 0 12px;
                }
                .quality-img {
                  float: right;

                  img {
                    width: 18px;
                    display: inline-block;
                    &:last-child {
                      margin-left: -12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .modal-footer {
        display: block;
        align-items: inherit;
        justify-content: inherit;
        padding: 1rem;
        border-color: $offwhite;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        button {
          width: 100px;
          font-weight: 600;
        }
      }
    }
  }
}
// Show all styles ends here

// Show all product system styles starts here
.show-all-product {
  right: 0;
  top: 58px;
  bottom: 0;
  left: unset;
  position: fixed;
  width: 40%;
  height: 100%;
  background-color: $white;
  padding-right: 0 !important;
  overflow: auto;
  overflow-y: auto;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    left: 0;
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;

    .modal-content {
      border-radius: 0;
      border: none;
      box-shadow: none;

      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 99;

        img {
          width: 25px;
        }
      }

      .modal-body {
        padding: 20px 20px 0;

        h3 {
          font-weight: 600;
          color: $black;
          margin: 10px 0 20px;
        }

        .product-row {
          .product-wrap {
            height: 100%;
            box-shadow: 0 0 3px 0 rgba(21, 21, 21, 0.25);
            margin: 0 0 20px;

            // &:hover .overlay-hover {
            //   opacity: 1 !important;
            // }

            .image-wrap {
              position: relative;
              min-height: 110px;

              .image {
                min-height: 110px;
              }

              // .overlay-hover {
              //   position: absolute;
              //   left: 5px;
              //   top: 5px;
              //   right: 5px;
              //   bottom: 5px;
              //   opacity: 0;
              //   z-index: 10;
              //   background-color: transparent;
              //   @include transition(all 0.5s ease);

              //   .add-project-button {
              //     color: $white;
              //     @include background-opacity($red, 0.6);
              //     display: block;
              //     width: 100%;
              //     @include calc(height, "50% - 2.5px");
              //     text-align: center;
              //     margin: 0 0 5px;
              //     position: relative;
              //     text-transform: uppercase;

              //     span {
              //       position: absolute;
              //       width: 100%;
              //       top: 50%;
              //       left: 50%;
              //       @include translate(-50%, -50%);

              //       img {
              //         width: 16px;
              //         display: inline-block;
              //         margin-right: 5px;
              //       }
              //     }
              //     &:hover {
              //       @include background-opacity($red, 0.85);
              //     }
              //   }

              //   .view-detail-button {
              //     color: $white;
              //     @include background-opacity($black, 0.6);
              //     display: block;
              //     width: 100%;
              //     @include calc(height, "50% - 2.5px");
              //     text-align: center;
              //     position: relative;
              //     span {
              //       position: absolute;
              //       width: 100%;
              //       top: 50%;
              //       left: 50%;
              //       @include translate(-50%, -50%);
              //       img {
              //         width: 16px;
              //         display: inline-block;
              //         margin-right: 5px;
              //       }
              //     }
              //     &:hover {
              //       @include background-opacity($black, 0.85);
              //     }
              //   }
              // }
            }

            .product-content {
              padding: 8px 6px;

              h6 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                float: left;
                @include calc(width, "100% - 24px");
              }

              .quality-img {
                margin: -3px 0 0;
                width: 24px;
                float: left;

                img {
                  width: 16px;

                  &:last-child {
                    margin-left: -12px;
                  }
                }
              }

              .category {
                color: $gray;
              }

              .price {
                font-size: 11px;
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
// Show all product system styles ends here

// Product all styles starts here
.modal-backdrop {
  &.bg-remove-two {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
  }
}

body {
  &.bg-remove-two {
    padding-right: 0 !important;
    overflow: inherit;
  }
}

#product_gallery_2 {
  z-index: 2000 !important;
  top: 58px;
}

.product-gallery-popup {
  right: inherit;
  top: 58px;
  bottom: 0;
  left: 0;
  position: fixed;
  @include calc(width, "100% - 575px");
  height: 100%;
  background-color: $white;
  padding-right: 0 !important;
  overflow: hidden;
  overflow-y: auto;
  // z-index: 1040;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    left: 0;
  }

  .modal-dialog {
    margin: 0;
    max-width: 100%;

    .modal-content {
      border-radius: 0;
      border: none;
      box-shadow: none;

      button.close {
        position: absolute;
        width: 45px;
        height: 45px;
        border-radius: 23px;
        padding: 0;
        background-color: $gray;
        opacity: 1;
        top: 0;
        right: 0;
        z-index: 99;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        @media (max-width: $breakpoint-md) {
          top: -60px;
          right: 10px;
        }

        img {
          width: 25px;
        }
      }

      .modal-body {
        padding: 0 50px 0 70px;

        @media (max-width: $breakpoint-md) {
          padding: 0 55px;
        }

        .product-slider-wrap {
          .custom-control-wrap {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: -60px 0 0;

            @media (max-width: $breakpoint-lg) {
              margin: -23px 0 0;
            }

            .prev-image,
            .next-image {
              opacity: 0.25;
              position: absolute;
              width: 24px;
              height: 45px;
              z-index: 999;
              cursor: pointer;

              &:hover {
                opacity: 1;
              }
            }

            .prev-image {
              left: -40px;
            }

            .next-image {
              right: -40px;
            }
          }

          .large-view-slide {
            .slick-list {
              .slick-track {
                display: flex;

                .item {
                  margin: 0;
                  vertical-align: middle;
                  height: auto;
                  display: grid;

                  video,
                  img {
                    width: 100%;
                    margin: auto;
                  }
                }
              }
            }
          }

          .small-view {
            margin: 15px 0 0;

            .slick-list {
              .slick-track {
                transform: translate3d(0, 0, 0) !important;

                .item {
                  margin: 0 5px;
                  position: relative;

                  &:hover:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    @include background-opacity($red, 0.69);
                  }

                  &.slick-current {
                    &:after {
                      content: "";
                      position: absolute;
                      left: 0;
                      top: 0;
                      right: 0;
                      bottom: 0;
                      @include background-opacity($red, 0.69);
                    }
                  }

                  .video {
                    position: relative;

                    .video-icon {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      margin: -14px 0 0 -14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// Product all styles Ends Here

// Select Distributor Styles Starts Here
#select_distributor {
  @include background-opacity($black, 0.75);
  z-index: 3000 !important;

  .modal-dialog {
    max-width: 960px;

    button.close {
      position: absolute;
      width: 45px;
      height: 45px;
      border-radius: 23px;
      padding: 10px;
      opacity: 1;
      top: -12px;
      right: -75px;
      z-index: 99;

      @media (max-width: $breakpoint-md) {
        top: -60px;
        right: 10px;
      }

      img {
        width: 24px;
      }
    }

    .modal-content {
      border-radius: 0;

      .modal-body {
        padding: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        flex-wrap: wrap;

        .left,
        .right {
          min-height: 627px;
          display: flex;
          flex-direction: column;
        }

        .left {
          width: 255px;
          float: left;

          @media (max-width: $breakpoint-sm) {
            width: 100%;
          }

          .content-wrap {
            position: relative;
            border: 1px solid $lightgray;
            height: 100%;
            // overflow-y: scroll;
            // overflow-x: hidden;

            .head {
              color: $black;
              padding: 20px 10px;
            }

            .checkbox-wrap {
              position: relative;
              padding: 0 10px;

              .checkbox {
                position: relative;
                padding: 15px 8px;
                @include background-opacity($white, 0.5);
                box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
                margin: 0 0 8px;

                .custom-control {
                  margin: 0;

                  .custom-control-label {
                    font-size: 14px;
                    color: $black;

                    span {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 194px;
                      float: left;
                    }

                    &::before {
                      top: 6px;
                    }

                    &::after {
                      top: 11px;
                    }
                  }
                }
              }
            }

            .apply-all-button {
              position: absolute;
              bottom: 0;
              width: 100%;
              height: 50px;
              background-color: $white;
              border: none;
              color: $blue;
              text-align: center;
              display: block;
              box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.25);

              &:hover {
                color: $darkblue;
              }
            }
          }
        }

        .right {
          @include calc(width, "100% - 255px");
          float: left;

          @media (max-width: $breakpoint-sm) {
            width: 100%;
          }

          #map {
            height: 100%;

            @media (max-width: $breakpoint-sm) {
              height: 500px;
            }
          }
        }
      }
    }
  }
}
// Select Distributor Styles Ends Here

.large-view-slide {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease;
  -webkit-transition: opacity 0.5s ease;
}
.large-view-slide.slick-initialized {
  visibility: visible;
  opacity: 1;
}

.modal {
  .upload-wrap .file-upload-wrap {
    .file-upload-wrap-two {
      &.error {
        .content-wrap {
          border-color: $error;

          .imageuploadify-overlay {
            .cube-icon {
              fill: $error;
            }
          }

          .imageuploadify-images-list {
            .drag-button {
              color: $error;
            }

            .imageuploadify-message {
              color: $error;
            }

            .imageuploadify-container {
              .imageuploadify-details {
                span {
                  color: $error;

                  svg {
                    fill: $error;
                  }
                }

                .remove-button {
                  background-color: $error;
                }
              }
            }
          }
        }
      }
    }
  }

  .file-upload-wrap {
    margin: auto;
    box-shadow: none;
    background: transparent;
    width: 100%;
    padding: 0;

    .content-wrap {
      border: 5px dashed $lightgray;
      border-radius: 12px;

      .icon-wrap {
        text-align: center;
        height: 80px;
        margin: 40px 0 40px;

        svg {
          width: 70px;
          fill: $gray;
        }

        &::after {
          display: none;
        }
      }

      .content {
        .drag-button {
          font-weight: 600;
          font-style: italic;
          color: $gray;
          line-height: 30px;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 99;
          }
        }

        .drag-message {
          font-weight: 600;
          font-style: italic;
          margin-bottom: 40px;
          color: $gray;
        }

        .imageuploadify-container {
          width: 100%;
          height: auto;
          margin: 0 0 20px !important;

          .remove-button {
            position: relative;
            top: inherit;
            right: inherit;
            margin-left: 10px;
          }

          img {
            display: none !important;
          }

          .imageuploadify-details {
            position: relative;
            left: inherit;
            top: inherit;
            right: inherit;
            bottom: inherit;
            background-color: transparent;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);

            span {
              font-weight: 700;
              color: $gray;
              margin: 0 !important;

              &:first-child {
                position: relative;
                padding: 0;
                margin: 0 0 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include translate(0, 0);
                top: inherit;
                left: inherit;

                svg {
                  width: 35px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
