.checkbox {
  padding: 0 0 2px 0;

  .checkbox-container {
    display: flex;
  }

  .checkbox-main {
    height: 20px;
    width: 20px;
    border: 1px solid $alt-black;
    border-radius: 2px;
    padding: 2px;
    background: $white;
    cursor: pointer;
  }

  .label-container {
    margin-bottom: unset;
    margin-left: 10px;
    width: calc(100% - 30px);
  }

  .checkbox-selected {
    background: $black;
    height: 100%;
    width: 100%;
  }
}

.checkbox-error {
  .checkbox-container {
    border: 1px solid $err-red;
  }
}