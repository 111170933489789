.bimmatch-alert {
    background-color:$alt-black;
    padding: 10px 20px;
    margin: 10px 0;
    color: $white;
    border-radius: 4px;

    animation: fadeIn 1.5s;
    
    &, .alert-actions, .alert-content{
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .alert-action-button{
        padding: 4px;
        // min-width: unset;
        height: unset;
    }

    .alert-action-button.bimmatch-button-loading{
        border: 0;
        padding: 0;
        height: 29px;
    }

    .alert-actions{
        margin-left: auto;
    }

    .close-button{
        cursor: pointer;
        width: 20px;
        height: 20px;
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1379%) hue-rotate(48deg) brightness(111%) contrast(87%);;
    }

    .close-button:hover{
        filter: brightness(0) saturate(100%) invert(43%) sepia(0%) saturate(834%) hue-rotate(156deg) brightness(91%) contrast(83%);
    }

    &.closing {
        animation: fadeOut 1.5s;
    }
}