.input-number {
  padding: 0 0 2px 0;
  width: 150px;

  .input-number-container {
    display: flex;
    // width: 150px;
    height: 40px;
    border: 1px solid $mid-gray;

    input {
      width: 100%;
      height: 100%;
      border: unset;
      text-indent: 5px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .unit {
      color: $alt-black;
      border-radius: 0 2px 2px 0;
      background: $offwhite;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      font-size: 12px;
    }
  }

  input::placeholder {
    @include common-input-text-style($lightgray);
    opacity: 1;
    /* Firefox */
  }
}

.input-number-error {
  .input-number-container {
    border: 1px solid $err-red;
  }

  input::placeholder {
    @include common-input-text-style($err-red);
    opacity: 1;
    /* Firefox */
  }
}

.input-number-readonly{
  .input-number-container{
    border: none;

    input{
      text-align: end;
    }
    
    .unit {
      background: $lightgrayshade;
    }
  }
}

.input-number-phone {
  width: 180px;
}