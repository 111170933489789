.settings-personal {
  position: relative;
  width: 100%;

  .settings-personal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;

    .settings-personal-heading {
      font-family: $barlow;
      font-style: normal;
      font-weight: 275;
      font-size: 32px;
      line-height: 38px;
      color: $black;
    }

    .bimmatch-button {
      min-width: 140px;
    }

  }

  .settings-personal-main {

    .accordion {
      margin-bottom: 20px;
      background: rgba(239, 239, 239, 0.5);

      .checkbox {
        margin-top: 22px;

        .label-container .label-text {
          color: $main-black;
          font-weight: 400;
        }
      }

    }

    .info {
      margin-bottom: 12px;
    }

    .option-container {
      padding: 0 20px;
      margin-bottom: 40px;
    }

    .terms-privacy {
      display: flex;

      a {
        text-decoration: underline;
        font-weight: 600;
      }


      .settings-privacy {
        margin-left: 20px;
      }
    }

    .name-email {
      display: flex;
      justify-content: space-between;

      .input-text {
        width: 49%;
      }
    }

    .settings-profession {
      width: 49%;
    }
  }
}


// Email verification
.settings {
  position: relative;
  padding: 130px 0 20px;
  min-height: 100vh;
  width: 100%;

  .actions-container,
  .actions-message {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .actions-container {

    .message-text {
      font-size: 32px;
      font-weight: 275;
      color: #000000;
      line-height: 28px;
      margin: 25px 0 15px;
    }

    .verification-image {
      filter: brightness(0%);
      height: 150px;
      width: 150px;
    }

    svg {
      height: 300px;
      width: 300px;
    }

    .bimmatch-button {
      margin-top: 15px;
    }
  }
}