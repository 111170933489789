.label-container {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  .form-required {
    color: $alt-black;
    font-weight: 800;
    font-size: 14px;
    margin-right: 6px;
  }

  .label-text-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .label-text {
    color: #696969;
    font-family: $opensans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .label-extras {
    display: flex;
  }

  .lod-with-required {
    display: flex;
    margin-right: 2px;


    .lod-count {
      padding: 0.4px 4px;
      background: $alt-black;
      border-radius: 24px;
      color: $white;
      height: 18px;
      margin-left: 5px;

    }

    .required-mark {
      margin-left: 4px;
      height: 18px;
    }

    .instruction-mark {
      height: 17px;
      width: 17px;
      margin-left: 2px;
    }
  }

  .label-delete,
  .label-setting {
    display: none;
    height: 18px;
    width: 18px;
    margin-left: 4px;
    cursor: pointer;
  }

  .label-setting {
    -webkit-transition: 1s ease-out;
    -moz-transition: 1s ease-out;
    transition: 1s ease-out;
  }

  .label-setting:hover {
    -webkit-transform: rotateZ(270deg);
    -moz-transform: rotateZ(270deg);
    transform: rotateZ(270deg);
  }

  .info-label-popover {
    margin-left: 5px;
    margin-right: 3px;

    .info-label {
      color: white;
      text-align: left;
    }

    .popover-content.popover-content-top {
      width: 300px;
      margin-left: -150px
    }

    .popover-content.popover-content-right {
      left: calc(100% + 10px)
    }
  }

  .lod-standard-popover {
    display: none;

    .label-settings-options {
      padding: 8px 4px;
      width: 248px;
    }

    .popover-content-bottom {
      margin-left: -143px;
    }

    .bimmatch-select {
      margin-top: 20px;
    }
  }
}

.label-container:hover {
  .label-delete {
    display: block;
  }
}


.label-with-settings:hover {

  .label-setting {
    display: block;
  }

  .lod-with-required {
    display: none;
  }

  .lod-standard-popover {
    display: unset;
  }
}

.label-with-settings {

  .settings-standard,
  .settings-instruction {
    width: calc(100% - 24px);
  }

  .settings-lod,
  .settings-standard-instruction {
    width: calc(100% - 40px)
  }

  .settings-standard-lod,
  .settings-lod-instruction {
    width: calc(100% - 61px)
  }

  .settings-standard-lod-instruction {
    width: calc(100% - 78px)
  }
}

.label-with-settings:hover,
.label-with-delete:hover {
  .label-text-container {
    width: calc(100% - 22px);
  }
}

.settings-open {
  .lod-standard-popover {
    display: unset;
  }
}