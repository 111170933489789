/* Scss Document */

// Media quaries breakpoints.
$breakpoint-xxs: 490px;
$breakpoint-xs: 575px;
$breakpoint-sm: 767px;
$breakpoint-md: 991px;
$breakpoint-lg: 1199px;
$breakpoint-xl: 1399px;

// floating panel widths
$floating-panel-width-medium: 575px;
$floating-panel-width-large: 695px;

// Asset Path
$assetPath: "/images";

/* Font Family */
$opensans: "Open Sans", sans-serif;
$barlow: "Barlow";

/* Colors  */
$white: #ffffff;
$offwhite: #eeeeee;
$lightgray: #aaaaaa;
$lightgrayshade: #efefef;
$lightgraytransparent: #efefef80;
$darkgray: #cccccc;
$gray: #444444;
$black: #151515;
$blue: #519ef3;
$darkblue: #5177f3;
$lightblue: #5286ED;
$red: #f3516b;
$yellow: #e4f351;
$error: #d0021b;
$google: #db4437;
$facebook: #3b5998;
$linkedin: #0077b5;
$autodesk: #0696d7;
$green: #7dbba5;
$brown: #d2732b;
$red-hover: #df3752;
$mid-gray: #696969;
$err-red: #ff0000;
$alt-black: #363636;
$main-black: #000000;
$light-green: #73BA37;
$verydarkgrey: #454545;

/* z index  */
$options-zindex: 1101;
$modal-backdrop-zindex: 1001;
$modal-container-zindex: 1002;
$popover-zindex: 1102;
$product-filter-zindex: 99;
$logout-zindex: 100;
$base-layout-zindex: 98;
$pushmenu-zindex: 1000;
$header-zindex: 99;
$tooltip-zindex: 1102;
$toast-zindex: 1103;