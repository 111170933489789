.base-layout-wrapper:has(.specs) .page-content {
    background: $lightgrayshade;
}

.specs{
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .specs-content, .specs-list-container{
        flex: 1;
        display: flex;
    }
    
    .specs-content{
        padding-top: 86px;
        // padding-top: 136px;
    }

    .toggle-type-keys .ask-iframe{
        display: none;
    }
    
    .toggle-type-ask .specs-list-container{
        display: none;
    }

    .ask-iframe{
        width: 100%;
    }
    
    .specs-header{
        position: fixed;
        width: 100%;
        z-index: 13;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 70px;
        // height: 120px;
        background-color: $lightgrayshade;
        
        .org-data{
            display: flex;
            align-items: center;
        }

        .org-logo{
            width: 60px;
            margin-right: 10px;
        }

        .spec-search-toggle{
            margin-left: auto;

            .toggle-main{
                background-color: transparent;
            }

            .toggle-option{
                padding: 0 10px;
                border-radius: 2px;
            }

            .toggle-option-normal .toggle-icon{
                filter: brightness(0) saturate(100%) invert(17%) sepia(1%) saturate(0%) hue-rotate(147deg) brightness(97%) contrast(87%);
            }
        }
    }

    .tag-filter-wrapper{
        position: fixed;
        // width: 250px;
        // padding: 0 10px;
        width: 275px;
        padding: 0 25px 0 0;

        height: calc(100% - 170px);
        // height: calc(100% - 220px);
        overflow-y: auto;

        // .filter-row-item{
        //     margin-top: 10px;
        // }
    }

    .no-data-view, .page-loader, .specs-list{
        flex: 1;
    }

    .page-loader, .specs-list{
        // margin-left: 250px;
        margin-left: 275px;
    }

    .specs-list{
        // margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .spec-item:has(.spec-table){
        padding: 4px;
        background: $lightgrayshade;
        border-radius: 2px;
    }

    .spec-accordion{
        .list-item-icon{
            cursor: pointer;
            margin-right: 10px;
            transform: rotate(-90deg);
        }

        .item-title{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            line-height: 22px;
        }

        .accordion-header-content{
            width: 70%;
        }
    
        .accordion-header-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
    
            .options{
                flex: 0 0 20px;
            }
    
            .updated-at, .username{
                flex: 1 1 20%
            }
    
            .projects{
                flex: 1 1 60%
            }
    
            .updated-at, .username, .projects{
                overflow: auto;
            }
        }
    
        .projects{
            display: flex;
            gap: 4px;
        }
    
        .project-name, .username, .updated-at{
            color: $alt-black;
            font-size: 12px;
            font-weight: 400;
        }
    
        .project-name{
            background: $white;
            padding: 4px 8px;
            border-radius: 24px;
    
            flex-shrink: 0;
            flex-grow: 1;
            max-width: 100px;
        }
    
        .username{
            font-weight: 600;
        }
    }

    .spec-table{
        .bimmatch-table-container{
            max-height: 65vh;
            overflow: auto;

            thead{
                z-index: 10;
            }
        }

        .column-id-title, .column-id-content, .column-id-categories, .column-id-sub-categories, .column-id-design-stage, .column-id-disciplines, .column-id-design-area, .column-id-building-codes{
            text-align: start;
        }

        .column-id-title{
            width: 300px;
            max-width: 300px;
        }
        
        .column-id-content, .column-id-categories, .column-id-sub-categories, .column-id-design-stage, .column-id-disciplines, .column-id-design-area, .column-id-building-codes{
            width: 700px;
            max-width: 700px;
            // white-space: normal;
        }

        tr.additional-header th.column-id-title{
            background: $lightgrayshade;
        }

        
        .column-id-title{
            color: $black;
            font-weight: 600;

            .spec-title{
                flex: 1;
                font-size: 14px;
            }
            
            .back-icon{
                width: 18px;
                height: 18px;
                cursor: pointer;
                transform: rotate(90deg);
            }
            .spec-icon{
                width: 24px;
            }
        }

        .column-id-importance .bimmatch-priority-description{
            display: flex;
            padding: 0;
            justify-content: center;

            .importance-tag-wrapper{
                margin: 0;
            }

            .chip{
                padding-top: 0;
                padding-bottom: 0;
                height: auto;
                border-radius: 24px;
                background: $offwhite;
                border: none;
            }
        }
    }

    .options{
        .bimmatch-popover .popover-content.popover-content-bottom {
            right: 4px;
            left: unset;
            padding: 5px 5px;
    
            .remove-invitee {
              padding: 5px;
              cursor: pointer;
            }
    
            .remove-invitee:hover {
              background-color: $lightgrayshade;
              border-radius: 4px;
            }
        }
    }

    .accordion-head-left img,.accordion-header-content .bimmatch-three-dot, .tree-icon {
        cursor: pointer;
    }
}