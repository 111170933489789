.page-layout{
    display: flex;
    flex-direction: column;

    .page-alerts{
        position: fixed;
        z-index: 98;
        background: $lightgrayshade;
        right: 50px;
        left: 50px;
        padding: 0;

        a{
            color: $red;
        }
    }
}