.accordion {
  background: rgba(239, 239, 239, 0.5);
  min-height: 50px;
  width: 100%;
  border-radius: 8px;

  .accordion-container {
    // padding-bottom: 5px;

    .accordion-head-left {
      display: flex;
      align-items: center;
    }

    .accordion-head-right {
      display: none;
      align-items: center;
      padding: 4px 5px;
      cursor: pointer;
    }

    .accordion-head-right:hover {
      background-color: $darkgray;
      border-radius: 5px;
    }
  }

  .accordion-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;

    .add-icon {
      height: 14px;
      width: 14px;
      margin-right: 6px;
    }

    .down-icon {
      height: 18px;
      width: 18px;
      margin-right: 12px;
      transform: rotate(-90deg);
    }

    .accordion-name,
    .head-text {
      font-style: normal;
      font-weight: 600;
    }

    .accordion-name {
      font-size: 16px;
      line-height: 32px;
      color: $alt-black;
    }

    .head-text {
      font-size: 14px;
      line-height: 22px;
      color: $black;
    }

    .label-setting {
      -webkit-transition: 1s ease-out;
      -moz-transition: 1s ease-out;
      transition: 1s ease-out;
    }

    .label-setting:hover {
      -webkit-transform: rotateZ(270deg);
      -moz-transform: rotateZ(270deg);
      transform: rotateZ(270deg);
    }

    .accordion-settings-popover .popover-content.popover-content-bottom {
      right: 0;
      left: unset;
    }

    .accordion-settings-options {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .accordion-settings-option {
        display: flex;
        padding: 5px;
        align-items: center;
        cursor: pointer;

        img {
          height: 20px;
          width: 20px;
          margin-right: 7px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: $black;
          white-space: nowrap;
        }

        &:hover {
          background-color: $lightgrayshade;
          border-radius: 4px;
        }
      }
    }

  }

  .accordion-body {
    display: none;
    padding: 10px 20px 20px 20px;
  }
}

.accordion-open {
  .accordion-body {
    display: block;
    // max-height: 600px;
    // overflow: scroll;
  }

  .accordion-head {
    .accordion-head-right {
      display: flex;
    }

    .down-icon {
      transform: rotate(0deg);
    }
  }

}

.accordion-disable {
  opacity: 0.6;
  cursor: none;
  pointer-events: none;
}