.image-preview {

    .bimmatch-modal .bimmatch-modal-head{
        gap: 25px;
    }
    
    .bimmatch-modal .modal-container{
        overflow: hidden;
    }
    
    .bimmatch-modal .modal-container .bimmatch-modal-body {
        margin: 0;
        padding: 0 20px;
        overflow: auto;
    }

    .bimmatch-modal-large .modal-container {
        max-width: 95%;
        max-height: 95%;
        width: unset;
    }

    .image-preview-image {
        height: 100%;
        width: 100%;
    }
}