.bimmatch-menu-popover-content {
    display: flex;
    flex-direction: column;
    gap: 5px;

    hr {
        margin: 0;
        width: 100%;
        color: $darkgray;
    }

    .bimmatch-menu-item {
        display: flex;
        padding: 5px;
        align-items: center;
        cursor: pointer;

        img {
            height: 20px;
            width: 20px;
            margin-right: 7px;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: $black;
            white-space: nowrap;
        }

        .bimmatch-button-loading::after {
            border-top-color: $black;
        }

        &:hover {
            background-color: $lightgrayshade;
            border-radius: 4px;
        }

        &.loader-wrapper,
        &:has(.bimmatch-button-loading) {
            position: relative;
        }
    }

    .bimmatch-menu-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}