footer {
  background-color: $gray;

  .footer-top {
    padding: 40px 0 100px;

    @media (max-width: $breakpoint-md) {
      padding: 100px 0 10px;
    }

    h3 {
      font-size: 32px;
      font-weight: 600;
      color: $lightgray;
      text-align: center;
      margin: 0 0 60px;
    }

    .image-wrap {
      @media (max-width: $breakpoint-md) {
        margin: 0 0 30px;
      }
    }
  }

  .footer-bottom {
    min-height: 90px;
    border-top: 1px solid $white;

    p {
      color: $lightgray;
      padding: 36px 0;
    }
  }
}
